import React, { useEffect, useState } from 'react';
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import { FormGroup } from 'shared/dist/components/generic-building-blocks/FormGroup';
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import { RoundButton, RoundRedirectButton } from 'shared/dist/components/generic-building-blocks/RoundButton';
import { SearchBox } from 'shared/dist/components/generic-building-blocks/SearchBox';
import { CreateResourceDialog } from 'shared/dist/components/generic-building-blocks/UserDialogs';
import { get_ajax, post } from 'shared/dist/utils/DataProvider';

export const ThemesPage = () => {

    const [isNewThemeDialogOpen, setIsNewThemeDialogOpen] = useState(false);
    const [newThemeName, setNewThemeName] = useState('');

    const [isLoading, setIsLoading] = useState(true);
    const [themes, setThemes] = useState([]);

    useEffect(()=>{
        loadThemes();
    }, []);

    const loadThemes = () => {
        get_ajax('GlobalAdmin/Themes/GetThemes', (response) => {
            console.log(response);
            setThemes(response);
            setIsLoading(false);
        });
    }   

    return (
        <>
            <br/>
            <div className='row'>
                <div className='col-md-8'> 
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Teme</h4>                                                                      
                    </LayoutCard>
                    {isLoading && (<LoadingCircleComponent/>)}
                    {!isLoading && (
                        <div className='row'>
                            {themes.map((theme, i) => 
                                <div className='col-md-4' key={i}>
                                    <RoundRedirectButton
                                        redirectUrl={"/global_admin/themes/details/"+theme.id}
                                        className={"custom-btn-primary"}
                                        text={theme.themeName}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className='col-md-4'>
                    <RoundButton
                        className={"custom-btn-primary"}
                        text="Tema noua"
                        onClickHandler={() => { setIsNewThemeDialogOpen(true); }}
                    />
                </div>
            </div>

            <CreateResourceDialog
                isOpen={isNewThemeDialogOpen}
                toggle={()=>{setIsNewThemeDialogOpen(!isNewThemeDialogOpen)}}
                size={'md'}                
                onConfirm={()=>{
                    post('GlobalAdmin/Themes/CreateNewTheme',
                    {
                        'themeName': newThemeName,
                    }, (response) =>{
                        if(response == false) {
                            alert("Eroare la adaugare");
                            return;
                        }
                        setIsNewThemeDialogOpen(false);
                        loadThemes();
                    })
                }}  
            >
                <FormGroup 
                    label={'Nume tema'}
                    value={newThemeName}      
                    onChange={(e) =>{ setNewThemeName(e.target.value); }}                        
                />
            </CreateResourceDialog>
        </>
    );
}

export const ThemeDetails = (props) => {
    const [themeId, setThemeId] = useState(undefined);
    useEffect(()=>{
        if (props.match.params.theme_id != undefined) {
            setThemeId(props.match.params.theme_id);
        }
    }, [props.match.params.theme_id]);  

    useEffect(()=>{
        if(themeId === undefined) return;
        load_data();
    }, [themeId]);

    const load_data = () => {
        get_ajax('GlobalAdmin/Themes/LoadThemeData?themeId='+themeId, (response) => {
            setThemeName(response.themeName);
            setThemeData(JSON.parse(response.themeData));
        });
    }

    const [themeName, setThemeName] = useState(undefined);
    const [themeData, setThemeData] = useState(undefined);

    if(themeName === undefined || themeData === undefined)
        return (<LoadingCircleComponent/>);
    return (
        <>
            <br/>
            <div className='row'>
                <div className='col-md-8'> 
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>{themeName}</h4>                                                                      
                    </LayoutCard>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" style={{borderTop: 'none'}}>Cod culoare</th>
                                    <th scope="col" style={{borderTop: 'none'}}>Alege</th>
                                    <th scope="col" style={{borderTop: 'none'}}>HEX</th>
                                </tr>
                            </thead>
                            <tbody>
                                {themeData.colors.map((color, index) => 
                                    <tr key={index}>
                                        <th scope="row">
                                            <input 
                                                type="text" 
                                                className='form-control no-outline' 
                                                value={color.name}
                                                onChange={(e) => {
                                                    const _theme = JSON.parse(JSON.stringify(themeData));
                                                    _theme.colors[index].name = e.target.value;
                                                    setThemeData(_theme);
                                                }}
                                            />
                                        </th>
                                        <td>
                                            <input 
                                                type="color" 
                                                className='form-control no-outline' 
                                                value={color.color}
                                                onChange={(e) => {
                                                    const _theme = JSON.parse(JSON.stringify(themeData));
                                                    _theme.colors[index].color = e.target.value;
                                                    setThemeData(_theme);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <input 
                                                type="text" 
                                                className='form-control no-outline' 
                                                value={color.color}
                                                onChange={(e) => {
                                                    const _theme = JSON.parse(JSON.stringify(themeData));
                                                    _theme.colors[index].color = e.target.value;
                                                    setThemeData(_theme);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="row">
                            <div className="col-md-8"></div>
                            <div className="col-md-4">
                                <RoundButton 
                                    disabled={false}
                                    className="btn-secondary"
                                    text="Adauga"                        
                                    onClickHandler={()=>{
                                        const _theme = JSON.parse(JSON.stringify(themeData));
                                        _theme.colors.push({'name': '', 'color': '#000000'});
                                        setThemeData(_theme);
                                    }}
                                />
                            </div>
                        </div>
                    </LayoutCard>
                </div>
                <div className='col-md-4'>
                    <RoundButton
                        className={"custom-btn-primary"}
                        text="Salveaza"
                        onClickHandler={() => { 
                            post('GlobalAdmin/Themes/UpdateThemeData', {
                                'themeId': themeId,
                                'json': JSON.stringify(themeData)
                            }, (response) => {
                                if(response === false) {alert("Eroare la salvare."); return; }
                                load_data();
                            })
                         }}
                    />
                </div>
            </div>
        </>
    );
}