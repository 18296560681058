import React, {useEffect, useState} from "react";
import {get_ajax, post} from 'shared/dist/utils/DataProvider';
import {LayoutCard} from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {RoundButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import { CreateResourceDialog, EditResourceDialog, PreviewDialog } from 'shared/dist/components/generic-building-blocks/UserDialogs';
import {FaDatabase} from 'react-icons/fa';
import {IoIosConstruct} from 'react-icons/io';
import {IoAddCircle, IoCreate, IoDocumentTextOutline, IoListOutline} from 'react-icons/io5';
import {VscSymbolMisc} from 'react-icons/vsc';
import {AiOutlineForm} from 'react-icons/ai';
import {FormGroup, FormGroupArea} from 'shared/dist/components/generic-building-blocks/FormGroup';
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {AiFillPlusCircle, AiFillRightCircle, AiFillCloseCircle, AiOutlineUnorderedList} from 'react-icons/ai';
import {BsPlusCircleFill, BsCheck2} from 'react-icons/bs';
import {BiText} from 'react-icons/bi';
import {HiDuplicate} from 'react-icons/hi';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import {VscEdit} from 'react-icons/vsc';
import {MdPreview, MdSave, MdDeleteOutline, MdModeEditOutline, MdOutlineDragHandle, MdOutlineSettingsSuggest, MdPendingActions, MdOutlineVisibilityOff} from 'react-icons/md';
import AutocompleteSelector from 'shared/dist/components/generic-building-blocks/AutocompleteSelector';
import {
    actionTypes,
    configurableFieldType,
    configurableModelsList,
    defaultRuleDetails, ruleBlockTypes, ruleTypes,
    sideOptionsList,
    skipDefaultNames
} from "./constants";
import {FaWpforms} from 'react-icons/fa';
import {GoSettings} from 'react-icons/go';
import {FcCheckmark} from 'react-icons/fc';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';
import Autocomplete from 'shared/dist/components/generic-building-blocks/AutocompleteTextbox';
import {CustomForm} from 'shared/dist/components/custom_forms/CustomForm';
import {DocumentEditor} from "./DocumentTemplateEditor/DocumentEditor";
import $ from 'jquery';
import { getAccessToken, GetRoles } from 'shared/dist/components/api-authorization/AuthService';
import { printHTML } from 'shared/dist/utils/PdfBuilder';
import { RuleConditionEditor } from "./CimFormsBuilder";
import {CimConfigDashboard} from "./CimConfigDashboard";

export const CimConfigActions = () => {
    return (
        <CimConfigDashboard
            selectedKey={'actions'}
        >
            <EditableActionsConfigurator/>
        </CimConfigDashboard>
    );
}

const EditableActionsConfigurator = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [existingForms, setExistingForms] = useState({});
    useEffect(()=>{
        loadData();
    }, []);

    const loadData = () => {
        get_ajax('CIMConfigurator/CustomActionsConfigurator/GetActions', (data) => {
            setExistingForms(data);
            setIsLoading(false);
        });
    }

    const [isAddNewFormDialogOpen, setIsAddNewFormDialogOpen] = useState(false);
    const [selectedFormsGroup, setSelectedFormsGroup] = useState(undefined);
    const [selectedFormId, setSelectedFormId] = useState(undefined);

    if(selectedFormsGroup === undefined)
    {
        return (
            <>
                <div className={'row'}>
                    {isLoading === false && (
                        <>
                            {Object.keys(existingForms).map((model, i) =>
                                <div className={'col-md-4'} key={i}>
                                    <LayoutCard
                                        onClick={()=>{setSelectedFormsGroup(model)}}
                                        className="layout_card layout_card_25bottom layout_card_padded shadow-sm cursor_pointer"
                                    >
                                        <FaWpforms size={32} className={"float-left"} style={{paddingRight: '10px', marginTop: '-5px'}}/>
                                        <h5>{model}</h5>
                                    </LayoutCard>
                                </div>
                            )}
                            <div className={'col-md-4'}>
                                <LayoutCard
                                    onClick={()=>{setIsAddNewFormDialogOpen(true)}}
                                    className="layout_card layout_card_25bottom layout_card_padded shadow-sm cursor_pointer center"
                                >
                                    <IoAddCircle size={48}/>
                                </LayoutCard>
                            </div>
                        </>
                    )}
                    {isLoading && (
                        <LoadingCircleComponent/>
                    )}
                    <AddNewFormDialog
                        isOpen={isAddNewFormDialogOpen}
                        toggle={()=>{
                            setIsAddNewFormDialogOpen(false);
                            loadData();
                        }}
                    />
                </div>
            </>
        );
    }
    else {
        if(selectedFormId === undefined)
        {
            return (
                <>
                    <LayoutCard
                        className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}
                    >
                        <div className={'row'}>
                            <div className={'col-md-1'}>
                                <span
                                    style={{cursor: 'pointer'}}
                                    className={"redirectNavigationLink"}
                                    onClick={()=>{setSelectedFormsGroup(undefined)}}
                                >Inapoi</span>
                            </div>
                            <div className={'col-md-5'}>
                                <h5 style={{textAlign: 'center'}}>{selectedFormsGroup}</h5>
                            </div>
                        </div>
                    </LayoutCard>
                    <div className={'row'}>
                        {existingForms[selectedFormsGroup].map((form, i) =>
                            <div className={'col-md-4'} key={i}>
                                <LayoutCard
                                    onClick={()=>{setSelectedFormId(form.id)}}
                                    className="layout_card layout_card_25bottom layout_card_padded shadow-sm cursor_pointer"
                                >
                                    <h5>{form.actionName}</h5>
                                </LayoutCard>
                            </div>
                        )}
                    </div>
                </>
            );
        } else {
            return (
                <ActionEditorContainer
                    formId={selectedFormId}
                    formName={existingForms[selectedFormsGroup].filter(x => x.id == selectedFormId)[0].actionName}
                    goBack={()=>{loadData(); setSelectedFormId(undefined);}}
                    modelType={selectedFormsGroup}
                />
            );
        }
    }
}

const AddNewFormDialog = ({isOpen, toggle}) => {
    const [formName, setFormName] = useState('');
    const [formModel, setFormModel] = useState('');
    const [modelOptions, setModelOptions] = useState([]);
    useEffect(()=>{
        if(isOpen === false) return;
        get_ajax("CIMConfigurator/CustomModelConfigurator/GetExternalMappingOptions?editableOnly=true", (options) => {
            setModelOptions(options);
            if(options.length > 0)
            {
                setFormModel(options[0].value);
            }
        })
    }, [isOpen]);
    return (
        <CreateResourceDialog
            isOpen={isOpen}
            toggle={toggle}
            size={'md'}
            title={'Adauga actiune'}
            onConfirm={()=>{
                post('CIMConfigurator/CustomActionsConfigurator/AddAction',
                    {
                        'modelType': formModel,
                        'actionName': formName
                    }, (response) => {
                        if(response === true)
                        {
                            toggle();
                            return;
                        }
                        alert("Eroare la adaugare");
                    });
            }}
        >
            <div className={'form-group'}>
                <label>Obiect extern</label>
                <select className={'form-control'} value={formModel} onChange={(e)=>{setFormModel(e.target.value)}}>
                    {modelOptions.map(option =>
                        <option value={option.value}>{option.name}</option>
                    )}
                </select>
            </div>
            <FormGroup
                label={'Nume actiune'}
                value={formName}
                onChange={(e) =>{ setFormName(e.target.value); }}
            />
        </CreateResourceDialog>
    );
}

const ActionEditorContainer = ({formId, formName, modelType, goBack}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [actionSteps, setActionSteps] = useState(undefined);
    useEffect(()=>{
        get_ajax('CIMConfigurator/CustomActionsConfigurator/GetActionSteps?actionId=' + formId, (response) => {
            setActionSteps(JSON.parse(response));
            setIsLoading(false);
        });
    }, [modelType, formId]);
    
    const addNewAction = (type) => {
        const _actionsSteps = [...actionSteps];
        _actionsSteps.push({
            type: type,
            modelInstanceSelector: {},
            actionPayload: {}
        });
        setActionSteps(_actionsSteps);
    }
    
    const renderActionName = (type) => {
        switch (type){
            case actionTypes.updateObject:
                return "Actualizeaza obiect";
            case actionTypes.generateDocument:
                return "Genereaza document";
        }
    }
    
    return(
        <>
            <LayoutCard className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}>
                <div className={'row'}>
                    <div className={'col-md-1'}>
                                <span
                                    style={{cursor: 'pointer'}}
                                    className={"redirectNavigationLink"}
                                    onClick={()=>{goBack();}}
                                >Inapoi</span>
                    </div>
                    <div className={'col-md-5'}>
                        <h5 style={{textAlign: 'center'}}>{formName}</h5>
                    </div>
                    <div className={'col-md-6'}>
                        <div className={'row'}>
                            <div className={'col-md-10'}></div>
                            <div className={'col-md-2'}>
                                <RoundButton
                                    className={"custom-btn-primary"}
                                    text={<MdSave/>}
                                    onClickHandler={()=>{
                                        post('CIMConfigurator/CustomActionsConfigurator/UpdateActionSteps', {
                                            'actionId': formId,
                                            'metadata': JSON.stringify(actionSteps)
                                        }, (response) => {
                                            if(response === false) {alert("Eroare la salvare"); return;}
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutCard>
            {isLoading && (<LoadingCircleComponent/>)}
            {!isLoading && (
                <LayoutCard className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}>
                    {actionSteps.map((action, i) =>
                        <div className="card" key={i} style={{marginBottom: '25px'}}>
                            <div className="card-header">
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <h6><b>{(i+1)}. {renderActionName(action.type)}</b></h6>
                                    </div>
                                    <div className={'col-md-6'}>
                                        <div className={'row'}>
                                            <div className={'col-md-10'}></div>
                                            <div className={'col-md-2'}>
                                                <RoundButton
                                                    className={"btn-danger"}
                                                    text={<MdDeleteOutline/>}
                                                    onClickHandler={()=>{
                                                        const _actions = [...actionSteps];
                                                        _actions.splice(i, 1);
                                                        setActionSteps(_actions);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <ActionModelInstanceSelector
                                            modelType={modelType}    
                                            modelInstanceSelector={action.modelInstanceSelector}
                                            updateModelInstanceSelector={(key, value)=>{
                                                const _actions = [...actionSteps];
                                                _actions[i]['modelInstanceSelector'][key] = value;
                                                setActionSteps(_actions);
                                            }}
                                        />
                                        {action.type === actionTypes.updateObject && (
                                            <ActionUpdateObjectConfigurator
                                                modelType={action.modelInstanceSelector['referenceType'] === 1 ?
                                                    action.modelInstanceSelector['externalObject'].substring(1) : modelType}
                                                actionPayload={action.actionPayload}
                                                updateActionPayload={(key, value) => {
                                                    const _actions = [...actionSteps];
                                                    _actions[i]['actionPayload'][key] = value;
                                                    setActionSteps(_actions);
                                                }}
                                            />
                                        )}
                                        {action.type === actionTypes.generateDocument && (
                                            <ActionGenerateDocumentConfigurator
                                                modelType={action.modelInstanceSelector['referenceType'] === 1 ?
                                                    action.modelInstanceSelector['externalObject'].substring(1) : modelType}
                                                actionPayload={action.actionPayload}
                                                updateActionPayload={(key, value) => {
                                                    const _actions = [...actionSteps];
                                                    _actions[i]['actionPayload'][key] = value;
                                                    setActionSteps(_actions);
                                                }}
                                            />
                                        )}
                                        {action.type === actionTypes.editForm && (
                                            <ActionEditFormConfigurator
                                                modelType={action.modelInstanceSelector['referenceType'] === 1 ?
                                                    action.modelInstanceSelector['externalObject'].substring(1) : modelType}
                                                actionPayload={action.actionPayload}
                                                updateActionPayload={(key, value) => {
                                                    const _actions = [...actionSteps];
                                                    _actions[i]['actionPayload'][key] = value;
                                                    setActionSteps(_actions);
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <ActionExecutionRulesConfigurator
                                            modelType={modelType}
                                            instanceRules={action.executionRules}
                                            updateInstanceRules={(value)=>{
                                                const _actions = [...actionSteps];
                                                _actions[i]['executionRules'] = value;
                                                setActionSteps(_actions);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {actionSteps.length === 0 && (
                        <p>Nu exista nicio actiune. Adaugati una mai jos</p>
                    )}
                    <div className="card-footer text-muted" style={{
                        marginLeft: '-25px', marginRight: '-25px', marginBottom: '-25px', marginTop: '25px',
                        paddingTop: '25px', paddingBottom: '25px'
                    }}>
                        <h6 style={{marginBottom: '25px'}}><b>Tipuri de actiuni</b></h6>
                        <div className={'row'}>
                            <div className={'col-md-3'}>
                                <RoundButton
                                    className={"custom-btn-primary"}
                                    onClickHandler={()=>{addNewAction(actionTypes.updateObject)}}
                                    text={(
                                        <>
                                            <IoListOutline/>
                                            <span style={{marginLeft: '5px'}}>Actualizeaza obiect</span>
                                        </>
                                    )}
                                />
                            </div>
                            <div className={'col-md-3'}>
                                <RoundButton
                                    className={"custom-btn-primary"}
                                    onClickHandler={()=>{addNewAction(actionTypes.generateDocument)}}
                                    text={(
                                        <>
                                            <IoDocumentTextOutline/>
                                            <span style={{marginLeft: '5px'}}>Genereaza document</span>
                                        </>
                                    )}
                                />
                            </div>
                            <div className={'col-md-3'}>
                                <RoundButton
                                    className={"custom-btn-primary"}
                                    onClickHandler={()=>{addNewAction(actionTypes.editForm)}}
                                    text={(
                                        <>
                                            <VscEdit/>
                                            <span style={{marginLeft: '5px'}}>Forma editare</span>
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </LayoutCard>
            )}
        </>
    );
}

export const ActionExecutionRulesConfigurator = ({instanceRules, updateInstanceRules, modelType}) => {
    return (
        <>
            <p className={'text-muted'}><b>Executie conditionata?</b></p>
            <label>Reguli pentru executie. Daca nu exista nicio regula, actiunea va fi executata in toate cazurile.</label>
            <RuleConditionEditor
                modelType={modelType}
                externalValue={instanceRules}
                setExternalValue={updateInstanceRules}
            />
        </>
    );
}

export const ActionModelInstanceSelector = ({modelInstanceSelector, updateModelInstanceSelector, modelType}) => {
    useEffect(()=>{
        if(modelInstanceSelector['referenceType']) setReferenceType(modelInstanceSelector['referenceType']);
        if(modelInstanceSelector['externalObject']) setExternalObject(modelInstanceSelector['externalObject']);
    }, [modelInstanceSelector]);
    
    const [referenceType, setReferenceType] = useState(
        modelInstanceSelector['referenceType'] !== undefined ? modelInstanceSelector['referenceType'] : 0
    );
    useEffect(()=>{
        updateModelInstanceSelector('referenceType', referenceType);
        
        if(referenceType === 1) {
            get_ajax(`CIMConfigurator/CustomModelConfigurator/RetrieveFields?type=${modelType}`, (data) => {
                if(data === undefined) return;
                setExternalObjectsOptions(data.fields.filter(x => x.type === 6));
            }).catch((e) => {
                setExternalObjectsOptions([]);
            });
        }
    }, [referenceType]);
    
    const [externalObjectsOptions, setExternalObjectsOptions] = useState([]);
    const [externalObject, setExternalObject] = useState(
        modelInstanceSelector['externalObject'] !== undefined ? modelInstanceSelector['externalObject'] : 'not-set'
    )
    useEffect(()=>{
        updateModelInstanceSelector('externalObject', externalObject);
    }, [externalObject]);
    
    return (
        <>
            <p className={'text-muted'}><b>Selectati instanta obiectului</b></p>
            <div className={'row'}>
                <div className={'col-md-6'}>
                    <div className={'form-group'}>
                        <label>Tip referinta</label>
                        <select className={'form-control'} value={referenceType} onChange={(e) => setReferenceType(Number(e.target.value))}>
                            <option value={0}>Obiect curent</option>
                            <option value={1}>Obiect extern</option>
                            <option value={2} disabled={true}>Referinta inversa</option>
                            <option value={3} disabled={true}>Cautare custom</option>
                        </select>
                    </div>
                </div>
                {referenceType === 1 && (
                    <div className={'col-md-6'}>
                        <div className={'form-group'}>
                            <label>Alege obiect</label>
                            <select className={'form-control'} value={externalObject} onChange={(e) => setExternalObject(e.target.value)}>
                                <option value={'not-set'}>-</option>
                                {externalObjectsOptions.map((object)=> 
                                    <option value={object.id}>{object.name}</option>
                                )}
                            </select>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

const ActionUpdateObjectConfigurator = ({actionPayload, updateActionPayload, modelType}) => {
    useEffect(()=>{
        get_ajax(`CIMConfigurator/CustomFormConfigurator/GetAvailableCustomMethods?modelType=${modelType}&includeMethods=false`, (data) => {
            setAvailableFieldsIds(data);
        });
    }, [modelType]);
    const [availableFieldIds, setAvailableFieldsIds] = useState([]);

    useEffect(()=>{
        get_ajax(`CIMConfigurator/CustomFormConfigurator/GetAvailableCustomMethods?modelType=${modelType}&includeMethods=true`, (data) => {
            setAutocompleteSuggestionsFunctions(data);
        });
    }, [modelType]);
    const [autocompleteSuggestionsFunctions, setAutocompleteSuggestionsFunctions] = useState([]);
    
    useEffect(()=>{
        if(actionPayload['values']) setValues(actionPayload['values']);
    }, [actionPayload]);
    
    const [values, setValues] = useState(actionPayload['values'] !== undefined ? actionPayload['values'] : [])
    useEffect(()=>{
        updateActionPayload('values', values);
    }, [values]);
    
    return (
        <>
            <p className={'text-muted'}><b>Actualizari</b></p>
            <table className="table tableLastRight">
                <thead>
                    <tr>
                        <th scope="col">Id camp</th>
                        <th scope="col">Valoare noua</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                {values.map((value, i) => 
                    <tr>
                        <td>
                            <Autocomplete
                                placeholder={'Id camp'}
                                suggestions={availableFieldIds}
                                value={value.field}
                                onChange={(e) =>{
                                    const _values = [...values];
                                    _values[i].field = e;
                                    setValues(_values);
                                }}
                            />
                        </td>
                        <td>
                            <Autocomplete
                                placeholder={'Valoare'}
                                suggestions={autocompleteSuggestionsFunctions}
                                value={value.value}
                                onChange={(e) =>{
                                    const _values = [...values];
                                    _values[i].value = e;
                                    setValues(_values);
                                }}
                            />
                        </td>
                        <td>
                            <RoundButton
                                className={"btn-danger"}
                                text={<MdDeleteOutline/>}
                                onClickHandler={()=>{
                                    const _values = [...values];
                                    _values.splice(i, 1);
                                    setValues(_values);
                                }}
                            />
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            <div className={'row'}>
                <div className={'col-md-6'}>
                    <RoundButton
                        className={"custom-btn-primary"}
                        text={'Adauga'}
                        onClickHandler={()=>{
                            const _values = [...values];
                            _values.push({field: '', value: ''});
                            setValues(_values);
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export const ActionGenerateDocumentConfigurator = ({actionPayload, updateActionPayload, modelType}) => {
    const [documentOptions, setDocumentOptions] = useState([]);
    useEffect(() => {
        get_ajax('CIMConfigurator/CustomDocumentsConfigurator/GetDocuments', (data) => {
            setDocumentOptions(data[modelType]);
        });
    });

    useEffect(()=>{
        if(actionPayload['selectedDocument']) setSelectedDocument(actionPayload['selectedDocument']);
    }, [actionPayload]);

    const [selectedDocument, setSelectedDocument] = useState(actionPayload['selectedDocument'] !== undefined ? actionPayload['selectedDocument'] : 'not-set');
    useEffect(()=>{
        updateActionPayload('selectedDocument', selectedDocument);
    }, [selectedDocument]);

    const [previewHTML, setPreviewHTML] = useState(undefined);
    return (
        <>
            <p className={'text-muted'}><b>Alege documentul</b></p>
            <div className={'row'}>
                <div className={'col-md-6'}>
                    <select className={'form-control'} value={selectedDocument} onChange={(e) => setSelectedDocument(e.target.value)}>
                        <option value={'not-set'}>-</option>
                        {documentOptions.map((object)=>
                            <option value={object.id}>{object.documentName}</option>
                        )}
                    </select>
                </div>
                <div className={'col-md-6'}>
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            <RoundButton
                                className={"custom-btn-primary"}
                                disabled={selectedDocument === 'not-set'}
                                text={<MdPreview/>}
                                onClickHandler={()=>{
                                    get_ajax(`CIMConfigurator/CustomDocumentsConfigurator/Preview?documentId=${selectedDocument}`, (response) => {
                                        setPreviewHTML(response);
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={previewHTML!==undefined} toggle={()=>{setPreviewHTML(undefined)}} size={'xl'}>
                <ModalHeader>Previzualizare</ModalHeader>
                <ModalBody>
                    <div
                        style={{border: '1px solid #2f3542', display: 'block', margin: 'auto'}}
                        dangerouslySetInnerHTML={{__html: previewHTML}}
                    ></div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="no-outline" onClick={()=>{setPreviewHTML(undefined)}}>Inchide</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

const ActionEditFormConfigurator = ({actionPayload, updateActionPayload, modelType}) => {

    const [forms, setAvailableForms] = useState([]);
    useEffect(()=>{
        if(actionPayload['editType']) setEditType(actionPayload['editType']);
        if(actionPayload['selectedForm']) setSelectedForm(actionPayload['selectedForm']);

        get_ajax('CIMConfigurator/CustomFormConfigurator/GetForms', (data) => {
            setAvailableForms(data[modelType]);
        });
    }, [actionPayload]);
    
    const [editType, setEditType] = useState(actionPayload['editType'] !== undefined ? actionPayload['editType'] : 'not-set');
    useEffect(()=>{
        updateActionPayload('editType', editType);
    }, [editType]);

    const [selectedForm, setSelectedForm] = useState(actionPayload['selectedForm'] !== undefined ? actionPayload['selectedForm'] : 'not-set');
    useEffect(()=>{
        updateActionPayload('selectedForm', selectedForm);
    }, [selectedForm]);
    
    return (
        <>
            <p className={'text-muted'}><b>Tip editare</b></p>
            <div className={'row'}>
                <div className={'col-md-6'}>
                    <div className={'form-group'}>
                        <label>Inregistrare istoric</label>
                        <select className={'form-control'} value={editType} onChange={(e) => setEditType(e.target.value)}>
                            <option value={'not-set'}>-</option>
                            <option value={'without-history'}>Fara inregistrare in istoric</option>
                            <option value={'with-history'}>Cu inregistrare in istoric</option>
                        </select>
                    </div>
                </div>
                <div className={'col-md-6'}>
                    <div className={'form-group'}>
                        <label>Foma editare</label>
                        <select className={'form-control'} value={selectedForm} onChange={(e) => setSelectedForm(e.target.value)}>
                            <option value={'not-set'}>-</option>
                            {forms.map((object)=>
                                <option value={object.id}>{object.formName}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
        </>
    );
}