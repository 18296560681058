import React, { useEffect, useState } from 'react';
import {get_ajax, post} from 'shared/dist/utils/DataProvider';
import {SearchBox} from 'shared/dist/components/generic-building-blocks/SearchBox';
import {RoundButton, RoundRedirectButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {LayoutCard} from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {Link, Redirect} from 'react-router-dom';
import {FormGroup} from 'shared/dist/components/generic-building-blocks/FormGroup';
import {DeleteConfirmationDialog, UpdateResultSuccessDialog, CreateResourceDialog, EditResourceDialog} from 'shared/dist/components/generic-building-blocks/UserDialogs';

import AOS from 'aos';
import "aos/dist/aos.css";

function isEmptyOrSpaces(str){
    return str === null || str.match(/^ *$/) !== null;
}

export const Subunitati = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [subunitati, setSubunitati] = useState([1,2,3,4,5,6,7,8,9,10]);

    useEffect(()=>{   
        AOS.init();       
        get_ajax("CompanyConfig/subunitati/GetSubunitati", (response) =>{
            setSubunitati(response);
            setIsLoading(false);
        });
    }, []);

    return(
        <>
            <h4>Subunitati</h4>
            <br/>
            <div className="form-group row">
                <div className="col-md-6">
                    <SearchBox placeholder="Cauta"/>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3">
                    <RoundRedirectButton 
                        className="custom-btn-primary"
                        text="Subunitate noua"
                        redirectUrl="/subunitati/new"
                    />
                </div>
            </div>
            <br/>
            {isLoading && (
                <>
                    <br/>
                    <LoadingCircleComponent/>
                </>
            )}
            {!isLoading && (
                <>
                <div className="row">
                    {subunitati.map((e, i) =>
                        <div className="col-md-4" key={i}>
                            <Link to = {"/subunitati/details/" + e.id}>
                                <div
                                    data-aos="flip-up"
                                    data-aos-delay={parseInt((i / 3)) % 2 == 0 ? (i % 3) * 50 : (2-(i % 3)) * 50}
                                >
                                    <LayoutCard className="layout_card1">                                    
                                        <h5>{e.nume}</h5>                                               
                                        <p style={{marginBottom: '0'}}>
                                            {e.adresa}
                                            <br/>
                                            {e.telefon}
                                        </p>                                                                         
                                    </LayoutCard>
                                </div>
                            </Link>
                        </div>
                    )}                
                </div>
                </>
            )}
            {!isLoading && subunitati.length == 0 && (
                <><br/><p style={{textAlign: 'center'}}>Nu exista subunitati.</p></>
            )}
        </>
    );
}

export const SubunitatiNew = () =>{
    const [nume, setNume] = useState('');
    const [adresa, setAdresa] = useState('');
    const [telefon, setTelefon] = useState('');    
    
    const [numeInvalidMsg, setNumeInvalidMsg] = useState('');
    const [adresaInvalidMsg, setAdresaInvalidMsg] = useState('');
    const [telefonInvalidMsg, setTelefonInvalidMsg] = useState('');    

    const [redirectToResource, setRedirectToResource] = useState('');    

    const addNewSubunitate = () =>{
        let isOkay = true;
        if(isEmptyOrSpaces(nume)){
            isOkay = false;
            setNumeInvalidMsg("Numele este obligatoriu");
        }
        if(isEmptyOrSpaces(adresa)){
            isOkay = false;
            setAdresaInvalidMsg("Adresa este obligatorie");
        }
        if(isEmptyOrSpaces(telefon)){
            isOkay = false;
            setTelefonInvalidMsg("Telefonul este obligatoriu");
        }        
        if(!isOkay)return;
        post('CompanyConfig/subunitati/CreateSubunitate', {
            "nume": nume, 
            "adresa": adresa, 
            "telefon": telefon,             
        }, (response)=>{
            if(response == "invalid_request")
            {
                alert("Eroare la adaugare");
                return;
            }
            setRedirectToResource("/subunitati/details/" + response);
        });
    }

    return(
        <>
            {redirectToResource != '' && (
                <Redirect to={redirectToResource}/>
            )}
            <h4><Link className="redirectNavigationLink" to='/subunitati'>Subunitati</Link> &gt; Subunitate noua</h4>
            <br/>
            <h6>Date identificare</h6>
            <div className="row">
                <div className="col-md-6">
                    <FormGroup 
                        label={'Nume companie'}
                        value={nume}      
                        onChange={(e) =>{ setNume(e.target.value); }}      
                        use_validation={numeInvalidMsg != ''}            
                        is_valid = {numeInvalidMsg == ''}
                        invalid_feedback = {numeInvalidMsg}
                    />
                    <FormGroup 
                        label={'Adresa'}
                        value={adresa}      
                        onChange={(e) =>{ setAdresa(e.target.value); }}          
                        use_validation={adresaInvalidMsg != ''}            
                        is_valid = {adresaInvalidMsg == ''}
                        invalid_feedback = {adresaInvalidMsg}        
                    />
                    <FormGroup 
                        label={'Telefon'}
                        value={telefon}      
                        onChange={(e) =>{ setTelefon(e.target.value); }}     
                        use_validation={telefonInvalidMsg != ''}            
                        is_valid = {telefonInvalidMsg == ''}
                        invalid_feedback = {telefonInvalidMsg}             
                    />                    
                    <br/>
                    <div className="row">
                    <div className="col-md-3">
                            <RoundButton
                                className="custom-btn-primary"
                                text="Adauga"
                                onClickHandler={addNewSubunitate}
                            />
                        </div>
                        <div className="col-md-9"></div>                        
                    </div>
                </div>
            </div>
        </>
    );
}

export const SubunitatiDetails = (props) =>{
    const [isLoading, setIsLoading] = useState(true);        

    const [nume, setNume] = useState('');
    const [adresa, setAdresa] = useState('');
    const [telefon, setTelefon] = useState('');    
    
    const [numeInvalidMsg, setNumeInvalidMsg] = useState('');
    const [adresaInvalidMsg, setAdresaInvalidMsg] = useState('');
    const [telefonInvalidMsg, setTelefonInvalidMsg] = useState('');    

    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [redirectToResource, setRedirectToResource] = useState('');
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

    useEffect(()=>{
        if (props.match.params.subunitate_id != undefined) {
            load_resources();
        }
    }, [props.match.params.subunitate_id]);    

    const load_resources = () =>{        
        get_ajax("CompanyConfig/subunitati/GetSubunitate?id=" + props.match.params.subunitate_id, 
            (response) =>{                
                setNume(response.nume);
                setAdresa(response.adresa);
                setTelefon(response.telefon);                

                setIsLoading(false);                
            }
        );
    }    

    const saveEdits = () =>{
        let isOkay = true;
        if(isEmptyOrSpaces(nume)){
            isOkay = false;
            setNumeInvalidMsg("Numele este obligatoriu");
        }
        if(isEmptyOrSpaces(adresa)){
            isOkay = false;
            setAdresaInvalidMsg("Adresa este obligatorie");
        }
        if(isEmptyOrSpaces(telefon)){
            isOkay = false;
            setTelefonInvalidMsg("Telefonul este obligatoriu");
        }        
        if(!isOkay)return;
        post('CompanyConfig/subunitati/UpdateSubunitate', {
            "id": props.match.params.subunitate_id,
            "nume": nume, 
            "adresa": adresa, 
            "telefon": telefon,             
        }, (response)=>{
            if(response == "invalid_request")
            {
                alert("Eroare la adaugare");
                return;
            }
            setIsConfirmationDialogOpen(true);            
        });
    }

    return (
        <>
            <h4><Link className="redirectNavigationLink" to='/subunitati'>Subunitate</Link> &gt; {nume}</h4>
            <br/>
            {isLoading && (
                <>                    
                    <LoadingCircleComponent/>
                </>
            )}
            {!isLoading && (
                <>                
                    <div className="row">
                        <div className="col-md-8">
                            <h6>Date identificare</h6>
                            <FormGroup 
                                label={'Nume companie'}
                                value={nume}      
                                onChange={(e) =>{ setNume(e.target.value); setIsSaveEnabled(true); }}      
                                use_validation={numeInvalidMsg != ''}            
                                is_valid = {numeInvalidMsg == ''}
                                invalid_feedback = {numeInvalidMsg}
                            />
                            <FormGroup 
                                label={'Adresa'}
                                value={adresa}      
                                onChange={(e) =>{ setAdresa(e.target.value); setIsSaveEnabled(true); }}          
                                use_validation={adresaInvalidMsg != ''}            
                                is_valid = {adresaInvalidMsg == ''}
                                invalid_feedback = {adresaInvalidMsg}        
                            />
                            <FormGroup 
                                label={'Telefon'}
                                value={telefon}      
                                onChange={(e) =>{ setTelefon(e.target.value); setIsSaveEnabled(true); }}     
                                use_validation={telefonInvalidMsg != ''}            
                                is_valid = {telefonInvalidMsg == ''}
                                invalid_feedback = {telefonInvalidMsg}             
                            />                            
                            <br/>
                            <div className="row">
                                <div className="col-md-3">
                                    <RoundButton
                                        disabled={!isSaveEnabled}
                                        className={isSaveEnabled ? "custom-btn-primary" : "custom-btn-primary disabled"}
                                        text="Salveaza"
                                        onClickHandler={saveEdits}
                                    />
                                </div>
                                <div className="col-md-6"></div>                        
                                <div className="col-md-3">
                                    <RoundButton                                        
                                        className={"btn-danger"}
                                        text="Sterge"
                                        onClickHandler={()=>{setIsDeleteDialogOpen(true);}}
                                    />
                                </div>
                            </div>
                        </div>                        
                    </div>
                </>
            )}
            <>                
                <DeleteConfirmationDialog
                    isOpen={isDeleteDialogOpen}
                    toggle={()=>{setIsDeleteDialogOpen(!isDeleteDialogOpen)}}
                    onConfirm={()=>{
                        post('CompanyConfig/subunitati/RemoveSubunitate', {
                            "id": props.match.params.subunitate_id
                        }, (response)=>{
                            if(response == false)
                            {
                                alert("Eroare la adaugare");
                                return;
                            }
                            setRedirectToResource("/subunitati");
                        });
                    }}
                />
                <UpdateResultSuccessDialog
                    isOpen={isConfirmationDialogOpen}
                    toggle={()=>{setIsConfirmationDialogOpen(!isConfirmationDialogOpen)}}
                    onConfirm={load_resources}
                />
                {redirectToResource != '' && (
                    <Redirect to={redirectToResource}/>
                )}
            </>
        </>
    );
}