import React, { useEffect } from 'react';
import {get_ajax} from 'shared/dist/utils/DataProvider';

export const Home = () => {

    return (
        <>
            
        </>
    )
}