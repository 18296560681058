import React, {useEffect, useState} from "react";
import {get_ajax, post} from 'shared/dist/utils/DataProvider';
import {LayoutCard} from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {RoundButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import { CreateResourceDialog, EditResourceDialog, DeleteConfirmationDialog } from 'shared/dist/components/generic-building-blocks/UserDialogs';
import {FaDatabase} from 'react-icons/fa';
import {IoIosConstruct} from 'react-icons/io';
import {IoAddCircle, IoCreate} from 'react-icons/io5';
import {VscSymbolMisc} from 'react-icons/vsc';
import {AiOutlineForm} from 'react-icons/ai';
import {FormGroup, FormGroupArea} from 'shared/dist/components/generic-building-blocks/FormGroup';
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {BsFillEyeSlashFill, BsFillEyeFill, BsThreeDotsVertical} from 'react-icons/bs';
import {MdUpdate} from 'react-icons/md';
import { configurableModelsList, sideOptionsList, skipDefaultNames} from "./constants";
import {configurableFieldType} from 'shared/dist/constants/constants';
import {DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem} from "reactstrap";
import {CimConfigDashboard} from "./CimConfigDashboard";
import {Redirect} from "react-router-dom";

export const CimConfigModels = (props) => {
    const [redirectToResource, setRedirectToResource] = useState(undefined);
    const [selectedType, setSelectedType] = useState(undefined);

    const [isLoading, setIsLoading] = useState(true);
    const [isAddNewModelDialogOpen, setIsAddNewModelDialogOpen] = useState(false);
    const [customModelsList, setCustomModelsList] = useState(configurableModelsList);
    useEffect(()=> {
        loadData();
    }, []);
    
    useEffect(()=>{
        setSelectedType(props.match.params.model_id);
    }, [props]);

    const loadData = () => {
        get_ajax(`CIMConfigurator/CustomModelConfigurator/GetCustomObjectTypes`, (custom_types) => {
            if(custom_types === undefined) return;
            const _customModelsList = [...customModelsList];
            for(let i = 0; i < custom_types.length; i++)
            {
                if(skipDefaultNames.includes(custom_types[i].type)) continue;
                if(_customModelsList.filter(x => x.type === custom_types[i].type).length === 0)
                {
                    _customModelsList.push({
                        name: custom_types[i].name,
                        type: custom_types[i].type,
                        description: ''
                    });
                }
            }
            setCustomModelsList(_customModelsList);
            setIsLoading(false);
        }).catch((e) => {
            setIsLoading(false);
        });
    }

    return (
        <>
            <CimConfigDashboard
                selectedKey={'models'}
            >
                {selectedType === undefined && (
                    <>
                        <div className={'row'}>
                            {isLoading === false && (
                                <>
                                    {customModelsList.map((option, id) =>
                                        <div className={'col-md-3'} key={id}>
                                            <LayoutCard
                                                key={id}
                                                onClick={()=>{
                                                    setRedirectToResource(`/cim/models/${option.type}`);
                                                }}
                                                className="layout_card layout_card_25bottom layout_card_padded shadow-sm cursor_pointer"
                                            >
                                                <h5>{option.name}</h5>
                                                <p>{option.description}</p>
                                            </LayoutCard>
                                        </div>
                                    )}
                                    <div className={'col-md-3'}>
                                        <LayoutCard
                                            onClick={()=>{setIsAddNewModelDialogOpen(true)}}
                                            className="layout_card layout_card_25bottom layout_card_padded shadow-sm cursor_pointer center"
                                        >
                                            <IoAddCircle size={48}/>
                                        </LayoutCard>
                                    </div>
                                </>
                            )}
                        </div>
                        {isLoading && (
                            <LoadingCircleComponent/>
                        )}
                        <CreateCustomModelModal
                            isOpen={isAddNewModelDialogOpen}
                            toggle={()=>{
                                setIsAddNewModelDialogOpen(false);
                                loadData();
                            }}
                            callBack={(modelName) => {
                                post('CIMConfigurator/CustomModelConfigurator/AddCustomModel',
                                    {
                                        'customModelName': modelName,
                                    }, (response) => {
                                        if(response === true)
                                        {
                                            setIsAddNewModelDialogOpen(false);
                                            loadData();
                                            return;
                                        }
                                        alert("Eroare la adaugare");
                                });
                            }}
                        />
                    </>
                )}
                {(selectedType && isLoading === false) && (
                    <EditableDataModelsFieldsEditor
                        type={selectedType}
                        goBack={()=>{ setRedirectToResource('/cim/models') }}
                        modelName={customModelsList.filter(x => x.type == selectedType)[0].name}
                    />
                )}
            </CimConfigDashboard>
            {(redirectToResource !== undefined) && (
                <Redirect to={redirectToResource} />
            )}
        </>
    );
}

const EditableDataModelsFieldsEditor = ({type, goBack, modelName}) => {
    const loadData = () => {
        get_ajax(`CIMConfigurator/CustomModelConfigurator/RetrieveFields?type=${type}`, (data) => {
            if(data === undefined) return;
            setFields(data.fields);
            get_ajax(`CIMConfigurator/CustomModelConfigurator/GetSearchFormula?type=${type}`, (formula) => {
                setSearchFormulaValue(formula);
                setIsLoading(false);
            });
        }).catch((e) => {
            setIsLoading(false);
        });
    }

    useEffect(()=>{
        setIsLoading(true);
        loadData();
    }, [type]);

    const [isAddFieldDialogOpen, setIsAddFieldDialogOpen] = useState(false);
    const [isFieldEditDialogOpen, setIsFieldEditDialog] = useState(undefined);

    const [isLoading, setIsLoading] = useState(true);
    const [fields, setFields] = useState([]);

    const [showDefaultFields, setShowDefaultFields] = useState(false);
    const [searchFormulaValue, setSearchFormulaValue] = useState('');
    
    const [newFromCopyModalVisible, setNewFromCopyModalVisible] = useState(false);
    const [confirmDeleteDialogVisible, setConfirmDeleteDialogVisible] = useState(false);
    
    return (
        <>
            <DeleteConfirmationDialog
                isOpen={confirmDeleteDialogVisible}
                toggle={()=>{setConfirmDeleteDialogVisible(!confirmDeleteDialogVisible)}}
                onConfirm={()=>{
                    post('CIMConfigurator/CustomModelConfigurator/RemoveType',
                        {
                            'type': type
                        }, (response) => {
                            if(response === true)
                            {
                                goBack();
                                return;
                            }
                            alert("Eroare la adaugare");
                        });
                }}
            />
            <CreateCustomModelModal
                isOpen={newFromCopyModalVisible}
                toggle={()=>{
                    setNewFromCopyModalVisible(false);
                }}
                callBack={(modelName) => {
                    post('CIMConfigurator/CustomModelConfigurator/AddCustomModelBasedOn',
                        {
                            'customModelName': modelName,
                            'baseModel': type
                        }, (response) => {
                            if(response === true)
                            {
                                goBack();
                                return;
                            }
                            alert("Eroare la adaugare");
                        });
                }}
            />
            <LayoutCard
                className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}
            >
                <div className={'row'}>
                    <div className={'col-md-1'}>
                    <span
                        style={{cursor: 'pointer'}}
                        className={"redirectNavigationLink"}
                        onClick={()=>{goBack()}}
                    >Inapoi</span>
                    </div>
                    <div className={'col-md-5'}>
                        <h5 style={{textAlign: 'center'}}>{modelName}</h5>
                    </div>
                    <div className={'col-md-6'}>
                        <div className={'row'}>
                            <div className={'col-md-6'}>

                            </div>
                            <div className={'col-md-2'}>
                                <RoundButton
                                    className={showDefaultFields ? "custom-btn-primary" : "btn-secondary"}
                                    text={!showDefaultFields ? (<BsFillEyeSlashFill/>) : (<BsFillEyeFill/>)}
                                    onClickHandler={() => { setShowDefaultFields(!showDefaultFields); }}
                                />
                            </div>
                            <div className={'col-md-2'}>
                                <RoundButton
                                    className={"custom-btn-primary"}
                                    text={(<IoCreate/>)}
                                    onClickHandler={() => { setIsAddFieldDialogOpen(true); }}
                                />
                            </div>
                            <div className={'col-md-2'}>
                                <UncontrolledDropdown>
                                    <DropdownToggle className={'no-outline btn btn-block rounded_input custom-btn-primary'}>
                                        <BsThreeDotsVertical/>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={()=>{setNewFromCopyModalVisible(true);}}>Copie in model nou</DropdownItem>
                                        <DropdownItem onClick={()=>{setConfirmDeleteDialogVisible(true);}}>Sterge</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <table className="table tableLastRight">
                    <thead>
                    <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Denumire</th>
                        <th scope="col">Tip</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {showDefaultFields && (
                        <>
                            <tr className={"disabled_grayscale"}>
                                <td>@adaugat_la</td>
                                <td>Adaugat la</td>
                                <td>Data</td>
                                <td></td>
                            </tr>
                            <tr className={"disabled_grayscale"}>
                                <td>@adaugat_de</td>
                                <td>Adaugat de</td>
                                <td>Utilizator</td>
                                <td></td>
                            </tr>
                            <tr className={"disabled_grayscale"}>
                                <td>@modificat_la</td>
                                <td>Modificat la</td>
                                <td>Data</td>
                                <td></td>
                            </tr>
                            <tr className={"disabled_grayscale"}>
                                <td>@modificat_de</td>
                                <td>Modificat de</td>
                                <td>Utilizator</td>
                                <td></td>
                            </tr>
                        </>
                    )}
                    {fields.map((field, i) =>
                        <tr key={i}>
                            <td>{field.id}</td>
                            <td>{field.name}</td>
                            <td>{configurableFieldType[field.type].name}</td>
                            <td style={{maxWidth: '40px'}}>
                                <RoundButton
                                    className={"btn-secondary"}
                                    text={(<BsThreeDotsVertical/>)}
                                    onClickHandler={()=>{ setIsFieldEditDialog(field); }}
                                />
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
                {isLoading && (
                    <LoadingCircleComponent/>
                )}
                {(!isLoading && fields.length === 0) && (
                    <p style={{textAlign: 'center'}}>Nu exista campuri. Adauga un camp mai sus.</p>
                )}
            </LayoutCard>
            <LayoutCard>
                <h6><b>Configurare interactiune modele</b></h6> <br/>
                <FormGroup
                    label={"Formula cautare"}
                    value={searchFormulaValue}
                    onChange={(e) => {
                        setSearchFormulaValue(e.target.value);
                        post('CIMConfigurator/CustomModelConfigurator/UpdateSearchFormula', {
                            'type': type,
                            'formula': e.target.value
                        }, (response) => {
                            if(response === false) {alert("Eroare la actualiazre"); }
                        })
                    }}
                />
            </LayoutCard>
            <br/>
            <CreateFieldModal
                isOpen={isAddFieldDialogOpen}
                toggle={()=>{setIsAddFieldDialogOpen(false)}}
                type={type}
                reloadCallback={()=>{
                    setIsAddFieldDialogOpen(false);
                    loadData();
                }}
            />
            <EditFieldModal
                isOpen={isFieldEditDialogOpen !== undefined}
                toggle={()=>{setIsFieldEditDialog(undefined)}}
                type={type}
                field={isFieldEditDialogOpen}
                reloadCallback={()=>{
                    setIsFieldEditDialog(undefined);
                    loadData();
                }}
            />
        </>
    );
}

const CreateCustomModelModal = ({isOpen, toggle, callBack}) => {
    const [modelName, setModelName] = useState('');
    return (
        <CreateResourceDialog
            isOpen={isOpen}
            toggle={toggle}
            size={'md'}
            title={'Adauga model'}
            onConfirm={()=>{
                callBack(modelName);
            }}
        >
            <FormGroup
                label={'Nume model'}
                value={modelName}
                onChange={(e) =>{ setModelName(e.target.value); }}
            />
        </CreateResourceDialog>
    );
}
const CreateFieldModal = ({isOpen, toggle, type, reloadCallback}) => {
    const [fieldName, setFieldName] = useState('');
    const [fieldId, setFieldId] = useState('');
    const [fieldType, setFieldType] = useState(0);
    const [fieldCustomFieldDetails, setFieldCustomFieldDetails] = useState();

    return (
        <CreateResourceDialog
            isOpen={isOpen}
            toggle={toggle}
            size={'md'}
            title={'Adauga camp'}
            onConfirm={()=>{
                post('CIMConfigurator/CustomModelConfigurator/AddNewFieldToCustomModel',
                    {
                        'type': type,
                        'field': {
                            'Id': fieldId,
                            'Name': fieldName,
                            'DefaultValue': '',
                            'Type': fieldType,
                            'CustomFieldDetails': JSON.stringify(fieldCustomFieldDetails)
                        },
                    }, (response) => {
                        if(response === true)
                        {
                            reloadCallback();
                            return;
                        }
                        alert("Eroare la adaugare");
                    });
            }}
        >
            <FormGroup
                label={'Nume camp'}
                value={fieldName}
                onChange={(e) =>{
                    setFieldName(e.target.value);
                    if(e.target.value) {
                        setFieldId(`@${e.target.value.toLowerCase()
                            .trim()
                            .replace(/\s\s+/g, ' ')
                            .replaceAll(' ', '_')}`);
                    } else {
                        setFieldId('');
                    }
                }}
            />
            <FormGroup
                label={'Id'}
                value={fieldId}
                onChange={(e) =>{ setFieldId(e.target.value); }}
            />
            <div className={'form-group'}>
                <label>Tip camp:</label>
                <select className={'form-control'} value={fieldType} onChange={(e) => setFieldType(e.target.value)}>
                    {configurableFieldType.map(option =>
                        <option key={option.value} value={option.value}>{option.name}</option>
                    )}
                </select>
            </div>
            {parseInt(`${fieldType}`) === 5 && ( <ListFieldDetails data={fieldCustomFieldDetails} setData={setFieldCustomFieldDetails}/> )}
            {parseInt(`${fieldType}`) === 6 && ( <ExternalObjectFieldDetails data={fieldCustomFieldDetails} setData={setFieldCustomFieldDetails}/> )}
        </CreateResourceDialog>
    );
}
const EditFieldModal = ({isOpen, toggle, type, field, reloadCallback}) => {
    const [fieldName, setFieldName] = useState('');
    const [fieldId, setFieldId] = useState('');
    const [fieldType, setFieldType] = useState(0);
    const [fieldCustomFieldDetails, setFieldCustomFieldDetails] = useState();

    useEffect(() => {
        if(field === undefined) return;
        setFieldName(field.name);
        setFieldId(field.id);
        setFieldType(field.type);
        setFieldCustomFieldDetails(JSON.parse(field.customFieldDetails));
    }, [field, type]);

    return (
        <EditResourceDialog
            isOpen={isOpen}
            toggle={toggle}
            size={'md'}
            title={'Modifica camp'}
            onConfirm={()=>{
                post('CIMConfigurator/CustomModelConfigurator/UpdateFieldOnCustomModel',
                    {
                        'type': type,
                        'field': {
                            'Id': fieldId,
                            'Name': fieldName,
                            'DefaultValue': '',
                            'Type': fieldType,
                            'CustomFieldDetails': JSON.stringify(fieldCustomFieldDetails)
                        },
                    }, (response) => {
                        if(response === true)
                        {
                            reloadCallback();
                            return;
                        }
                        alert("Eroare la adaugare");
                    });
            }}
            onDelete={()=>{
                toggle();
                post('CIMConfigurator/CustomModelConfigurator/DeleteFieldFromCustomModel',
                    {
                        'type': type,
                        'fieldId': fieldId,
                    }, (response) => {
                        if(response === true)
                        {
                            reloadCallback();
                            return;
                        }
                        alert("Eroare la adaugare");
                    });
            }}
        >
            <FormGroup
                label={'Nume camp'}
                value={fieldName}
                onChange={(e) =>{
                    setFieldName(e.target.value);
                }}
            />
            <FormGroup
                label={'Id'}
                value={fieldId}
                disabled={true}
                onChange={()=>{}}
            />
            <div className={'form-group'}>
                <label>Tip camp:</label>
                <select className={'form-control'} value={fieldType} onChange={(e) => setFieldType(e.target.value)}>
                    {configurableFieldType.map(option =>
                        <option key={option.value} value={option.value}>{option.name}</option>
                    )}
                </select>
            </div>
            {parseInt(`${fieldType}`) === 5 && ( <ListFieldDetails data={fieldCustomFieldDetails} setData={setFieldCustomFieldDetails}/> )}
            {parseInt(`${fieldType}`) === 6 && ( <ExternalObjectFieldDetails data={fieldCustomFieldDetails} setData={setFieldCustomFieldDetails}/> )}
        </EditResourceDialog>
    );
}

const ListFieldDetails = ({data, setData}) => {
    return (
        <>
            <FormGroupArea
                label={'Lista elemente'}
                value={data}
                rows={10}
                onChange={(e) => {
                    setData(e.target.value);
                }}
            />
            <small>Adaugati elementele pe cate un rand, cu ; la final</small>
        </>
    );
}

const ExternalObjectFieldDetails = ({data, setData}) => {
    useEffect(()=>{
        get_ajax("CIMConfigurator/CustomModelConfigurator/GetExternalMappingOptions", (options) => {
            setPossibleOptions(options);
        })
    }, [data]);
    const [possibleOptions, setPossibleOptions] = useState([]);
    return (
        <>
            <div className={'form-group'}>
                <label>Obiect extern</label>
                <select className={'form-control'} value={data} onChange={(e)=>{setData(e.target.value)}}>
                    {possibleOptions.map(option =>
                        <option value={option.value}>{option.name}</option>
                    )}
                </select>
            </div>
        </>
    );
}