import React, { useEffect, useState } from 'react';
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {get_ajax, post} from 'shared/dist/utils/DataProvider';
import {AiFillCaretDown, AiFillCaretUp} from 'react-icons/ai';
import {BsArrowReturnRight} from 'react-icons/bs';
import {FormGroup, FormGroupArea} from 'shared/dist/components/generic-building-blocks/FormGroup';
import {RoundButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import { CreateResourceDialog, CustomResourceModal, DeleteConfirmationDialog, UpdateResultSuccessDialog } from 'shared/dist/components/generic-building-blocks/UserDialogs';

import AOS from 'aos';
import "aos/dist/aos.css";

export const CompanyStructure = () => {    

    const getTreeData = (nestedTreeData) => {
        return nestedTreeData.map((item) => ({
            ...item,
            hasChildren:
                nestedTreeData.filter((i) => i.parentId === item.id).length > 0,
        }));
    }

    const [treeStructure, setTreeStructure] = useState([]);
    const [loadedStructure, setLoadedStructure] = useState(false);

    useEffect(()=>{
        AOS.init();  
        loadCompartimentsGraph();
    }, []);

    const loadCompartimentsGraph = () => {
        get_ajax("CompanyConfig/Compartimente/GetCompartimente", (response) => {
            setTreeStructure(getTreeData(response));         
            setLoadedStructure(true);            
        });
    }

    const [selectedNodeId, setSelectedNodeId] = useState(undefined);
    const [nodeDetailsLoaded, setNodeDetailsLoaded] = useState(false);

    const [selectedNodeNume, setSelectedNodeNume] = useState('');
    const [selectedNodeParent, setSelectedNodeParent] = useState('');

    useEffect(()=>{
        if(selectedNodeId == undefined) return;
        get_ajax("CompanyConfig/Compartimente/GetCompartiment?compartimentId=" + selectedNodeId, (response) =>{
            setSelectedNodeNume(response.nume);
            setSelectedNodeParent(response.parentId);
            setNodeDetailsLoaded(true);
        }); 
    }, [selectedNodeId]);

    const [newNodeDialogOpen, setNewNodeDialogOpen] = useState(false);
    const [newNodeName, setNewNodeName] = useState('');

    const [isDeleteNodeDialogOpen, setIsDeleteNodeDialogOpen] = useState(false);
    const [isSaveSuccessfullConfirmationVisible, setIsSaveSuccessfullConfirmationVisible] = useState(false);

    const [isFlatImportDialogOpen, setIsFlatImportDialogOpen] = useState(false);
    const [flatImportListValue, setFlatImportListValue] = useState('');

    return(
        <>
            <div className='row'>
                <div className='col-md-8'>
                    <h4>Structura</h4>
                </div>
                <div className='col-md-4'>
                    <RoundButton
                        className="custom-btn-primary"
                        text={'Importa lista'}
                        onClickHandler={() => {setIsFlatImportDialogOpen(true)}}
                    />
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-md-6">
                    {loadedStructure && (
                        <div data-aos="flip-up" data-aos-delay={0}>
                            <LayoutCard
                                className="layout_card_no_click"                            
                            >
                                <Tree 
                                    treeData={treeStructure} 
                                    setSelectedNodeId={setSelectedNodeId}
                                    selectedNodeId={selectedNodeId}
                                />
                            </LayoutCard>
                        </div>
                    )}                    
                </div>
                <div className="col-md-6">
                    {(selectedNodeId != undefined && nodeDetailsLoaded) && (
                        <div
                            data-aos="flip-up" data-aos-delay={50}
                        >
                            <h6>Detalii compartiment</h6>  
                            <FormGroup 
                                label={'Nume compartiment'}
                                value={selectedNodeNume}      
                                onChange={(e) =>{ setSelectedNodeNume(e.target.value); }}      
                            />
                            <div className="row">
                                <div className="col-md-6">
                                    <RoundButton
                                        className="custom-btn-primary"
                                        text="Adauga compartiment"
                                        onClickHandler={()=>{ setNewNodeName(''); setNewNodeDialogOpen(true); }}
                                    />
                                </div>                                                 
                                <div className="col-md-3">
                                    {selectedNodeParent != 'root' && (
                                        <RoundButton
                                            className={"btn-danger"}
                                            text="Sterge"
                                            onClickHandler={()=>{ setIsDeleteNodeDialogOpen(true); }}
                                        />
                                    )}                                    
                                </div> 
                                <div className="col-md-3">
                                    <RoundButton
                                        className="custom-btn-primary"
                                        text="Salveaza"
                                        onClickHandler={()=>{
                                            post('CompanyConfig/Compartimente/UpdateCompartiment',
                                            {
                                                'numeCompartiment': selectedNodeNume,
                                                'id': selectedNodeId
                                            }, (response) =>{
                                                if(response == false) {
                                                    alert("Eroare la salvare");
                                                    return;
                                                }                                                
                                                loadCompartimentsGraph();   
                                                setIsSaveSuccessfullConfirmationVisible(true);
                                            })
                                        }}
                                    />
                                </div>                                                 
                            </div>
                        </div>
                    )}                    
                </div>
            </div>
            <CreateResourceDialog
                isOpen={newNodeDialogOpen}
                toggle={()=>{setNewNodeDialogOpen(!newNodeDialogOpen)}}
                size={'md'}                
                onConfirm={()=>{
                    post('CompanyConfig/Compartimente/CreateCompartiment',
                    {
                        'numeCompartiment': newNodeName,
                        'parentCompartiment': selectedNodeId
                    }, (response) =>{
                        if(response == false) {
                            alert("Eroare la adaugare");
                            return;
                        }
                        setNewNodeDialogOpen(!newNodeDialogOpen);
                        loadCompartimentsGraph();
                        setSelectedNodeId(undefined);
                    })
                }}  
            >
                <FormGroup 
                    label={'Nume compartiment'}
                    value={newNodeName}      
                    onChange={(e) =>{ setNewNodeName(e.target.value); }}                        
                />
            </CreateResourceDialog>
            <DeleteConfirmationDialog
                isOpen={isDeleteNodeDialogOpen}
                toggle={()=>{setIsDeleteNodeDialogOpen(!isDeleteNodeDialogOpen)}}
                onConfirm={()=>{
                    post('CompanyConfig/Compartimente/DeleteCompartiment',
                    {                        
                        'id': selectedNodeId
                    }, (response) =>{
                        if(response == false) {
                            alert("Eroare la stergere");
                            return;
                        }                        
                        loadCompartimentsGraph();
                        setSelectedNodeId(undefined);
                    })
                }}
            />
            <UpdateResultSuccessDialog
                isOpen={isSaveSuccessfullConfirmationVisible}
                toggle={()=>{setIsSaveSuccessfullConfirmationVisible(!isSaveSuccessfullConfirmationVisible)}}
                onConfirm={()=>{}}
                label={"Salvat cu succes"}
            />

            <CustomResourceModal
                size={'lg'}
                isOpen={isFlatImportDialogOpen}
                toggle={() => setIsFlatImportDialogOpen(false)}
                title={"Incarca lista compartimente"}
            >
                <FormGroupArea
                    rows={10}
                    value={flatImportListValue}
                    onChange={(e) => setFlatImportListValue(e.target.value)}
                />
                <RoundButton
                    className="custom-btn-primary"
                    text={'Adauga compartimente'}
                    onClickHandler={() => {
                        post('CompanyConfig/Compartimente/UploadCompartimenteList',
                        {
                            'list': flatImportListValue
                        }, (response) => {
                            if(response === false)
                                alert("Eroare la import.");
                            setIsFlatImportDialogOpen(false);
                            loadCompartimentsGraph();
                        });
                    }}
                />
            </CustomResourceModal>
        </>
    );
}

function Tree({ treeData,setSelectedNodeId, selectedNodeId, parentId = "root", level = 0 }) {        
    const items = treeData
        .filter((item) => item.parentId === parentId)
        .sort((a, b) => (a.nume > b.nume ? 1 : -1));
    if (!items.length) return null;    
    return (
        <>
            {items.map((item) =>                 
                <TreeItem key={item.id} selectedNodeId={selectedNodeId} item={item} level={level} setSelectedNodeId={setSelectedNodeId} parentId={parentId} treeData={treeData}/>
            )}
        </>
    );
}

function TreeItem({item, level, selectedNodeId, setSelectedNodeId, parentId,treeData}) {        
    const [isOpen, setIsOpen] = useState(level == 0);    
    return(
        <div key={item.id} level={level} className="treeBlock" style={(level != 0 && level != 1) ? { paddingLeft: '15px' } : {}}>
            <label className={selectedNodeId == item.id ? "label_selected" : ""} style={{ fontSize: '16', cursor: 'pointer' }} onClick={()=>{setSelectedNodeId(item.id)}}>
                {level != 0 && (    
                    <BsArrowReturnRight />
                )}                      
                {item.nume}                        
            </label>
            {item.hasChildren && (
                <>
                    <button onClick={()=>{setIsOpen(!isOpen)}} className="btn btn-secondary no-outline float-right" id={parentId + "_" + level + "_" + item.id}>
                        {isOpen == false && (
                            <AiFillCaretDown className="float-right" />
                        )}
                        {isOpen == true && (
                            <AiFillCaretUp className="float-right" />
                        )}
                    </button>
                    {isOpen && (
                        <Tree treeData={treeData} selectedNodeId={selectedNodeId} setSelectedNodeId={setSelectedNodeId} parentId={item.id} level={level + 1} />
                    )}
                </>
            )}                    
        </div>
    );
}