import React, {useState, useMemo, useCallback, useRef, useEffect} from 'react';
import { createEditor, Editor, Transforms, Range } from "slate";
import { createPortal } from 'react-dom';
import { cx, css } from "@emotion/css";
import {
    Slate,
    Editable,
    ReactEditor,
    withReact,
    useSelected,
    useFocused,
    useSlate,
} from "slate-react";
import { withHistory } from "slate-history";

const LIST_TYPES = ["numbered-list", "bulleted-list"];

export const DocumentEditor = ({documentJSON, updateDocumentJSON, modelFields}) => {
    const ref = useRef();
    const renderElement = useCallback((props) => <Element {...props} />, []);
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
    const editor = useMemo(
        () => withHistory(withMentions(withReact(withHistory(createEditor())))),
        []
    );

    const [value, setValue] = useState(
        documentJSON ? JSON.parse(documentJSON) :
        [
            {
                type: 'paragraph',
                children: [
                    { text: 'Introduceti textul aici...' }
                ]
            }
        ]
    );

    const [target, setTarget] = useState();
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState("");
    const chars = modelFields.filter((c) =>
        c.toLowerCase().includes(search.toLowerCase())
    );
    const onKeyDown = useCallback(
        (event) => {
            if (target) {
                switch (event.key) {
                    case "ArrowDown":
                        event.preventDefault();
                        const prevIndex = index >= chars.length - 1 ? 0 : index + 1;
                        setIndex(prevIndex);
                        break;
                    case "ArrowUp":
                        event.preventDefault();
                        const nextIndex = index <= 0 ? chars.length - 1 : index - 1;
                        setIndex(nextIndex);
                        break;
                    case "Tab":
                    case "Enter":
                        event.preventDefault();
                        Transforms.select(editor, target);
                        insertMention(editor, chars[index]);
                        setTarget(null);
                        break;
                    case "Escape":
                        event.preventDefault();
                        setTarget(null);
                        break;
                }
            }
        },
        [index, search, target]
    );

    useEffect(() => {
        if (target && chars.length > 0) {
            const el = ref.current;
            const domRange = ReactEditor.toDOMRange(editor, target);
            const rect = domRange.getBoundingClientRect();
            el.style.top = `${rect.top + window.pageYOffset + 24}px`;
            el.style.left = `${rect.left + window.pageXOffset}px`;
        }
    }, [chars.length, editor, index, search, target]);
    
    return (
        <>
            <Slate 
                editor={editor} 
                value={value}
                onChange={(value) => {
                    setValue(value);
                    const content = JSON.stringify(value);
                    updateDocumentJSON(content);

                    const { selection } = editor;

                    if (selection && Range.isCollapsed(selection)) {
                        const [start] = Range.edges(selection);
                        const wordBefore = Editor.before(editor, start, { unit: 'word' });
                        const before = wordBefore && Editor.before(editor, wordBefore);
                        const beforeRange = before && Editor.range(editor, before, start);
                        const beforeText = beforeRange && Editor.string(editor, beforeRange);
                        const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
                        const after = Editor.after(editor, start);
                        const afterRange = Editor.range(editor, start, after);
                        const afterText = Editor.string(editor, afterRange);
                        const afterMatch = afterText.match(/^(\s|$)/);

                        if (beforeMatch && afterMatch) {
                            setTarget(beforeRange);
                            setSearch(beforeMatch[1]);
                            setIndex(0);
                            return;
                        }
                    }

                    setTarget(null);
                }}
            >
                <Toolbar>
                    <MarkButton format="bold" icon="format_bold" />
                    <MarkButton format="italic" icon="format_italic" />
                    <MarkButton format="underline" icon="format_underlined" />
                    <MarkButton format="overline" icon="format_overlined" />
                    <MarkButton format="middleline" icon="format_middlelined" />

                    <BlockButton format="heading-one" icon="looks_one" />
                    <BlockButton format="heading-two" icon="looks_two" />
                    <BlockButton format="heading-three" icon="looks_three" />
                    <BlockButton format="heading-four" icon="looks_four" />
                    <BlockButton format="heading-five" icon="looks_five" />
                    <BlockButton format="heading-six" icon="looks_six" />
                    <BlockButton format="numbered-list" icon="format_list_numbered" />
                    <BlockButton format="bulleted-list" icon="format_list_bulleted" />

                    <AlignButton format="align-left" icon="align_left" />
                    <AlignButton format="align-center" icon="align_center" />
                    <AlignButton format="align-right" icon="align_right" />
                    <AlignButton format="align-justify" icon="align_justify" />

                </Toolbar>
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    onKeyDown={onKeyDown}
                />
                {target && chars.length > 0 && (
                    <>
                        {createPortal(
                            <div
                                ref={ref}
                                style={{
                                    top: "-9999px",
                                    left: "-9999px",
                                    position: "absolute",
                                    zIndex: 1,
                                    padding: "3px",
                                    background: "white",
                                    borderRadius: "4px",
                                    boxShadow: "0 1px 5px rgba(0,0,0,.2)",
                                }}
                            >
                                {chars.map((char, i) => (
                                    <div
                                        key={char}
                                        style={{
                                            padding: "1px",
                                            borderRadius: "3px",
                                            background: i === index ? "#B4D5FF" : "transparent",
                                        }}
                                    >
                                        {char}
                                    </div>
                                ))}
                            </div>, document.body)}
                    </>
                )}
            </Slate>
        </>
    );
};


const withMentions = (editor) => {
    const { isInline, isVoid } = editor;

    editor.isInline = (element) => {
        return element.type === "mention" ? true : isInline(element);
    };

    editor.isVoid = (element) => {
        return element.type === "mention" ? true : isVoid(element);
    };

    return editor;
};

const insertMention = (editor, character) => {
    const mention = { type: "mention", character, children: [{ text: "" }] };
    Transforms.insertNodes(editor, mention);
    Transforms.move(editor);
};

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: (n) => LIST_TYPES.includes(n.type),
        split: true,
    });

    Transforms.setNodes(editor, {
        type: isActive ? "paragraph" : isList ? "list-item" : format,
    });

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

const toggleAlign = (editor, format) => {
    // const isActive = isAlignActive(editor, format);
    Transforms.setNodes(editor, {
        alignment: format
    });
};

const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: (n) => n.type === format,
    });

    return !!match;
};

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

const isAlignActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: (n) => n.alignment === format,
    });
    return !!match;
}

const Element = (props) => {
    const { attributes, children, element } = props;

    switch (element.alignment) {
        case "align-center":
            return <div style={{ textAlign: 'center' }}>{renderInnerElement(props)}</div>;
        case "align-left":
            return <div style={{ textAlign: 'left' }}>{renderInnerElement(props)}</div>;
        case "align-right":
            return <div style={{ textAlign: 'right' }}>{renderInnerElement(props)}</div>;
        case "align-justify":
            return <div style={{ textAlign: 'justify' }}>{renderInnerElement(props)}</div>;
        default:
            return renderInnerElement(props);
    }
};

const renderInnerElement = (props) => {
    const { attributes, children, element } = props;
    switch (element.type) {
        case "mention":
            return <MentionElement {...props} />;
        case "block-quote":
            return <blockquote {...attributes}>{children}</blockquote>;
        case "bulleted-list":
            return <ul {...attributes}>{children}</ul>;
        case "heading-one":
            return <h1 {...attributes}>{children}</h1>;
        case "heading-two":
            return <h2 {...attributes}>{children}</h2>;
        case "heading-three":
            return <h3 {...attributes}>{children}</h3>;
        case "heading-four":
            return <h4 {...attributes}>{children}</h4>;
        case "heading-five":
            return <h5 {...attributes}>{children}</h5>;
        case "heading-six":
            return <h6 {...attributes}>{children}</h6>;
        case "list-item":
            return <li {...attributes}>{children}</li>;
        case "numbered-list":
            return <ol {...attributes}>{children}</ol>;
        default:
            return <p {...attributes}>{children}</p>;
    }
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code) {
        children = <code>{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    if (leaf.overline) {
        children = <div style={{ textDecoration: 'overline' }}>{children}</div>
    }

    if (leaf.middleline) {
        children = <div style={{ textDecoration: 'line-through' }}>{children}</div>
    }

    return <span {...attributes}>{children}</span>;
};

const MentionElement = ({ attributes, children, element }) => {
    const selected = useSelected();
    const focused = useFocused();
    return (
        <span
            {...attributes}
            contentEditable={false}
            style={{
                padding: "3px 3px 2px",
                margin: "0 1px",
                verticalAlign: "baseline",
                display: "inline-block",
                borderRadius: "4px",
                backgroundColor: "#eee",
                fontSize: "0.9em",
                boxShadow: selected && focused ? "0 0 0 2px #EE6352" : "none",
            }}
        >
      @{element.character}
            {children}
    </span>
    );
};

const BlockButton = ({ format, icon }) => {
    let icon_svg = '';
    switch (icon) {
        case 'format_list_bulleted':
            icon_svg = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>;
            break;
        case 'format_list_numbered':
            icon_svg = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>;
            break;
        case 'looks_one':
            icon_svg = <div>H1</div>;
            break;
        case 'looks_two':
            icon_svg = <div>H2</div>;
            break;
        case 'looks_three':
            icon_svg = <div>H3</div>;
            break;
        case 'looks_four':
            icon_svg = <div>H4</div>;
            break;
        case 'looks_five':
            icon_svg = <div>H5</div>;
            break;
        case 'looks_six':
            icon_svg = <div>H6</div>;
            break;
        default:
            icon_svg = <Icon>{icon}</Icon>;
            break;
    }
    const editor = useSlate();
    return (
        <Button
            active={isBlockActive(editor, format)}
            onMouseDown={(event) => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
        >
            {icon_svg}
        </Button>
    );
};

const MarkButton = ({ format, icon }) => {
    let icon_svg = '';
    switch (icon) {
        case 'format_italic':
            icon_svg = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-italic"><line x1="19" y1="4" x2="10" y2="4"></line><line x1="14" y1="20" x2="5" y2="20"></line><line x1="15" y1="4" x2="9" y2="20"></line></svg>;
            break;
        case 'format_bold':
            icon_svg = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bold"><path d="M6 4h8a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path><path d="M6 12h9a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path></svg>;
            break;
        case 'format_underlined':
            icon_svg = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-underline"><path d="M6 3v7a6 6 0 0 0 6 6 6 6 0 0 0 6-6V3"></path><line x1="4" y1="21" x2="20" y2="21"></line></svg>;
            break;
        case 'format_overlined':
            icon_svg = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" space="preserve">
                <path stroke="currentColor" strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' fill='none' d="M6,7v7c0,3.3,2.7,6,6,6s6-2.7,6-6V7" />
                <line x1="4" y1="3" x2="20" y2="3" stroke="currentColor" strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' fill='none' />
            </svg>;
            break;
        case 'format_middlelined':
            icon_svg =
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" space="preserve">
                    <path d="M6,5v7c0,3.3,2.7,6,6,6s6-2.7,6-6V5" stroke="currentColor" strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' fill='none' />
                    <line x1="4" y1="11" x2="20" y2="11" stroke="currentColor" strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' fill='none' />
                </svg>;
            break;
        case 'code':
            icon_svg = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-code"><polyline points="16 18 22 12 16 6"></polyline><polyline points="8 6 2 12 8 18"></polyline></svg>;
            break;
        default:
            icon_svg = <Icon>{icon}</Icon>;
            break;
    }
    const editor = useSlate();
    return (
        <Button
            active={isMarkActive(editor, format)}
            onMouseDown={(event) => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
        >
            {icon_svg}
        </Button>
    );
};

const AlignButton = ({ format, icon }) => {
    let icon_svg = '';
    switch (icon) {
        case 'align_left':
            icon_svg = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-align-left"><line x1="17" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="17" y1="18" x2="3" y2="18"></line></svg>;
            break;
        case 'align_right':
            icon_svg = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-align-right"><line x1="21" y1="10" x2="7" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="21" y1="18" x2="7" y2="18"></line></svg>;
            break;
        case 'align_center':
            icon_svg = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-align-center"><line x1="18" y1="10" x2="6" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="18" y1="18" x2="6" y2="18"></line></svg>;
            break;
        case 'align_justify':
            icon_svg = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-align-justify"><line x1="21" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="21" y1="18" x2="3" y2="18"></line></svg>;
            break;
        default:
            icon_svg = <Icon>{icon}</Icon>
            break;
    }
    const editor = useSlate();
    return (
        <Button
            active={isAlignActive(editor, format)}
            onMouseDown={(event) => {
                event.preventDefault();
                toggleAlign(editor, format);
            }}>
            {icon_svg}
        </Button>
    );
};

const Button = React.forwardRef(({ className, active, reversed, ...props }, ref) => (
        <span
            {...props}
            ref={ref}
            className={cx(
                className,
                css`
          cursor: pointer;
          color: ${reversed
                    ? active
                        ? "white"
                        : "#aaa"
                    : active
                        ? "black"
                        : "#ccc"};
        `
            )}
        />
    ));

const EditorValue = React.forwardRef(({ className, value, ...props }, ref) => {
        const textLines = value.document.nodes
            .map((node) => node.text)
            .toArray()
            .join("\n");
        return (
            <div
                ref={ref}
                {...props}
                className={cx(
                    className,
                    css`
            margin: 30px -20px 0;
          `
                )}
            >
                <div
                    className={css`
            font-size: 14px;
            padding: 5px 20px;
            color: #404040;
            border-top: 2px solid #eeeeee;
            background: #f8f8f8;
          `}
                >
                    Slate's value as text
                </div>
                <div
                    className={css`
            color: #404040;
            font: 12px monospace;
            white-space: pre-wrap;
            padding: 10px 20px;
            div {
              margin: 0 0 0.5em;
            }
          `}
                >
                    {textLines}
                </div>
            </div>
        );
    });

const Icon = React.forwardRef(({ className, ...props }, ref) => (
    <span
        {...props}
        ref={ref}
        className={cx(
            "material-icons",
            className
        )}
    />
));

const Instruction = React.forwardRef(({ className, ...props }, ref) => (
    <div
        {...props}
        ref={ref}
        className={cx(
            className,
            css`
        white-space: pre-wrap;
        margin: 0 -20px 10px;
        padding: 10px 20px;
        font-size: 14px;
        background: #f8f8e8;
      `
        )}
    />
));

const Menu = React.forwardRef(({ className, ...props }, ref) => (
    <div
        {...props}
        ref={ref}
        className={cx(
            className,
            css`
        & > * {
          display: inline-block;
        }
        & > * + * {
          margin-left: 15px;
        }
      `
        )}
    />
));

const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
    <Menu
        {...props}
        ref={ref}
        className={cx(
            className,
            css`
        position: relative;
        padding: 1px 10px 9px;
        margin: 0 -20px;
        border-bottom: 2px solid #eee;
        margin-bottom: 20px;
        margin-top: -10px;
      `
        )}
    />
));