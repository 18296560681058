import React, {useEffect, useState} from "react";
import {get_ajax, post} from 'shared/dist/utils/DataProvider';
import {LayoutCard} from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {RoundButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import { CreateResourceDialog, EditResourceDialog, PreviewDialog } from 'shared/dist/components/generic-building-blocks/UserDialogs';
import {FaDatabase} from 'react-icons/fa';
import {IoIosConstruct} from 'react-icons/io';
import {IoAddCircle, IoCreate} from 'react-icons/io5';
import {VscSymbolMisc} from 'react-icons/vsc';
import {AiOutlineForm} from 'react-icons/ai';
import {FormGroup, FormGroupArea} from 'shared/dist/components/generic-building-blocks/FormGroup';
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {AiFillPlusCircle, AiFillRightCircle, AiFillCloseCircle, AiOutlineUnorderedList} from 'react-icons/ai';
import {BsPlusCircleFill, BsCheck2} from 'react-icons/bs';
import {BiText} from 'react-icons/bi';
import {HiDuplicate} from 'react-icons/hi';
import {MdPreview, MdModeEditOutline, MdOutlineDragHandle, MdOutlineSettingsSuggest, MdPendingActions, MdOutlineVisibilityOff} from 'react-icons/md';
import {
    configurableFieldType,
    configurableModelsList,
    defaultRuleDetails, ruleBlockTypes, ruleTypes,
    sideOptionsList,
    skipDefaultNames
} from "./constants";
import {FaWpforms} from 'react-icons/fa';
import {GoSettings} from 'react-icons/go';
import {FcCheckmark} from 'react-icons/fc';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';
import Autocomplete from 'shared/dist/components/generic-building-blocks/AutocompleteTextbox';
import {CustomForm} from 'shared/dist/components/custom_forms/CustomForm';
import {CimConfigDashboard} from "./CimConfigDashboard";

export const CimConfigForms = () => {
    return (
        <CimConfigDashboard
            selectedKey={'forms'}
        >
            <EditableFormConfigurator/>
        </CimConfigDashboard>
    );
}

const EditableFormConfigurator = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [existingForms, setExistingForms] = useState({});
    useEffect(()=>{
        loadData();
    }, []);
    
    const loadData = () => {
        get_ajax('CIMConfigurator/CustomFormConfigurator/GetForms', (data) => {
            setExistingForms(data);
            setIsLoading(false); 
        });
    }
    
    const [isAddNewFormDialogOpen, setIsAddNewFormDialogOpen] = useState(false);
    const [selectedFormsGroup, setSelectedFormsGroup] = useState(undefined);
    const [selectedFormId, setSelectedFormId] = useState(undefined);
    
    if(selectedFormsGroup === undefined)
    {
        return (
            <>
                <div className={'row'}>
                    {isLoading === false && (
                        <>
                            {Object.keys(existingForms).map((model, i) =>
                                <div className={'col-md-4'} key={i}>
                                    <LayoutCard
                                        onClick={()=>{setSelectedFormsGroup(model)}}
                                        className="layout_card layout_card_25bottom layout_card_padded shadow-sm cursor_pointer"
                                    >
                                        <FaWpforms size={32} className={"float-left"} style={{paddingRight: '10px', marginTop: '-5px'}}/>
                                        <h5>{model}</h5>
                                    </LayoutCard>
                                </div>
                            )}
                            <div className={'col-md-4'}>
                                <LayoutCard
                                    onClick={()=>{setIsAddNewFormDialogOpen(true)}}
                                    className="layout_card layout_card_25bottom layout_card_padded shadow-sm cursor_pointer center"
                                >
                                    <IoAddCircle size={48}/>
                                </LayoutCard>
                            </div>
                        </>
                    )}
                    {isLoading && (
                        <LoadingCircleComponent/>
                    )}
                    <AddNewFormDialog
                        isOpen={isAddNewFormDialogOpen}
                        toggle={()=>{
                            setIsAddNewFormDialogOpen(false);
                            loadData();
                        }}
                    />
                </div>
            </>
        );
    }
    else {
        if(selectedFormId === undefined)
        {
            return (
                <>
                    <LayoutCard
                        className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}
                    >
                        <div className={'row'}>
                            <div className={'col-md-1'}>
                                <span
                                    style={{cursor: 'pointer'}}
                                    className={"redirectNavigationLink"}
                                    onClick={()=>{setSelectedFormsGroup(undefined)}}
                                >Inapoi</span>
                            </div>
                            <div className={'col-md-5'}>
                                <h5 style={{textAlign: 'center'}}>{selectedFormsGroup}</h5>
                            </div>
                        </div>
                    </LayoutCard>
                    <div className={'row'}>
                        {existingForms[selectedFormsGroup].map((form, i) => 
                            <div className={'col-md-4'} key={i}>
                                <LayoutCard
                                    onClick={()=>{setSelectedFormId(form.id)}}
                                    className="layout_card layout_card_25bottom layout_card_padded shadow-sm cursor_pointer"
                                >
                                    <h5>{form.formName}</h5>
                                </LayoutCard>
                            </div>
                        )}
                    </div>
                </>
            );
        } else {
            return (
                <FormEditor
                    formId={selectedFormId}
                    formName={existingForms[selectedFormsGroup].filter(x => x.id == selectedFormId)[0].formName}
                    goBack={()=>{loadData(); setSelectedFormId(undefined);}}
                    modelType={selectedFormsGroup}
                />
            );
        }
    }
}

const AddNewFormDialog = ({isOpen, toggle}) => {
    const [formName, setFormName] = useState('');
    const [formModel, setFormModel] = useState('');
    const [modelOptions, setModelOptions] = useState([]); 
    useEffect(()=>{
        if(isOpen === false) return;
        get_ajax("CIMConfigurator/CustomModelConfigurator/GetExternalMappingOptions?editableOnly=true", (options) => {
            setModelOptions(options);
            if(options.length > 0)
            {
                setFormModel(options[0].value);
            }
        })
    }, [isOpen]);
    return (
        <CreateResourceDialog
            isOpen={isOpen}
            toggle={toggle}
            size={'md'}
            title={'Adauga forma'}
            onConfirm={()=>{
                post('CIMConfigurator/CustomFormConfigurator/AddCustomForm',
                    {
                        'modelType': formModel,
                        'customFormName': formName
                    }, (response) => {
                        if(response === true)
                        {
                            toggle();
                            return;
                        }
                        alert("Eroare la adaugare");
                    });
            }}
        >
            <div className={'form-group'}>
                <label>Obiect extern</label>
                <select className={'form-control'} value={formModel} onChange={(e)=>{setFormModel(e.target.value)}}>
                    {modelOptions.map(option =>
                        <option value={option.value}>{option.name}</option>
                    )}
                </select>
            </div>
            <FormGroup
                label={'Nume forma'}
                value={formName}
                onChange={(e) =>{ setFormName(e.target.value); }}
            />
        </CreateResourceDialog>
    );
}

let form_fields_old = undefined;
const FormEditor = ({formId, formName, modelType, goBack}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingRules, setIsLoadingRules] = useState(true);
    
    const [isAddNewFieldDialogOpen, setIsAddNewFieldDialogOpen] = useState(false);
    const [isRuleConfigureDialogOpen, setIsRuleConfigureDialog] = useState(false);
    
    useEffect(()=>{
        form_fields_old = undefined;
        loadData();
    }, [formId]);
    
    const loadData = () => {
        get_ajax(`CIMConfigurator/CustomFormConfigurator/GetFormDetails?formId=${formId}`, (data) => {
            try{
                const parsedData = JSON.parse(data);
                setFormFields(parsedData.fields ? parsedData.fields : []);   
            }
            catch (e){}
            finally {
                setIsLoading(false);
            }
        });
        get_ajax(`CIMConfigurator/CustomFormConfigurator/GetFormRules?formId=${formId}`, (data) => {
            try{
                const parsedData = JSON.parse(data);
                setFormRules(parsedData ? parsedData : {});
            }
            catch (e){}
            finally {
                setIsLoadingRules(false);
            }
        });
    }
    const saveData = () => {
        post('CIMConfigurator/CustomFormConfigurator/UpdateFormDetails', {
            formId: formId,
            formConfig: JSON.stringify({
                fields: formFields
            })
        }, (response) => {
            if(response === false) { alert("Eroare la salvare"); }
            loadData();
        });
    }
    
    const [formFields, setFormFields] = useState([]);
    const [formRules, setFormRules] = useState([]);
    
    const [editRuleDialogOpen, setEditRuleDialogOpen] = useState(undefined);
    const [editRuleDialogData, setEditRuleDialogData] = useState(undefined);
    
    useEffect(()=>{
        if(formFields === undefined || formFields.length === 0) return;
        if(form_fields_old === undefined)
        {
            form_fields_old = formFields;
        }
        else if(JSON.stringify(form_fields_old) === JSON.stringify(formFields))
        {
            return;
        }
        form_fields_old = formFields;
        saveData();
    }, [formFields]);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const pages_cpy = [...formFields];
        const [removed] = pages_cpy.splice(result.source.index, 1);
        pages_cpy.splice(result.destination.index, 0, removed);
        setFormFields(pages_cpy);
    }
    
    const [previewDialogOpen, setIsPreviewDialogOpen] = useState(false);
    const [editFieldDialogSelectedElemenet, setEditFieldDialogSelectedElement] = useState(undefined);
    
    const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);
    const [isFormEditModalVisible, setIsFormEditModalVisible] = useState(false);
    
    return (
        <>
            <LayoutCard className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}>
                <div className={'row'}>
                    <div className={'col-md-1'}>
                                <span
                                    style={{cursor: 'pointer'}}
                                    className={"redirectNavigationLink"}
                                    onClick={()=>{goBack();}}
                                >Inapoi</span>
                    </div>
                    <div className={'col-md-5'}>
                        <h5 style={{textAlign: 'center'}}>{formName}</h5>
                    </div>
                    <div className={'col-md-6'}>
                        <div className={'row'}>
                            <div className={'col-md-2'}>
                                <RoundButton
                                    className={"custom-btn-primary"}
                                    text={<MdModeEditOutline/>}
                                    onClickHandler={()=>{
                                        setIsFormEditModalVisible(true);
                                    }}
                                />
                            </div>
                            <div className={'col-md-2'}>
                                <RoundButton
                                    className={"custom-btn-primary"}
                                    text={<HiDuplicate/>}
                                    onClickHandler={()=>{
                                        setIsDuplicateModalVisible(true);
                                    }}
                                />
                            </div>
                            <div className={'col-md-2'}></div>
                            <div className={'col-md-2'}>
                                <RoundButton
                                    className={"custom-btn-primary"}
                                    text={<MdPreview/>}
                                    onClickHandler={()=>{
                                        setIsPreviewDialogOpen(true);
                                    }}
                                />
                            </div>
                            <div className={'col-md-2'}>
                                <RoundButton
                                    className={"custom-btn-primary"}
                                    text={<MdOutlineSettingsSuggest/>}
                                    onClickHandler={()=>{setIsRuleConfigureDialog(true);}}
                                />
                            </div>
                            <div className={'col-md-2'}>
                                <RoundButton
                                    className={"custom-btn-primary"}
                                    text={<BsPlusCircleFill/>}
                                    onClickHandler={() => { setIsAddNewFieldDialogOpen(true); }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutCard>
            <div className={'row'}>
                <div className={'col-md-7'}>
                    <LayoutCard
                        className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}
                    >
                        <h6>Campuri:</h6><br/>
                        {isLoading === true && (<LoadingCircleComponent/>)}
                        {!isLoading && (
                            <>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="page_tree_viewer">
                                        {(provided) => (
                                            <ul
                                                className="list-group"
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                                {formFields.map((field, i) =>
                                                    <Draggable draggableId={`page_tree_viewer_draggable_id_${i}`} key={i} index={i}>
                                                        {(provided) => (
                                                            <li className="list-group-item noselect" key={i}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                            >
                                                                <div className={'row'}>
                                                                    <div className={'col-md-5'}>
                                                                        <span
                                                                            className={"float-left"}
                                                                            style={{marginTop: '5px', marginRight: '10px', cursor: 'grab'}}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <MdOutlineDragHandle/>
                                                                        </span>
                                                                        <div style={{paddingTop: '5px'}}>{field.name}</div>
                                                                    </div>
                                                                    <div className={'col-md-5'}>
                                                                        <small style={{marginTop: '9px'}} className={'float-right'}>{field.id}</small>
                                                                    </div>
                                                                    <div className={'col-md-2'}>
                                                                        <RoundButton
                                                                            className={"custom-btn-primary"}
                                                                            text={<GoSettings/>}
                                                                            onClickHandler={(e)=>{
                                                                                e.preventDefault();
                                                                                setEditFieldDialogSelectedElement(i);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </Draggable>
                                                )}
                                                {provided.placeholder}
                                            </ul>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                {formFields.length === 0 && (
                                    <p style={{textAlign: 'center'}}>Nu exista campuri in aceasta forma</p>
                                )}  
                            </>
                        )}
                    </LayoutCard>
                </div>
                <div className={'col-md-5'}>
                    <LayoutCard
                        className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}
                    >
                        <h6>Reguli:</h6><br/>
                        {isLoadingRules === true && (<LoadingCircleComponent/>)}
                        {!isLoadingRules && (
                            <>
                                {Object.keys(formRules).length === 0 && (
                                    <p style={{textAlign: 'center'}}>Nu exista reguli adaugate</p>
                                )}
                                {Object.keys(formRules).length !== 0 && (
                                    <ul className="list-group">
                                        {Object.keys(formRules).map((ruleId, i) =>
                                            <li key={ruleId} className="list-group-item">
                                                <div className={'row'}>
                                                    <div className={'col-md-8'}>
                                                        <b>Regula {(i+1)}</b>
                                                    </div>
                                                    <div className={'col-md-4'}>
                                                        <div className='toggle_box'>
                                                            <div className='row'>
                                                                <div className='col-md-6' style={{paddingRight: '0'}}>
                                                                    <div className='anim non_active' style={{cursor: 'pointer'}} onClick={()=>{
                                                                        setEditRuleDialogData(formRules[ruleId]);
                                                                        setEditRuleDialogOpen(ruleId);
                                                                    }}>
                                                                        <GoSettings/>
                                                                    </div>
                                                                </div>
                                                                <div className={'col-md-6'} style={{paddingLeft: '0'}}>
                                                                    <div className='anim danger' style={{cursor: 'pointer'}} onClick={()=>{
                                                                        //    TODO Add confirmation before removal
                                                                        post('CIMConfigurator/CustomFormConfigurator/RemoveRuleFromForm', {
                                                                            formId: formId,
                                                                            ruleId: ruleId
                                                                        }, (response) => {
                                                                            if(response === false) { alert("Eroare la salvare"); }
                                                                            loadData();
                                                                        });
                                                                    }}>
                                                                        <AiFillCloseCircle/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                )}
                            </>  
                        )}
                    </LayoutCard>
                </div>
            </div>
            <AddNewFieldOnFormDialog
                type={modelType}
                isOpen={isAddNewFieldDialogOpen}
                toggle={()=>{setIsAddNewFieldDialogOpen(false);}}
                onSelected={(newField)=>{
                    const _fields = [...formFields];
                    _fields.push(newField);
                    setFormFields(_fields);
                    setIsAddNewFieldDialogOpen(false);
                }}
            />
            {isRuleConfigureDialogOpen && (
                <RuleConfigureDialog
                    type={modelType}
                    formId={formId}
                    isOpen={isRuleConfigureDialogOpen}
                    toggle={() => {setIsRuleConfigureDialog(false); loadData();}}
                    existingData={undefined}
                />  
            )}
            {editRuleDialogOpen && (
                <RuleConfigureDialog
                    type={modelType}
                    formId={formId}
                    isOpen={editRuleDialogOpen !== undefined}
                    toggle={() => {setEditRuleDialogOpen(undefined); loadData();}}
                    existingData={editRuleDialogData}
                    ruleId = {editRuleDialogOpen}
                />
            )}
            <PreviewDialog
                isOpen={previewDialogOpen}
                toggle={()=>{setIsPreviewDialogOpen(false)}}
                size={'lg'}
                title={"Previzualizeaza forma"}
            >
                <CustomForm 
                    formId={formId}
                    // TODO Have these only for employee... they should not be hard coded like this, but set somehow
                    defaultValues={{"@tara":"Romania", "@judet":"Bacau"}}
                />
            </PreviewDialog>
            <FormFieldDialog
                isOpen={editFieldDialogSelectedElemenet !== undefined}
                deleteField={()=>{
                    const _fields = [...formFields];
                    _fields.splice(editFieldDialogSelectedElemenet, 1);
                    setFormFields(_fields);
                }}
                toggle={()=>{ setEditFieldDialogSelectedElement(undefined); }}
                field={editFieldDialogSelectedElemenet !== undefined ? formFields[editFieldDialogSelectedElemenet] : undefined}
                updateField={(field) => {
                    const _fields = [...formFields];
                    _fields[editFieldDialogSelectedElemenet] = field;
                    setFormFields(_fields);
                }}
            />
            <DuplicateFormDialog
                selectedFormId={formId}
                isOpen={isDuplicateModalVisible}
                toggle={()=>{setIsDuplicateModalVisible(false);}}
                goBack={()=>{goBack();}}
            />
            <EditFormMetadataDetails
                formId={formId}
                formName={formName}
                isOpen={isFormEditModalVisible}
                toggle={()=>{setIsFormEditModalVisible(false);}}
                editSomething={()=>{
                    setIsFormEditModalVisible(false);
                    goBack();
                }}
            />
        </>
    );
}

const AddNewFieldOnFormDialog = ({isOpen, toggle, type, onSelected, skipExternals = false}) => {
    const [fields, setFields] = useState(undefined);
    const [externalReferenceFields, setExternalReferenceFields] = useState(undefined);
    
    useEffect(() => {
        if(type === undefined) return;
        get_ajax(`CIMConfigurator/CustomModelConfigurator/RetrieveFields?type=${type}`, (data) => {
            if(data === undefined) return;
            setFields(data.fields);
        }).catch((e) => {
            setFields([]);
        });

        if(skipExternals === false)
        {
            get_ajax(`CIMConfigurator/CustomFormConfigurator/RetrieveExternalFields?type=${type}`, (data) => {
                if(data === undefined) return;
                setExternalReferenceFields(data);
            }).catch((e) => {
                setFields([]);
            });
        }
        else {}
        setExternalReferenceFields([]);
    }, [type]);
    
    useEffect(() => {
        setSelectedIndex(-1);
    }, [isOpen]);
    
    const [selectedInternal, setSelectedInternal] = useState(undefined);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    
    return (
        <>
            <CreateResourceDialog
                isOpen={isOpen}
                toggle={toggle}
                size={'md'}
                title={'Adauga camp'}
                onConfirm={()=>{
                    if(selectedIndex === -1)
                    {
                        alert("Alegeti un camp"); return
                    }
                    if(selectedInternal)
                    {
                        onSelected(fields[selectedIndex]);
                    }
                    else{
                        onSelected(
                            {
                                "id": `@${externalReferenceFields[selectedIndex]}`,
                                "name": externalReferenceFields[selectedIndex],
                                "customFieldDetails": "{}",
                                "type": 7
                            });
                    }
                }}
            >
                {(fields === undefined || externalReferenceFields === undefined) && (<LoadingCircleComponent/>)}
                {(fields !== undefined && externalReferenceFields !== undefined) && (
                    <>
                        <div style={{maxHeight: '60vh', overflow: 'scroll'}}>
                            <p>Campuri interne</p>
                            <ul className="list-group">
                                {fields.map((field, i) =>
                                    <li 
                                        style={{cursor: 'pointer'}}
                                        key={i}
                                        className={(selectedIndex === i && selectedInternal) ? "list-group-item list-group-item-active noselect" : "list-group-item noselect"}
                                        onClick={()=>{
                                            if(selectedIndex === i)
                                            {
                                                setSelectedIndex(-1);
                                                return;
                                            }
                                            setSelectedIndex(i);
                                            setSelectedInternal(true);
                                        }}
                                    >
                                        {field.name} 
                                        <small className={'float-right'}>{field.id}</small>
                                    </li>
                                )}
                            </ul>
                            <br/>
                            {externalReferenceFields.length > 0 && (
                                <>
                                    <p>Referinte externe</p>
                                    <ul className="list-group">
                                        {externalReferenceFields.map((field, i) =>
                                            <li
                                                style={{cursor: 'pointer'}}
                                                key={i}
                                                className={(selectedIndex === i && !selectedInternal) ? "list-group-item list-group-item-active noselect" : "list-group-item noselect"}
                                                onClick={()=>{
                                                    if(selectedIndex === i)
                                                    {
                                                        setSelectedIndex(-1);
                                                        return;
                                                    }
                                                    setSelectedIndex(i);
                                                    setSelectedInternal(false);
                                                }}
                                            >
                                                {field}
                                            </li>
                                        )}
                                    </ul>
                                </>
                            )}
                        </div>
                    </>
                )}
            </CreateResourceDialog>
        </>
    );
}

const RuleConfigureDialog = ({isOpen, toggle, formId, existingData, ruleId, type}) => {
    const [selectedRuleIndex, setSelectedRuleIndex] = useState(0);
    const [ruleBlocks, setRuleBlocks] = useState(defaultRuleDetails);
    
    useEffect(()=>{
        if(existingData === undefined) 
            return;
        setRuleBlocks(JSON.parse(existingData));
    }, [existingData]);
    
    useEffect(()=>{
        value_buffer = [];
        setSelectedRuleIndex(0);
        if(existingData === undefined)
        {
            setRuleBlocks(defaultRuleDetails);
        }
    }, [isOpen]);
    
    const [internalPageLoadingTimeout, setInternalPageLoadingTimeout] = useState(true);
    useEffect(()=>{
        setInternalPageLoadingTimeout(true);
        setTimeout(()=>{
            setInternalPageLoadingTimeout(false);
        }, 100);
    }, [selectedRuleIndex]);
    
    const renderRuleBlockListView = (element) => {
        if(element.type === ruleBlockTypes.ruleType)
        {
            return (
                <>
                    Tip
                    {element.value === ruleTypes.undefined && (<MdPendingActions className={"float-right"}/>)}
                    {element.value !== ruleTypes.undefined && (<BsCheck2 className={"float-right"}/>)}
                </>
            );
        } else if(element.type === ruleBlockTypes.condition) {
            return (
                <>
                    Conditie
                    {element.value === undefined && (<MdPendingActions className={"float-right"}/>)}
                    {element.value !== undefined && (<BsCheck2 className={"float-right"}/>)}
                </>
            );
        } else if(element.type === ruleBlockTypes.setValue) {
            return (
                <>
                    {ruleBlocks[0].value === ruleTypes.value && ('Valoare')}
                    {ruleBlocks[0].value === ruleTypes.visibility && ('Vizibilitate')}
                    {ruleBlocks[0].value === ruleTypes.listOptions && ('Optiuni')}
                    {element.value === undefined && (<MdPendingActions className={"float-right"}/>)}
                    {element.value !== undefined && (<BsCheck2 className={"float-right"}/>)}
                </>
            );
        }
        return (<></>);
    }
    
    const updateRuleBlockValue = (index, newValue) => {
        const rules = [...ruleBlocks];
        rules[index].value = newValue;
        setRuleBlocks(rules);
    }

    useEffect(()=>{
        get_ajax(`CIMConfigurator/CustomFormConfigurator/GetAvailableCustomMethods?modelType=${type}&includeMethods=false`, (data) => {
            setAllowedFields(data);
        });
    }, [type]);
    const [allowedFields, setAllowedFields] = useState([]);
    
    return (
        <>
            <CreateResourceDialog
                isOpen={isOpen}
                toggle={toggle}
                size={'xl'}
                title={'Configureaza regula'}
                onConfirm={()=>{
                    let isOk = true;
                    for(let i = 0; i < ruleBlocks.length; i++)
                    {
                        let element = ruleBlocks[i];
                        if(element.type === ruleBlockTypes.ruleType)
                        {
                            if(element.value === ruleTypes.undefined)
                                isOk = false;
                        } else if(element.type === ruleBlockTypes.condition) {
                            if(element.value === undefined)
                                isOk = false;
                        } else if(element.type === ruleBlockTypes.setValue) {
                            if (element.value === undefined)
                                isOk = false;
                            else {
                                if(ruleBlocks[0].value === ruleTypes.visibility)
                                {
                                    if(!allowedFields.includes(element.value['field']))
                                    {
                                        isOk = false;
                                    }
                                }
                                else if(ruleBlocks[0].value === ruleTypes.value)
                                {
                                    if(!allowedFields.includes(element.value['field']))
                                    {
                                        isOk = false;
                                    }
                                }
                                // compelete here for list option rules
                            }
                        }
                    }
                    if(isOk === false)
                    {
                        alert("Finalizati configurarea inainte de salvare");
                        return;
                    }

                    post('CIMConfigurator/CustomFormConfigurator/CreateOrUpdateFormRule', {
                        formId: formId,
                        jsonPayload: JSON.stringify(ruleBlocks),
                        ruleId: ruleId ? ruleId : "new_rule"
                    }, (response) => {
                        if(response === false) { alert("Eroare la salvare"); }
                        toggle();
                    });
                }}
            >   
                <div className={'row'}>
                    <div className={'col-md-9'}>
                        {internalPageLoadingTimeout === false && (
                            <>
                                {ruleBlocks[selectedRuleIndex].type === ruleBlockTypes.ruleType && (
                                    <>
                                        <p>Ce va modifica aceasta regula?</p>
                                        <br/>
                                        <div className={'row'}>
                                            <div className={'col-md-4'} style={{textAlign: 'center'}}>
                                                <RoundButton
                                                    className={ruleBlocks[selectedRuleIndex].value === ruleTypes.value ? "custom-btn-primary" : "btn-secondary"}
                                                    text={<BiText/>}
                                                    onClickHandler={() => {updateRuleBlockValue(selectedRuleIndex, ruleTypes.value)}}
                                                />
                                                <small>Valoarea unui camp</small>
                                                <br/><br/>
                                                <label>Permite modificare ulterioara?</label>
                                                <input type={"checkbox"} checked={ruleBlocks[selectedRuleIndex].canAlter} onChange={e => {
                                                    const rules = [...ruleBlocks];
                                                    rules[selectedRuleIndex].canAlter = e.target.checked;
                                                    setRuleBlocks(rules);
                                                }}/>
                                            </div>
                                            <div className={'col-md-4'} style={{textAlign: 'center'}}>
                                                <RoundButton
                                                    className={ruleBlocks[selectedRuleIndex].value === ruleTypes.visibility ? "custom-btn-primary" : "btn-secondary"}
                                                    text={<MdOutlineVisibilityOff/>}
                                                    onClickHandler={() => {updateRuleBlockValue(selectedRuleIndex, ruleTypes.visibility)}}
                                                />
                                                <small>Vizibilitatea unui camp</small>
                                            </div>
                                            <div className={'col-md-4'} style={{textAlign: 'center'}}>
                                                <RoundButton
                                                    className={ruleBlocks[selectedRuleIndex].value === ruleTypes.listOptions ? "custom-btn-primary" : "btn-secondary"}
                                                    text={<AiOutlineUnorderedList/>}
                                                    onClickHandler={() => {updateRuleBlockValue(selectedRuleIndex, ruleTypes.listOptions)}}
                                                />
                                                <small>Optiunile unui camp llista</small>
                                            </div>
                                        </div>
                                        <br/>
                                    </>
                                )}
                                {ruleBlocks[selectedRuleIndex].type === ruleBlockTypes.condition && (
                                    <>
                                        <p>Adauga conditia pentru regula:</p>
                                        <br/>
                                        <RuleConditionEditor
                                            externalValue={ruleBlocks[selectedRuleIndex].value}
                                            setExternalValue={(value) => { updateRuleBlockValue(selectedRuleIndex, value); }}
                                            modelType={type}
                                        />
                                    </>
                                )}
                                {ruleBlocks[selectedRuleIndex].type === ruleBlockTypes.setValue && (
                                    <>
                                        {ruleBlocks[0].value === ruleTypes.visibility && (
                                            <RuleConfigurationVisibleDetails
                                                externalValue={ruleBlocks[selectedRuleIndex].value}
                                                setExternalValue={(value) => { updateRuleBlockValue(selectedRuleIndex, value); }}
                                                modelType={type}
                                            />
                                        )}
                                        {ruleBlocks[0].value === ruleTypes.value && (
                                            <RuleConfigurationValueDetails
                                                externalValue={ruleBlocks[selectedRuleIndex].value}
                                                setExternalValue={(value) => { updateRuleBlockValue(selectedRuleIndex, value); }}
                                                modelType={type}
                                            />
                                        )}
                                        {ruleBlocks[0].value === ruleTypes.listOptions && (
                                            <RuleConfigurationOptionsDetails
                                                externalValue={ruleBlocks[selectedRuleIndex].value}
                                                setExternalValue={(value) => { updateRuleBlockValue(selectedRuleIndex, value); }}
                                                modelType={type}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {internalPageLoadingTimeout && (<LoadingCircleComponent/>)}
                    </div>
                    <div className={'col-md-3'}>
                        <ul className="list-group">
                            {ruleBlocks.map((block, i) => 
                                <li 
                                    key={i}
                                    className={selectedRuleIndex !== i ? "list-group-item" : (i == 0) ? "list-group-item list-group-item-active list-group-item-no-round-bottom" : "list-group-item list-group-item-active list-group-item-no-round-bottom list-group-item-no-round-top"}
                                    style={{cursor: 'pointer'}}
                                    onClick={()=>{setSelectedRuleIndex(i)}}
                                >
                                    {renderRuleBlockListView(block)}
                                </li>
                            )}
                            <li className="list-group-item"></li>
                            <li
                                className={ruleBlocks[0].value !== ruleTypes.undefined && ruleBlocks[1].value !== undefined ?
                                    "list-group-item list-group-item-active list-group-item-no-round-top" : "list-group-item list-group-item-no-round-top disabled"}
                                style={{textAlign: 'center', cursor: 'pointer'}}
                                onClick={()=>{
                                    if(ruleBlocks[0].value === ruleTypes.undefined || ruleBlocks[1].value === undefined)
                                    {
                                        return;
                                    }
                                    const rules = [...ruleBlocks];
                                    rules.push({type: ruleBlockTypes.setValue, value: undefined});
                                    setRuleBlocks(rules);
                                }}
                            >Adauga</li>
                        </ul>
                    </div>
                </div>
            </CreateResourceDialog>
        </>
    );
}

const EditFormMetadataDetails = ({isOpen, toggle, formId, formName, editSomething}) => {
    const [formNameValue, setFormNameValue] = useState(formName);
    return (
        <>
            <EditResourceDialog
                isOpen={isOpen}
                toggle={toggle}
                size={'md'}
                title={'Multiplica forma'}
                onConfirm={()=>{
                    post('CIMConfigurator/CustomFormConfigurator/RenameForm', {
                        formId: formId,
                        formName: formNameValue
                    }, (response)=>{
                        if(response === true)
                        {
                            editSomething();
                        }
                        else {
                            toggle();
                        }
                    });
                }}
                onDelete={()=>{
                    post('CIMConfigurator/CustomFormConfigurator/DeleteForm', {
                        formId: formId,
                    }, (response)=>{
                        if(response === true)
                        {
                            editSomething();
                        }
                        else {
                            toggle();
                        }
                    });
                }}
            >
                <FormGroup
                    label={'Nume forma'}
                    value={formNameValue}
                    onChange={(e) =>{ setFormNameValue(e.target.value); }}
                />
            </EditResourceDialog>
        </>
    );
}

const DuplicateFormDialog = ({isOpen, toggle, selectedFormId, goBack}) => {
    const [duplicatedFormName, setDuplicatedFormName] = useState(undefined);
    return (
        <>
            <CreateResourceDialog
                isOpen={isOpen}
                toggle={toggle}
                size={'sm'}
                title={'Multiplica forma'}
                saveBtnOverwride={"Adauga"}
                onConfirm={()=>{
                    post('CIMConfigurator/CustomFormConfigurator/DuplicateCustomForm', {
                        originalFormId: selectedFormId,
                        newFormName: duplicatedFormName
                    }, (response)=>{
                        if(response === true)
                        {
                            toggle();
                            goBack();
                        }
                        else {
                            alert("Eroare la duplicare");
                            toggle();
                        }
                    });
                }}
            >
                <FormGroup
                    label={'Nume forma noua'}
                    value={duplicatedFormName}
                    onChange={(e) =>{ setDuplicatedFormName(e.target.value); }}
                />
            </CreateResourceDialog>
        </>
    );
}

let value_buffer = [];
export const RuleConditionEditor = ({externalValue, setExternalValue, modelType}) => {
    const [value, setValue] = useState(externalValue);
    useEffect(()=>{
        if(value === undefined)
        {
            return;
        }
        value_buffer = value;
        setExternalValue(value);
    }, [value]);
    
    const updateAutocompleteValue = (index, newValue, isFirst) => {
        const _values = [...value_buffer];
        if(isFirst)
            _values[index].value1 = newValue;
        else 
            _values[index].value2 = newValue;
        setExternalValue(_values);
    }

    useEffect(()=>{
        get_ajax(`CIMConfigurator/CustomFormConfigurator/GetAvailableCustomMethods?modelType=${modelType}`, (data) => {
            setAutocompleteSuggestions(data);
        });
    }, []);
    const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);
    
    return (
        <>
            <ul className="list-group">
                {!(value === undefined || value === null || value.length === 0) && (
                    <>
                        {value.map((record, i) =>
                            <li className="list-group-item" key={i}>
                                <div className={'row'}>
                                    <div className={'col-md-9'}>
                                        <div className={'row'}>
                                            <div className={'col-md-5'}>
                                                <Autocomplete
                                                    placeholder={'Valoare 1'}
                                                    suggestions={autocompleteSuggestions}
                                                    value={record.value1}
                                                    onChange={(e) =>{
                                                        updateAutocompleteValue(i, e, true);
                                                    }}
                                                />
                                            </div>
                                            <div className={'col-md-2'}>
                                                <select 
                                                    className={'form-control'}
                                                    value={record.operator}
                                                    onChange={(e) => {
                                                        const _values = [...value];
                                                        _values[i].operator = e.target.value;
                                                        setValue(_values);
                                                    }}
                                                >
                                                    <option value={'='}>=</option>
                                                    <option value={'≠'}>≠</option>
                                                    <option value={'>'}>&gt;</option>
                                                    <option value={'>='}>&ge;</option>
                                                    <option value={'<'}>&lt;</option>
                                                    <option value={'<='}>&le;</option>
                                                </select>
                                            </div>
                                            <div className={'col-md-5'}>
                                                <Autocomplete
                                                    placeholder={'Valoare 2'}
                                                    suggestions={autocompleteSuggestions}
                                                    value={record.value2}
                                                    onChange={(e) =>{
                                                        updateAutocompleteValue(i, e, false);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'col-md-3'}>
                                        <div className='toggle_box'>
                                            <div className='row'>
                                                {i !== 0 && (
                                                    <div className='col-md-6' style={{paddingRight: '0'}}>
                                                        <div className='anim non_active' style={{cursor: 'pointer'}} onClick={()=>{
                                                            const _values = [...value];
                                                            _values[i].isOr = !_values[i].isOr;
                                                            setValue(_values);
                                                        }}>
                                                            {record.isOr && (<IoIosArrowDown/>)}
                                                            {!record.isOr && (<IoIosArrowUp/>)}
                                                        </div>
                                                    </div>
                                                )}
                                                {i === 0 && (
                                                    <div className={i !== 0 ? 'col-md-4': 'col-md-6'}
                                                         style={i !== 0 ? {paddingLeft: '0', paddingRight: '0'} :
                                                             {paddingRight: '0'}}>
                                                        <div className='anim active activeSQ' style={{cursor: 'pointer'}} onClick={()=>{
                                                            const _value = [...value_buffer];
                                                            _value.splice(i+1, 0, {value1: '', value2: '', operator: '=', isOr: false});
                                                            setValue(_value);
                                                        }}>
                                                            <AiFillPlusCircle/>
                                                        </div>
                                                    </div>  
                                                )}
                                                <div className={'col-md-6'} style={{paddingLeft: '0'}}>
                                                    <div className='anim danger' style={{cursor: 'pointer'}} onClick={()=>{
                                                        const _value = [...value_buffer];
                                                        _value.splice(i, 1);
                                                        setValue(_value);
                                                    }}>
                                                        <AiFillCloseCircle/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )}  
                    </>
                )}
            </ul>
            <br/>
            {(value === undefined || value === null || value.length === 0) && (
                <div className={'row'}>
                    <div className={'col-md-8'}></div>
                    <div className={'col-md-4'}>
                        <RoundButton
                            className={"custom-btn-primary"}
                            text={"Adauga regula"}
                            onClickHandler={()=>{setValue([{
                                value1: '',
                                value2: '',
                                operator: '=',
                                isOr: false
                            }]);}}
                        />
                    </div>
                </div>  
            )}
        </>
    );
}

const RuleConfigurationVisibleDetails = ({externalValue, setExternalValue, modelType}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [fieldId, setFieldId] = useState('');
    const [visibility, setVisibility] = useState('');
    useEffect(()=>{
        if(externalValue === undefined) {
            setIsLoading(false);
            return;
        }
        
        setFieldId(externalValue['field']);
        setVisibility(externalValue['visibility'] || 'visible');

        setIsLoading(false);
    }, [externalValue]);
    
    useEffect(()=>{
        if(fieldId === '' && visibility === '') return;
        setExternalValue({'field': fieldId, 'visibility': visibility});
    }, [fieldId, visibility]);

    useEffect(()=>{
        get_ajax(`CIMConfigurator/CustomFormConfigurator/GetAvailableCustomMethods?modelType=${modelType}&includeMethods=false`, (data) => {
            setAutocompleteSuggestions(data);
        });
    }, []);
    const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);
    
    if(isLoading)
        return (<></>);
    return (
        <div className={'row'}>
            <div className={'col-md-6'}>
                <Autocomplete
                    placeholder={'Id camp'}
                    suggestions={autocompleteSuggestions}
                    value={fieldId}
                    onChange={(e) =>{
                        setFieldId(e);
                    }}
                />
            </div>
            <div className={'col-md-6'}>
                <select className={'form-control'} value={visibility} onChange={(e) => {setVisibility(e.target.value)}}>
                    <option value={"visible"}>Vizibil</option>
                    <option value={"hidden"}>Ascuns</option>
                </select>
            </div>
        </div>
    );
}

const RuleConfigurationValueDetails = ({externalValue, setExternalValue, modelType}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [fieldId, setFieldId] = useState('');
    const [value, setValue] = useState('');
    useEffect(()=>{
        if(externalValue === undefined) {
            setIsLoading(false);
            return;
        }
        setFieldId(externalValue['field']);
        setValue(externalValue['value']);
        setIsLoading(false);
    }, [externalValue]);

    useEffect(()=>{
        if(fieldId === '' && value === '') return;
        setExternalValue({'field': fieldId, 'value': value});
    }, [fieldId, value]);

    useEffect(()=>{
        get_ajax(`CIMConfigurator/CustomFormConfigurator/GetAvailableCustomMethods?modelType=${modelType}&includeMethods=false`, (data) => {
            setAutocompleteSuggestions(data);
        });
    }, []);
    const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);

    useEffect(()=>{
        get_ajax(`CIMConfigurator/CustomFormConfigurator/GetAvailableCustomMethods?modelType=${modelType}&includeMethods=true`, (data) => {
            setAutocompleteSuggestionsFunctions(data);
        });
    }, []);
    const [autocompleteSuggestionsFunctions, setAutocompleteSuggestionsFunctions] = useState([]);
    
    if(isLoading)
        return (<></>);
    return (
        <div className={'row'}>
            <div className={'col-md-5'}>
                <Autocomplete
                    placeholder={'Id camp'}
                    suggestions={autocompleteSuggestions}
                    value={fieldId}
                    onChange={(e) =>{
                        setFieldId(e);
                    }}
                />
            </div>
            <div className={'col-md-2'}>
                <div style={{textAlign: 'center'}}>=</div>
            </div>
            <div className={'col-md-5'}>
                <Autocomplete
                    placeholder={'Valoare'}
                    suggestions={autocompleteSuggestionsFunctions}
                    value={value}
                    onChange={(e) =>{
                        setValue(e);
                    }}
                />
            </div>
        </div>
    );
}

const RuleConfigurationOptionsDetails = ({externalValue, setExternalValue, modelType}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [fieldId, setFieldId] = useState('');
    const [value, setValue] = useState('');
    useEffect(()=>{
        if(externalValue === undefined) {
            setIsLoading(false);
            return;
        }
        setFieldId(externalValue['field']);
        setValue(externalValue['value']);
        setIsLoading(false);
    }, [externalValue]);

    useEffect(()=>{
        if(fieldId === '' && value === '') return;
        setExternalValue({'field': fieldId, 'value': value});
    }, [fieldId, value]);

    useEffect(()=>{
        get_ajax(`CIMConfigurator/CustomFormConfigurator/GetAvailableCustomMethods?modelType=${modelType}&includeMethods=false`, (data) => {
            setAutocompleteSuggestions(data);
        });
    }, []);
    const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);

    if(isLoading)
        return (<></>);
    return (
        <div className={'row'}>
            <div className={'col-md-5'}>
                <Autocomplete
                    placeholder={'Id camp'}
                    suggestions={autocompleteSuggestions}
                    value={fieldId}
                    onChange={(e) =>{
                        setFieldId(e);
                    }}
                />
            </div>
            <div className={'col-md-2'}>
                <div style={{textAlign: 'center'}}>∈</div>
            </div>
            <div className={'col-md-5'}>
                <textarea
                    placeholder={'Optiuni lista'}
                    className={'form-control'}
                    value={value}
                    rows={10}
                    onChange={(e) =>{
                        setValue(e.target.value);
                    }}
                >
                    
                </textarea>
                <small>Adaugati elementele pe cate un rand, cu ; la final</small>
            </div>
        </div>
    );
}

const FormFieldDialog = ({isOpen, toggle, deleteField, field, updateField}) => {
    useEffect(()=>{
        if(isOpen === false) return;
        if(field === undefined) return;
        setFieldType(field.type);
    }, [isOpen]);
    
    const [fieldType, setFieldType] = useState(-1);
    const [updatedDict, setUpdatesDict] = useState({});
    
    return (
        <>
            <EditResourceDialog
                isOpen={isOpen}
                toggle={toggle}
                size={'xl'}
                title={'Configureaza camp'}
                onConfirm={()=>{
                    Object.keys(updatedDict).map((key) => {
                        field[key] = updatedDict[key]; 
                    });
                    updateField(field);
                    toggle();
                }}
                onDelete={()=>{
                    deleteField();
                    toggle();
                }}
            >
                {field !== undefined && (
                    <>
                        {fieldType === 6 && (
                            <>
                                <FormFieldDialogCustomModelReference
                                    fieldDetails={field}
                                    setFieldDetails={(key, value) => {
                                        console.log(`Setting ${key} to ${value}`);
                                        const _updates = {...updatedDict};
                                        _updates[key] = value;
                                        field[key] = value;
                                        updateField(field);
                                        setUpdatesDict(_updates);
                                    }}
                                    fieldId={field['id']}
                                />
                            </>
                        )}
                        {fieldType === 7 && (
                            <FormFieldDialogCustomModelExternalReferencePage
                                fieldDetails={field}
                                setFieldDetails={(key, value) => {
                                    console.log(`Setting ${key} to ${value}`);
                                    const _updates = {...updatedDict};
                                    _updates[key] = value;
                                    field[key] = value;
                                    updateField(field);
                                    setUpdatesDict(_updates);
                                }}
                                fieldId={field['id']}
                            />
                        )}
                    </>
                )}
                
                <br/>
                <p>{JSON.stringify(field)}</p>
            </EditResourceDialog>
        </>
    )
}

const FormFieldDialogCustomModelReference = ({fieldId, fieldDetails, setFieldDetails}) => {
    return (
        <>
            <div className={'row'}>
                <div className={'col-md-6'}>
                    <div className={'form-group'}>
                        <label>Comportament:</label>
                        <select
                            className={'form-control'}
                            value={fieldDetails['behaviour']}
                            onChange={(e) => setFieldDetails('behaviour', e.target.value)}
                        >
                            <option value={'not-set'}>-</option>
                            <option value={'pick-existent'}>Alege existent</option>
                            <option disabled={true} value={'add-new'}>Adauga nou</option>
                        </select>
                    </div>
                </div>
            </div>
        </>
    )
}

const FormFieldDialogCustomModelExternalReferencePage = ({fieldId, fieldDetails, setFieldDetails}) => {
    useEffect(()=>{
        get_ajax('CIMConfigurator/CustomFormConfigurator/GetForms', (data) => {
            const _fieldId = fieldId.substring(1);
            setExternalFormsOptions(data[_fieldId]);
        });
    }, [fieldId]);
    
    const [externalFormsOptions, setExternalFormsOptions] = useState([]);
    
    return (
        <>
            <div className={'row'}>
                <div className={'col-md-6'}>
                    <div className={'form-group'}>
                        <label>Comportament:</label>
                        <select 
                            className={'form-control'} 
                            value={fieldDetails['behaviour']}
                            onChange={(e) => setFieldDetails('behaviour', e.target.value)}
                        >
                            <option value={'not-set'}>-</option>
                            <option disabled={true}>Alege existent</option>
                            <option value={'add-new'}>Adauga nou</option>
                        </select>
                    </div>
                    <div className={'form-group'}>
                        <label>Relatie:</label>
                        <select
                            className={'form-control'}
                            value={fieldDetails['relation']}
                            onChange={(e) => setFieldDetails('relation', e.target.value)}
                        >
                            <option value={'not-set'}>-</option>
                            <option value={'1-to-1'}>1 to 1</option>
                            <option value={'1-to-many'}>1 to many</option>
                        </select>
                    </div>
                    <div className={'form-group'}>
                        <label>Afisare actiune:</label>
                        <select
                            className={'form-control'}
                            value={fieldDetails['actionViewType']}
                            onChange={(e) => setFieldDetails('actionViewType', e.target.value)}
                        >
                            <option value={'not-set'}>-</option>
                            <option value={'new-dialog'}>Dialog nou</option>
                            <option disabled={true}>Integrat</option>
                        </select>
                    </div>
                    <div className={'form-group'}>
                        <label>Alege forma:</label>
                        <select
                            className={'form-control'}
                            value={fieldDetails['form']}
                            onChange={(e) => setFieldDetails('form', e.target.value)}
                        >
                            <option value={'not-set'}>-</option>
                            {externalFormsOptions.map((form) =>
                                <option key={form.id} value={form.id}>{form.formName}</option>
                            )}
                        </select>
                    </div>
                    <div className={'form-group'}>
                        <label>Tip previzualizare:</label>
                        <select
                            className={'form-control'}
                            value={fieldDetails['previewType']}
                            onChange={(e) => setFieldDetails('previewType', e.target.value)}
                        >
                            <option value={'not-set'}>-</option>
                            <option value={'table-view'}>Tabelara</option>
                            <option disabled={true}>Forma</option>
                        </select>
                        {(fieldDetails['previewType'] && fieldDetails['previewType'] !== 'not-set') && (<br/>)}
                        {fieldDetails['previewType'] === 'table-view' && (
                            <>
                                <label>Alege coloane tabel:</label>
                                <TableFieldsConfigurator
                                    selectedColumns={fieldDetails['tablePreviewSelectedColumns']}
                                    updateSelectedColumns={(newColumns)=>{ setFieldDetails('tablePreviewSelectedColumns', newColumns) }}
                                    modelType={fieldId.substring(1)}
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className={'col-md-6'}>
                    {(fieldDetails['form'] && fieldDetails['form'] !== 'not-set') && (
                        <>
                            <strong><p>Previzualizare forma:</p></strong>
                            <CustomForm 
                                formId={fieldDetails['form']}
                                defaultValues={{}}
                                disableAll={true}
                            />
                        </> 
                    )}
                </div>
            </div>
        </>
    );
}

export const TableFieldsConfigurator = ({selectedColumns, updateSelectedColumns, modelType}) => {
    const [isAddNewFieldDialogOpen, setIsAddNewFieldDialogOpen] = useState(false);
    return (
        <>
            {(selectedColumns && selectedColumns.length > 0) && (
                <>
                    <ul className="list-group">
                        {selectedColumns.map((field, i) =>
                            <li className={'list-group-item'} key={i}>
                                <div className={'row'}>
                                    <div className={'col-md-10'}>
                                        {field.name}
                                        <small className={'float-right'}>{field.id}</small>
                                    </div>
                                    <div className={'col-md-2'}>
                                        <RoundButton
                                            text={<AiFillCloseCircle/>}
                                            className={'btn-danger'}
                                            onClickHandler={()=>{
                                                if(selectedColumns === undefined || selectedColumns.length === undefined)
                                                    selectedColumns = [];
                                                const _fields = [...selectedColumns];
                                                _fields.splice(i, 1);
                                                updateSelectedColumns(_fields);
                                            }}
                                        />
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                    <br/>
                </>  
            )}
            <RoundButton
                className={"custom-btn-primary"}
                text={"Adauga camp"}
                onClickHandler={() => { setIsAddNewFieldDialogOpen(true); }}
            />

            <AddNewFieldOnFormDialog
                type={modelType}
                isOpen={isAddNewFieldDialogOpen}
                toggle={()=>{setIsAddNewFieldDialogOpen(false);}}
                onSelected={(newField)=>{
                    if(selectedColumns === undefined || selectedColumns.length === undefined)
                        selectedColumns = [];
                    const _fields = [...selectedColumns];
                    _fields.push(newField);
                    updateSelectedColumns(_fields);
                    setIsAddNewFieldDialogOpen(false);
                }}
                skipExternals={true}
            />
        </>
    );
}