import React, {useEffect, useState} from 'react';
import {LayoutCard} from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {RoundButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import {IoAddCircle} from "react-icons/io5";
import {BsFilePersonFill} from "react-icons/bs";
import {get_ajax, post} from 'shared/dist/utils/DataProvider';
import { CreateResourceDialog, EditResourceDialog, PreviewDialog } from 'shared/dist/components/generic-building-blocks/UserDialogs';
import {FormGroup, FormGroupArea} from 'shared/dist/components/generic-building-blocks/FormGroup';
import {AiFillCloseCircle} from "react-icons/ai";
import {skipDefaultNames} from "./constants";
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {Checkbox} from 'shared/dist/components/generic-building-blocks/Checkbox';
import {TableFieldsConfigurator} from '../cim_configurator/CimFormsBuilder';
import {MdOutlineModelTraining} from 'react-icons/md';
import * as RiLib from "react-icons/lib";
import codegen from "babel-plugin-codegen/macro";
import loadable from "@loadable/component";
import ReactDOMServer from 'react-dom/server';
import { v4 as uuidv4 } from 'uuid';
import {CimConfigDashboard} from "./CimConfigDashboard";

export const CimConfigRoles = () => {
    return (
        <CimConfigDashboard
            selectedKey={'roles'}
        >
            <EditableCimRolesConfigurator/>
        </CimConfigDashboard>
    );
}

const EditableCimRolesConfigurator = () => {
    const [cimRoles, setCimRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    
    const loadRoles = () => {
        setIsLoading(true);
        get_ajax('CIMConfigurator/CustomRolesConfiguration/GetCustomRoles', (roles) => {
            setCimRoles(roles);
        });
        setIsLoading(false);
    }
    useEffect(()=>{
        loadRoles();
    }, []);
    
    const [isNewSubroleDialogOpen, setIsNewSubroleDialogOpen] = useState(false);
    if(selectedRole !== undefined)
    {
        return (
            <RoleConfigurator
                selectedRole={selectedRole}
                jsonData={JSON.parse(selectedRole.roleConfigJSON)}
                setSelectedRole={()=>{setSelectedRole(undefined); loadRoles(); }}
                updateSelectedRole={(property, value) => {
                    let _selectedRoleJSON = JSON.parse(selectedRole.roleConfigJSON);
                    _selectedRoleJSON[property] = value;
                    post('CIMConfigurator/CustomRolesConfiguration/UpdateRoleJsonConfig', {
                        roleId: selectedRole.id,
                        jsonData: JSON.stringify(_selectedRoleJSON)
                    }, (response) => {
                        if(response === false) {
                            alert("Error updating role");
                        }
                        const _selectedRole = selectedRole;
                        _selectedRole.roleConfigJSON = JSON.stringify(_selectedRoleJSON);
                        setSelectedRole(_selectedRole);
                    });
                }}
            />
        )
    }
    return (
        <>
            <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                <h5>Personalizeaza rolurile CIM cu subroluri</h5>
                <p>Rolul <i>Default</i> are permisiunile prestabilite pentru modulul CIM. In cazul in care doriti roluri multiple in cadrul acestui modul, adaugati noile roluri mai jos.</p>
            </LayoutCard>

            {isLoading && (<LoadingCircleComponent/>)}
            {!isLoading && (
                <>
                    <div className={'row'}>
                        {cimRoles.map((role, index) =>
                            <div className={'col-md-4'} key={index}>
                                <LayoutCard
                                    onClick={()=>{ setSelectedRole(role) }}
                                    className="layout_card layout_card_25bottom layout_card_padded shadow-sm cursor_pointer"
                                >
                                    <BsFilePersonFill size={32} className={"float-left"} style={{paddingRight: '10px', marginTop: '-5px'}}/>
                                    <h5>{role.roleName}</h5>
                                </LayoutCard>
                            </div>
                        )}
                        <div className={'col-md-4'}>
                            <LayoutCard
                                onClick={()=>{ setIsNewSubroleDialogOpen(true); }}
                                className="layout_card layout_card_25bottom layout_card_padded shadow-sm cursor_pointer center"
                            >
                                <IoAddCircle size={48}/>
                            </LayoutCard>
                        </div>
                    </div>
                    <RoleAssignationComponent cimRoles={cimRoles}/>
                </>
            )}
            
            <AddNewSubRoleDialog
                isOpen={isNewSubroleDialogOpen}
                toggle={()=>{
                    setIsNewSubroleDialogOpen(false);
                    loadRoles();
                }}
            />
        </>
    );
}

const AddNewSubRoleDialog = ({isOpen, toggle}) => {
    const [roleName, setRoleName] = useState('');
    return (
        <CreateResourceDialog
            isOpen={isOpen}
            toggle={toggle}
            size={'md'}
            title={'Adauga rol'}
            onConfirm={()=>{
                post('CIMConfigurator/CustomRolesConfiguration/AddCustomRole',
                    {
                        'newRoleName': roleName
                    }, (response) => {
                        if(response === true)
                        {
                            toggle();
                            return;
                        }
                        alert("Eroare la adaugare");
                    });
            }}
        >
            <FormGroup
                label={'Nume rol nou'}
                value={roleName}
                onChange={(e) =>{ setRoleName(e.target.value); }}
            />
        </CreateResourceDialog>
    )
}

const RoleConfigurator = ({selectedRole, jsonData, setSelectedRole, updateSelectedRole}) => {
    const [allowedModels, setAllowedModels] = useState(jsonData['allowedModels'] ? jsonData['allowedModels'] : []);
    useEffect(()=>{
        updateSelectedRole("allowedModels", allowedModels);
    }, [allowedModels]);
    
    const [sideMenuOptions, setSideMenuOptions] = useState(jsonData['sideMenuOptions'] ? jsonData['sideMenuOptions'] : []);
    useEffect(()=>{
        updateSelectedRole("sideMenuOptions", sideMenuOptions);
    }, [sideMenuOptions]);
    
    const [isAddNewAllowedModelDialogOpen, setIsAddNewAllowedModelDialogOpen] = useState(false);
    const [isAddNewSideMenuOptionDialogOpen, setIsAddNewSideMenuOptionDialogOpen] = useState(false);
    
    const [selectedMenuOptionIndex, setSelectedMenuOptionIndex] = useState(-1);
    
    const [rolesDetails, setRolesDetails] = useState(jsonData['rolesDetails'] ? jsonData['rolesDetails'] : {})
    useEffect(()=>{
        updateSelectedRole("rolesDetails", rolesDetails);
    }, [rolesDetails]);

    const [isMenuOptionLoading, setIsMenuOptionLoading] = useState(false);
    
    return (
        <>
            <LayoutCard
                className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}
            >
                <div className={'row'}>
                    <div className={'col-md-1'}>
                        <span
                            style={{cursor: 'pointer'}}
                            className={"redirectNavigationLink"}
                            onClick={()=>{setSelectedRole(undefined)}}
                        >
                            Inapoi
                        </span>
                    </div>
                    <div className={'col-md-5'}>
                        <h5 style={{textAlign: 'center'}}>{selectedRole.roleName}</h5>
                    </div>
                </div>
            </LayoutCard>
            <div className={'row'}>
                <div className={'col-md-3'}>
                    <LayoutCard
                        className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}
                    >
                        <h6><b>Meniu lateral</b></h6> <br/>
                        <ul className={"list-group list-group-flush"}>
                            {sideMenuOptions.map((model, index) =>
                                <li 
                                    key={index}
                                    className={index === selectedMenuOptionIndex ? "list-group-item-active" : "list-group-item"} 
                                    style={{padding: '3px'}}
                                    onClick={()=>{
                                        if(selectedMenuOptionIndex !== index)
                                        {
                                            setSelectedMenuOptionIndex(index);
                                        } else {
                                            setSelectedMenuOptionIndex(-1);
                                        }
                                    }}
                                >
                                    <div className={'row'}>
                                        <div className={'col-md-9'}>
                                            <label style={{height: '100%', lineHeight: '100%'}}>{model.name}</label>
                                        </div>
                                        <div className={'col-md-3'}>
                                            <RoundButton
                                                text={<AiFillCloseCircle style={{marginLeft: '-3px'}}/>}
                                                className={'btn-danger'}
                                                onClickHandler={()=>{
                                                    const _sideMenuOptions = [...sideMenuOptions];
                                                    _sideMenuOptions.splice(index, 1);
                                                    setSideMenuOptions(_sideMenuOptions);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </li>
                            )}
                            {sideMenuOptions.length === 0  && (
                                <label>Nu exista modele permise</label>
                            )}
                        </ul>
                        <br/>
                        <RoundButton
                            text={"Adauga optiune"}
                            className={'custom-btn-primary'}
                            onClickHandler={()=>{ setIsAddNewSideMenuOptionDialogOpen(true); }}
                        />
                    </LayoutCard>
                    <LayoutCard
                        className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}
                    >
                        <h6><b>Modele permise</b></h6> <br/>
                        <ul className="list-group list-group-flush">
                            {allowedModels.map((model, index) =>
                                <li className="list-group-item" style={{padding: '3px'}} key={index}>
                                    <div className={'row'}>
                                        <div className={'col-md-9'}>
                                            <label style={{height: '100%', lineHeight: '100%'}}>{model.name}</label>
                                        </div>
                                        <div className={'col-md-3'}>
                                            <RoundButton
                                                text={<AiFillCloseCircle style={{marginLeft: '-3px'}}/>}
                                                className={'btn-danger'}
                                                onClickHandler={()=>{
                                                    const _allowedModels = [...allowedModels];
                                                    _allowedModels.splice(index, 1);
                                                    setAllowedModels(_allowedModels)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </li>
                            )}
                            {allowedModels.length === 0  && (
                                <label>Nu exista modele permise</label>
                            )}
                        </ul>
                        <br/>
                        <RoundButton
                            text={"Adauga model"}
                            className={'custom-btn-primary'}
                            onClickHandler={()=>{ setIsAddNewAllowedModelDialogOpen(true); }}
                        />
                    </LayoutCard>
                </div>
                <div className={'col-md-9'}>
                    {(selectedMenuOptionIndex !== -1 && sideMenuOptions !== undefined && sideMenuOptions[selectedMenuOptionIndex] !== undefined) && (
                        <>
                            <RoleMenuViewConfigurator
                                menuName={sideMenuOptions[selectedMenuOptionIndex].name}
                                setMenuName={(newName) => {
                                    const _sideMenuModels = [...sideMenuOptions];
                                    _sideMenuModels[selectedMenuOptionIndex].name = newName;
                                    setSideMenuOptions(_sideMenuModels);
                                }}
                                menuType={sideMenuOptions[selectedMenuOptionIndex].type}
                                roleDetails={rolesDetails[sideMenuOptions[selectedMenuOptionIndex].id] ? rolesDetails[sideMenuOptions[selectedMenuOptionIndex].id] : 
                                    rolesDetails[sideMenuOptions[selectedMenuOptionIndex].type] ? rolesDetails[sideMenuOptions[selectedMenuOptionIndex].type] : {}}
                                setRoleDetails={(internalKey, keyValue)=>{
                                    let dict = {...rolesDetails[sideMenuOptions[selectedMenuOptionIndex].id]};
                                    if(!dict) { dict = {}; }
                                    dict[internalKey] = keyValue;
                                    const _roles = {...rolesDetails};
                                    _roles[sideMenuOptions[selectedMenuOptionIndex].id] = dict;
                                    setRolesDetails(_roles);
                                }}
                                roleIcon={sideMenuOptions[selectedMenuOptionIndex].icon}
                                setRoleIcon={(iconStr)=>{
                                    const _sideMenuModels = [...sideMenuOptions];
                                    _sideMenuModels[selectedMenuOptionIndex].icon = iconStr;
                                    setSideMenuOptions(_sideMenuModels);
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
            <PickModelModal
                isOpen={isAddNewAllowedModelDialogOpen}
                toggle={()=>{ setIsAddNewAllowedModelDialogOpen(false); }}
                selectModel={(model)=>{
                    const _allowedModels = [...allowedModels];
                    _allowedModels.push(model);
                    setAllowedModels(_allowedModels);
                    setIsAddNewAllowedModelDialogOpen(false);
                }}
            />
            <PickFromAllowedModels
                isOpen={isAddNewSideMenuOptionDialogOpen}
                toggle={()=>{ setIsAddNewSideMenuOptionDialogOpen(false); }}
                selectModel={(model)=>{
                    const _sideMenuModels = [...sideMenuOptions];
                    model.id = uuidv4();
                    _sideMenuModels.push(model);
                    setSideMenuOptions(_sideMenuModels);
                    setIsAddNewSideMenuOptionDialogOpen(false);
                }}
                modelsList={allowedModels}
            />
        </>
    );
}

const RoleMenuViewConfigurator = ({menuType, menuName, setMenuName, roleDetails, setRoleDetails, roleIcon, setRoleIcon}) => {
    
    const [availableForms, setAvailableForms] = useState([]);
    useEffect(()=>{
        get_ajax('CIMConfigurator/CustomFormConfigurator/GetForms', (data) => {
            setAvailableForms(data[menuType]);
        });
    }, [menuType]);

    const [newElementFormOption, setNewElementFormOption] = useState(roleDetails["newElementFormOption"] ? roleDetails["newElementFormOption"] : "disabled");
    useEffect(()=>{
        setRoleDetails("newElementFormOption", newElementFormOption);
    }, [newElementFormOption]);

    const [editElementFormOption, setEditElementFormOption] = useState(roleDetails["editElementFormOption"] ? roleDetails["editElementFormOption"] : "disabled");
    useEffect(()=>{
        setRoleDetails("editElementFormOption", editElementFormOption);
    }, [editElementFormOption]);

    const [viewElementFormOption, setViewElementFormOption] = useState(roleDetails["viewElementFormOption"] ? roleDetails["viewElementFormOption"] : "disabled");
    useEffect(()=>{
        setRoleDetails("viewElementFormOption", viewElementFormOption);
    }, [viewElementFormOption]);

    const [listViewType, setListViewType] = useState(roleDetails["listViewType"] ? roleDetails["listViewType"] : "disabled");
    useEffect(()=>{
        setRoleDetails("listViewType", listViewType);
    }, [listViewType]);
    
    const [listViewTableSelectedColumns, setListViewTableSelectedColumns] = useState(roleDetails["listViewTableSelectedColumns"] ? roleDetails["listViewTableSelectedColumns"] : []);
    useEffect(()=>{
        setRoleDetails("listViewTableSelectedColumns", listViewTableSelectedColumns);
    }, [listViewTableSelectedColumns]);

    const [allowedModelActions, setAllowedModelActions] = useState(roleDetails["allowedModelActions"] ? roleDetails["allowedModelActions"] : []);
    useEffect(()=>{
        setRoleDetails("allowedModelActions", allowedModelActions);
    }, [allowedModelActions]);

    const [allowViewModelHistory, setAllowViewModelHistory] = useState(roleDetails["allowViewModelHistory"] ? roleDetails["allowViewModelHistory"] : "false");
    useEffect(()=>{
        setRoleDetails("allowViewModelHistory", allowViewModelHistory);
    }, [allowViewModelHistory]);

    const [allowViewGeneratedDocs, setAllowViewGeneratedDocs] = useState(roleDetails["allowViewGeneratedDocs"] ? roleDetails["allowViewGeneratedDocs"] : "false");
    useEffect(()=>{
        setRoleDetails("allowViewGeneratedDocs", allowViewGeneratedDocs);
    }, [allowViewGeneratedDocs]);
    
    useEffect(()=>{
        setNewElementFormOption(roleDetails["newElementFormOption"] ? roleDetails["newElementFormOption"] : "disabled");
        setListViewType(roleDetails["listViewType"] ? roleDetails["listViewType"] : "disabled");
        setListViewTableSelectedColumns(roleDetails["listViewTableSelectedColumns"] ? roleDetails["listViewTableSelectedColumns"] : []);
        setEditElementFormOption(roleDetails["editElementFormOption"] ? roleDetails["editElementFormOption"] : "disabled");
        setViewElementFormOption(roleDetails["viewElementFormOption"] ? roleDetails["viewElementFormOption"] : "disabled");
        setAllowViewModelHistory(roleDetails["allowViewModelHistory"] ? roleDetails["allowViewModelHistory"] : "false");
        setAllowViewGeneratedDocs(roleDetails["allowViewGeneratedDocs"] ? roleDetails["allowViewGeneratedDocs"] : "false");
        if(roleDetails["allowedModelActions"])
        {
            setAllowedModelActions(roleDetails["allowedModelActions"] ? roleDetails["allowedModelActions"]: []);    
        }
        
        setIsLoading(false);
    }, [roleDetails]);

    const [isLoading, setIsLoading] = useState(true);
    
    const [showAddActionModal, setShowAddActionModal] = useState(false);
    const [showIconPickDialog, setShowIconPickDialog] = useState(false);

    if(isLoading) return (<LoadingCircleComponent/>);
    return (
        <>
            <LayoutCard
                className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}
            >
                <div className='row'>
                    <div className={'col-md-4'}>
                        <input className={'form-control'} value={menuName} onChange={(e) => {setMenuName(e.target.value)}}/>
                    </div>
                    <div className='col-md-4'>
                        <div className='float-right'>
                            <div onClick={()=>{ setShowIconPickDialog(true); }}>
                                {roleIcon === undefined && (<MdOutlineModelTraining size={28}/>)}
                                {roleIcon && (<span dangerouslySetInnerHTML={{__html: roleIcon}}></span>)}
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutCard>
            <div className={'row'}>
                <div className={'col-md-6'}>
                    <LayoutCard
                        className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}
                    >
                        <h6><b>Vizualizare date</b></h6> <br/>
                        <div className={'row'}>
                            <div className={'col-md-6'}>
                                Tip vizualizare
                            </div>
                            <div className={'col-md-6'}>
                                <select 
                                    className={'form-control'}
                                    value={listViewType}
                                    onChange={(e) => {setListViewType(e.target.value); }}
                                >
                                    <option value={"disabled"}>Dezactivat</option>
                                    <option value={"tableView"}>Tabel</option>
                                    <option value={"gridView"}>Carduri</option>
                                </select>
                            </div>
                        </div>
                        {listViewType === "tableView" && (
                            <>
                                <hr/>
                                <label>Alege coloane tabel:</label>
                                <TableFieldsConfigurator
                                    selectedColumns={listViewTableSelectedColumns}
                                    updateSelectedColumns={(newColumns)=>{ setListViewTableSelectedColumns(newColumns); }}
                                    modelType={menuType}
                                />
                                <hr/>
                            </>
                        )}
                    </LayoutCard>
                </div>
                <div className={'col-md-6'}>
                    <LayoutCard
                        className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}
                    >
                        <h6><b>Optiuni generale</b></h6> <br/>
                        <ul className={"list-group"}>
                            <li className={"list-group-item"}>
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        Element nou
                                    </div>
                                    <div className={'col-md-6'}>
                                        <select 
                                            className={'form-control'}
                                            value={newElementFormOption}
                                            onChange={(e) => {setNewElementFormOption(e.target.value); }}
                                        >
                                            <option value={"disabled"}>Dezactivat</option>
                                            {availableForms.map(form => 
                                                <option value={form.id} key={form.id}>{form.formName}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </li>
                            <li className={"list-group-item"}>
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        Editeaza element
                                    </div>
                                    <div className={'col-md-6'}>
                                        <select
                                            className={'form-control'}
                                            value={editElementFormOption}
                                            onChange={(e) => {setEditElementFormOption(e.target.value); }}
                                        >
                                            <option value={"disabled"}>Dezactivat</option>
                                            {availableForms.map(form =>
                                                <option value={form.id} key={form.id}>{form.formName}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </li>
                            <li className={"list-group-item"}>
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        Vizualizeaza element
                                    </div>
                                    <div className={'col-md-6'}>
                                        <select
                                            className={'form-control'}
                                            value={viewElementFormOption}
                                            onChange={(e) => {setViewElementFormOption(e.target.value); }}
                                        >
                                            <option value={"disabled"}>Dezactivat</option>
                                            {availableForms.map(form =>
                                                <option value={form.id} key={form.id}>{form.formName}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <br/>
                        {(editElementFormOption !== 'disabled' || viewElementFormOption !== 'disabled') && (
                            <>
                                <Checkbox
                                    checkLabel={'Permite vizualizare istoric?'}
                                    value={allowViewModelHistory === 'true'}
                                    onChange={(e)=>{
                                        setAllowViewModelHistory(e.target.checked ? 'true' : "false");
                                    }}
                                />
                                <Checkbox
                                    checkLabel={'Permite vizualizare documente generate?'}
                                    value={allowViewGeneratedDocs === 'true'}
                                    onChange={(e)=>{
                                        setAllowViewGeneratedDocs(e.target.checked ? 'true' : "false");
                                    }}
                                />
                            </>
                        )}
                    </LayoutCard>
                    <LayoutCard
                        className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}
                    >
                        <h6><b>Actiuni permise pe model</b></h6> <br/>
                        <ul className={'list-group'} style={{marginBottom: '25px'}}>
                            {allowedModelActions.map((action, i) => 
                                <li key={i} className={'list-group-item'}>
                                    <div className={'row'}>
                                        <div className={'col-md-10'}>{action.actionName}</div>
                                        <div className={'col-md-2'}>
                                            <RoundButton
                                                text={<AiFillCloseCircle style={{marginLeft: '-3px'}}/>}
                                                className={'btn-danger'}
                                                onClickHandler={()=>{
                                                    const _allowedModelActions = [...allowedModelActions];
                                                    _allowedModelActions.splice(i, 1);
                                                    setAllowedModelActions(_allowedModelActions);
                                                }}
                                            />  
                                        </div>
                                    </div>
                                </li>
                            )}
                        </ul>
                        <RoundButton
                            text={"Adauga actiune"}
                            className={'custom-btn-primary'}
                            onClickHandler={()=>{ setShowAddActionModal(true); }}
                        />
                    </LayoutCard>
                </div>
            </div>
            
            <PickActionModal
                isOpen={showAddActionModal}
                toggle={()=>{setShowAddActionModal(false);}}
                modelType={menuType}
                selectAction={(e)=>{
                    const _allowedModelActions = [...allowedModelActions];
                    _allowedModelActions.push(e);
                    setAllowedModelActions(_allowedModelActions);
                    setShowAddActionModal(false);
                }}
            />
            <PickIconModal
                isOpen={showIconPickDialog}
                toggle={()=>{setShowIconPickDialog(false);}}
                selectIconString={(iconStr) => {
                    setRoleIcon(iconStr);
                    setShowIconPickDialog(false);
                }}
            />
        </>
    );
}

const PickFromAllowedModels = ({isOpen, toggle, selectModel, modelsList}) => {
    return (
        <CreateResourceDialog
            isOpen={isOpen}
            toggle={toggle}
            size={'xl'}
            title={'Alege model'}
            saveBtnOverwride={'Selecteaza'}
            hideCreateButton={true}
            onConfirm={()=>{

            }}
        >
            <div className={'row'}>
                {modelsList.map((option, i) =>
                    <div className={'col-md-3'} key={i}>
                        <LayoutCard
                            onClick={()=>{ selectModel(option); }}
                            className="layout_card layout_card1"
                        >
                            <h5>{option.name}</h5>
                        </LayoutCard>
                    </div>
                )}
            </div>
        </CreateResourceDialog>
    );
};

const PickModelModal = ({isOpen, toggle, selectModel}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [modelsList, setModelsList] = useState([]);
    useEffect(()=>{
        if(isOpen === false) return;

        get_ajax(`CIMConfigurator/CustomModelConfigurator/GetCustomObjectTypes`, (custom_types) => {
            if(custom_types === undefined) return;
            setModelsList(custom_types);
            setIsLoading(false);
        }).catch((e) => {
            setIsLoading(false);
        });
        
    }, [isOpen]);
    
    return (
        <CreateResourceDialog
            isOpen={isOpen}
            toggle={toggle}
            size={'xl'}
            title={'Alege model'}
            saveBtnOverwride={'Selecteaza'}
            hideCreateButton={true}
            onConfirm={()=>{
                
            }}
        >
            <div className={'row'}>
                {modelsList.map((option, i) =>
                    <div className={'col-md-3'} key={i}>
                        <LayoutCard
                            onClick={()=>{ selectModel(option); }}
                            className="layout_card layout_card1"
                        >
                            <h5>{option.name}</h5>
                        </LayoutCard>
                    </div>
                )}
            </div>
        </CreateResourceDialog>
    )
}

const PickActionModal = ({isOpen, toggle, modelType, selectAction}) => {
    const [modelsList, setModelsList] = useState([]);
    useEffect(()=>{
        if(isOpen === false) return;

        get_ajax(`CIMConfigurator/CustomActionsConfigurator/GetActions`, (actions) => {
            try {
                setModelsList(actions[modelType]);
            }
            catch (e) {
                setModelsList([]);
            }
        });

    }, [isOpen]);

    return (
        <CreateResourceDialog
            isOpen={isOpen}
            toggle={toggle}
            size={'xl'}
            title={'Alege actiune'}
            saveBtnOverwride={'Selecteaza'}
            hideCreateButton={true}
            onConfirm={()=>{

            }}
        >
            <div className={'row'}>
                {modelsList.map((option, i) =>
                    <div className={'col-md-3'} key={i}>
                        <LayoutCard
                            onClick={()=>{ selectAction(option); }}
                            className="layout_card layout_card1"
                        >
                            <h5>{option.actionName}</h5>
                        </LayoutCard>
                    </div>
                )}
            </div>
        </CreateResourceDialog>
    )
}

const RoleAssignationComponent = ({cimRoles}) => {
    
    const [isLoading, setIsLoading] = useState(true);
    const [usersList, setUsersList] = useState([]);
    
    useEffect(()=>{
        loadUsers();
    }, [cimRoles]);
    
    const loadUsers = () => {
        get_ajax('CIMConfigurator/CustomRolesConfiguration/GetCIMEnabledAccounts', (users) => {
            setUsersList(users);
            setIsLoading(false);
        });
    }
    
    if(isLoading) return (<LoadingCircleComponent/>);
    return (
        <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
            <h5>Atribuie roluri utilizatorilor CIM</h5>
            <br/>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nume utilizator</th>
                    <th scope="col">Nume</th>
                    <th scope="col">Prenume</th>
                    <th scope="col">Rol CIM</th>
                </tr>
                </thead>
                <tbody>
                    {usersList.map((user, index) => 
                        <tr key={index}>
                            <th scope="row">{index+1}</th>
                            <td>{user.userName}</td>
                            <td>{user.nume}</td>
                            <td>{user.prenume}</td>
                            <td>
                                <select
                                    value={user.cimSubRole}    
                                    className={'form-control'}
                                    onChange={(e) => {
                                        post('CIMConfigurator/CustomRolesConfiguration/UpdateCIMRole', {
                                            userId: user.id,
                                            roleId: e.target.value,
                                        }, (response) => {
                                            if(response === false) {
                                                alert("Error updating role");
                                            }
                                            loadUsers();
                                        });
                                    }}
                                >
                                    {cimRoles.map((role) => 
                                        <option value={role.id} key={role.id}>{role.roleName}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </LayoutCard>
    );
}

const PickIconModal = ({isOpen, toggle, selectIconString}) => {
    const ALL_ICONS = RiLib["IconsManifest"]; // -> this is if you really want all icons. Alternatively, you can put just the collections
    const getIconById = (id) => {
    return ALL_ICONS.find((i) => i.id === id);
    };
    const icons_for_search = [getIconById('fa'), getIconById('md')];
    const [searchString, setSearchString] = useState('');

    return (
        <CreateResourceDialog
            isOpen={isOpen}
            toggle={toggle}
            size={'xl'}
            title={'Alege pictograma'}
            saveBtnOverwride={'Selecteaza'}
            hideCreateButton={true}
            onConfirm={()=>{

            }}
        >
            <FormGroup 
                label={'Cauta'}
                value={searchString}      
                onChange={(e) =>{ setSearchString(e.target.value); }}     
            />
            <div className={'row'}>
                {icons_for_search.map((icon) => 
                    <SearchIconSet
                        key={icon.id}
                        icon={icon.id}
                        query={searchString}
                        selectIconStr = {selectIconString}
                    />
                )}
            </div>
        </CreateResourceDialog>
    )
}

const SearchIconSet = ({icon, query, selectIconStr}) => {
    const fn = codegen`
        const { IconsManifest } = require("react-icons/lib/cjs");

        let codes = "(function (id) { switch (id) {";
        IconsManifest.forEach(icon => {
        codes += 'case "' + icon.id + '":\\nreturn import("react-icons/' + icon.id +'/index");\\n'
        })
        codes += '}})';

        module.exports = codes;
        // module.exports = "import('react-icons/fa/index')"
    `;

    function getIcons(iconsId) {
        return fn(iconsId);
    }

    const IconSet = loadable.lib(() => getIcons(icon));
    return (
        <IconSet fallback={<></>}>
            {({ default: icons }) => {
                const found = Object.keys(icons).filter((name) =>
                    query ? name.toLowerCase().includes(query) : true
                );
                return (
                    <>
                        {found.map((name, i) => (
                            <div key={i} className='col-md-1' style={{margin: '5px', marginTop: '10px', marginBottom: '10px'}} onClick={() => {
                                selectIconStr(ReactDOMServer.renderToString(icons[name]()).replace("1em", "28").replace("1em", "28"));
                            }}>
                                <span dangerouslySetInnerHTML={{__html: ReactDOMServer.renderToString(icons[name]()).replace("1em", "28").replace("1em", "28")}}></span>
                            </div>
                        ))}
                    </>
                );
            }}
        </IconSet>
    )
}