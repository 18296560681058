import React, {useEffect, useState} from "react";
import {get_ajax, post} from 'shared/dist/utils/DataProvider';
import {LayoutCard} from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {RoundButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import { CreateResourceDialog, EditResourceDialog, PreviewDialog, DeleteConfirmationDialog } from 'shared/dist/components/generic-building-blocks/UserDialogs';
import {FaDatabase, FaFolder} from 'react-icons/fa';
import {MdDeleteOutline} from 'react-icons/md';
import {IoIosConstruct} from 'react-icons/io';
import {IoAddCircle, IoCreate} from 'react-icons/io5';
import {VscSymbolMisc} from 'react-icons/vsc';
import {AiOutlineForm} from 'react-icons/ai';
import {FormGroup, FormGroupArea} from 'shared/dist/components/generic-building-blocks/FormGroup';
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {AiFillPlusCircle, AiFillRightCircle, AiFillCloseCircle, AiOutlineUnorderedList} from 'react-icons/ai';
import {BsPlusCircleFill, BsCheck2} from 'react-icons/bs';
import {BiText} from 'react-icons/bi';
import {HiDuplicate} from 'react-icons/hi';
import {BsFillGearFill} from 'react-icons/bs';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import {MdPreview, MdSave, MdModeEditOutline, MdOutlineDragHandle, MdOutlineSettingsSuggest, MdPendingActions, MdOutlineVisibilityOff} from 'react-icons/md';
import {
    configurableFieldType,
    configurableModelsList,
    defaultRuleDetails, ruleBlockTypes, ruleTypes,
    sideOptionsList,
    skipDefaultNames
} from "./constants";
import {FaWpforms} from 'react-icons/fa';
import {GoSettings} from 'react-icons/go';
import {FcCheckmark} from 'react-icons/fc';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';
import Autocomplete from 'shared/dist/components/generic-building-blocks/AutocompleteTextbox';
import {CustomForm} from 'shared/dist/components/custom_forms/CustomForm';
import {DocumentEditor} from "./DocumentTemplateEditor/DocumentEditor";
import $ from 'jquery';
import { getAccessToken, GetRoles } from 'shared/dist/components/api-authorization/AuthService';
import { printHTML } from 'shared/dist/utils/PdfBuilder';
import {ToggleViewSelector} from 'shared/dist/components/generic-building-blocks/ToggleViewSelector';
import {
    ActionExecutionRulesConfigurator,
    ActionGenerateDocumentConfigurator,
    ActionModelInstanceSelector
} from "./CimActionsBuilder";
import {CimConfigDashboard} from "./CimConfigDashboard";

export const CimConfigDocuments = () => {
    return (
        <CimConfigDashboard
            selectedKey={'documents'}
        >
            <EditableDocumentsConfigurator/>
        </CimConfigDashboard>
    );
}

const EditableDocumentsConfigurator = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [existingForms, setExistingForms] = useState({});
    useEffect(()=>{
        loadData();
    }, []);

    const loadData = () => {
        get_ajax('CIMConfigurator/CustomDocumentsConfigurator/GetDocuments', (data) => {
            setExistingForms(data);
            setIsLoading(false);
        });
    }

    const [isAddNewFormDialogOpen, setIsAddNewFormDialogOpen] = useState(false);
    const [selectedFormsGroup, setSelectedFormsGroup] = useState(undefined);
    const [selectedFormId, setSelectedFormId] = useState(undefined);

    const [useModelBasedGrouping, setUseModelBasedGrouping] = useState(true);
    
    if(selectedFormsGroup === undefined)
    {
        return (
            <>
                <div className={'row'}>
                    <div className={'col-md-8'}></div>
                    <div className={'col-md-4'}>
                        <div className={'row'}>
                            <div className={'col-md-6'}></div>
                            <div className={'col-md-6'}>
                                <ToggleViewSelector
                                    icon1={<FaDatabase/>}
                                    icon2={<FaFolder/>}
                                    firstOptionActive={useModelBasedGrouping}
                                    optionChanged={(active)=>{ setUseModelBasedGrouping(active); }}
                                />  
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div className={'row'}>
                    {useModelBasedGrouping && (
                        <>
                            {isLoading === false && (
                                <>
                                    {Object.keys(existingForms).map((model, i) =>
                                        <div className={'col-md-4'} key={i}>
                                            <LayoutCard
                                                onClick={()=>{setSelectedFormsGroup(model)}}
                                                className="layout_card layout_card_25bottom layout_card_padded shadow-sm cursor_pointer"
                                            >
                                                <FaWpforms size={32} className={"float-left"} style={{paddingRight: '10px', marginTop: '-5px'}}/>
                                                <h5>{model}</h5>
                                            </LayoutCard>
                                        </div>
                                    )}
                                    <div className={'col-md-4'}>
                                        <LayoutCard
                                            onClick={()=>{setIsAddNewFormDialogOpen(true)}}
                                            className="layout_card layout_card_25bottom layout_card_padded shadow-sm cursor_pointer center"
                                        >
                                            <IoAddCircle size={48}/>
                                        </LayoutCard>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {useModelBasedGrouping === false && (
                        <>
                            <p>To be implemented.</p>
                        </>
                    )}
                    <AddNewFormDialog
                        isOpen={isAddNewFormDialogOpen}
                        toggle={()=>{
                            setIsAddNewFormDialogOpen(false);
                            loadData();
                        }}
                    />
                </div>
                {isLoading && (
                    <LoadingCircleComponent/>
                )}
            </>
        );
    }
    else {
        if(selectedFormId === undefined)
        {
            return (
                <>
                    <LayoutCard
                        className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}
                    >
                        <div className={'row'}>
                            <div className={'col-md-1'}>
                                <span
                                    style={{cursor: 'pointer'}}
                                    className={"redirectNavigationLink"}
                                    onClick={()=>{setSelectedFormsGroup(undefined)}}
                                >Inapoi</span>
                            </div>
                            <div className={'col-md-5'}>
                                <h5 style={{textAlign: 'center'}}>{selectedFormsGroup}</h5>
                            </div>
                        </div>
                    </LayoutCard>
                    <div className={'row'}>
                        {existingForms[selectedFormsGroup].map((form, i) =>
                            <div className={'col-md-4'} key={i}>
                                <LayoutCard
                                    onClick={()=>{setSelectedFormId(form.id)}}
                                    className="layout_card layout_card_25bottom layout_card_padded shadow-sm cursor_pointer"
                                >
                                    <div className={'row'}>
                                        <div className={'col-md-8'}>
                                            <h5>{form.documentName}</h5>
                                        </div>
                                        <div className={'col-md-4'}>
                                            {form.documentType === 1 && (
                                                <div className={"float-right"}>
                                                    <BsFillGearFill/>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </LayoutCard>
                            </div>
                        )}
                    </div>
                </>
            );
        } else {
            return (
                <DocumentEditorContainer
                    formId={selectedFormId}
                    formName={existingForms[selectedFormsGroup].filter(x => x.id == selectedFormId)[0].documentName}
                    goBack={(x)=>{
                        if(x) {
                            setSelectedFormId(undefined);
                            setSelectedFormsGroup(undefined);
                        }
                        loadData(); 
                        setSelectedFormId(undefined);
                    }}
                    modelType={selectedFormsGroup}
                />
            );
        }
    }
}

const AddNewFormDialog = ({isOpen, toggle}) => {
    const [formName, setFormName] = useState('');
    const [formModel, setFormModel] = useState('');
    const [modelOptions, setModelOptions] = useState([]);
    const [modelType, setModelType] = useState(0);
    useEffect(()=>{
        if(isOpen === false) return;
        get_ajax("CIMConfigurator/CustomModelConfigurator/GetExternalMappingOptions?editableOnly=true", (options) => {
            setModelOptions(options);
            if(options.length > 0)
            {
                setFormModel(options[0].value);
            }
        })
    }, [isOpen]);
    return (
        <CreateResourceDialog
            isOpen={isOpen}
            toggle={toggle}
            size={'md'}
            title={'Adauga document'}
            onConfirm={()=>{
                post('CIMConfigurator/CustomDocumentsConfigurator/AddCustomDocument',
                    {
                        'modelType': formModel,
                        'customDocumentName': formName,
                        'customDocumentType': modelType
                    }, (response) => {
                        if(response === true)
                        {
                            toggle();
                            return;
                        }
                        alert("Eroare la adaugare");
                    });
            }}
        >
            <div className={'form-group'}>
                <label>Obiect extern</label>
                <select className={'form-control'} value={formModel} onChange={(e)=>{setFormModel(e.target.value)}}>
                    {modelOptions.map(option =>
                        <option value={option.value}>{option.name}</option>
                    )}
                </select>
            </div>
            <FormGroup
                label={'Nume document'}
                value={formName}
                onChange={(e) =>{ setFormName(e.target.value); }}
            />
            <div className={'form-group'}>
                <label>Tip document:</label>
                <select className={'form-control'} value={modelType} onChange={(e) =>{setModelType(e.target.value)}}>
                    <option value={0}>Simplu</option>
                    <option value={1}>Avansat</option>
                </select>
            </div>
        </CreateResourceDialog>
    );
}

const DocumentEditorContainer = ({formId, formName, modelType, goBack}) => {
    const [fields, setFields] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [documentMetadata, setDocumentMetadata] = useState(undefined);
    const [enableSave, setEnableSave] = useState(false);
    const [documentType, setDocumentType] = useState(-1);
    useEffect(()=>{
        get_ajax('CIMConfigurator/CustomDocumentsConfigurator/GetAutocompleteFieldsForModel?modelType=' + modelType, (response) => {
            if(Array.isArray(response))
            {
                setFields(response);
            }
        });
        get_ajax('CIMConfigurator/CustomDocumentsConfigurator/GetDocumentMetadata?documentId=' + formId, (response) => {
            get_ajax('CIMConfigurator/CustomDocumentsConfigurator/GetDocumentType?documentId=' + formId, (responseType) => {
                setDocumentType(responseType);
                if(responseType === 0)
                    setDocumentMetadata(response);
                else {
                    try { setDocumentMetadata(JSON.parse(response)); }
                    catch (e) { setDocumentMetadata([]); }
                }
                setIsLoading(false);
            });
        });
        loadAntet();
    }, [modelType, formId]);

    const [antetImage, setAntetImage] = useState('');
    const [isAntetLoading, setIsAntetLoading] = useState(true);
    const loadAntet = () => {
        get_ajax('CIMConfigurator/CustomDocumentsConfigurator/HasAntet?documentId=' + formId, (response) => {
            if(response === true) {
                let url = `CIMConfigurator/CustomDocumentsConfigurator/GetAntentIfAny?documentId=${formId}`;
                var blob;
                var xmlHTTP = new XMLHttpRequest();
                xmlHTTP.open('GET', url, true);
                xmlHTTP.responseType = 'arraybuffer';
                xmlHTTP.onload = function(e) {
                    blob = new Blob([this.response]);
                };
                xmlHTTP.onloadend = function(e){
                    url = window.URL.createObjectURL(blob);
                    setAntetImage(url);
                    setIsAntetLoading(false);
                }
                const token = getAccessToken();
                xmlHTTP.setRequestHeader('Authorization', token);
                xmlHTTP.send();
            } else {
                setIsAntetLoading(false);
            }
        });
    }
    
    const [selectedFile, setSelectedFile] = useState(null);
    const inputFileChanged = (e) => {
        setSelectedFile(e.target.files[0]);
    }
    
    useEffect(()=>{
        if(selectedFile === null || selectedFile === undefined) { return; }
        setIsAntetLoading(true);
        const targetUrl = "CIMConfigurator/CustomDocumentsConfigurator/UploadAntet?documentId=" + formId;
        var data = new FormData();
        data.append('files', selectedFile);

        var request = new XMLHttpRequest();
        request.upload.addEventListener('progress', function (e) {
            if (e.total === e.loaded) {
                setTimeout(()=>{
                    loadAntet()
                }, 1000);
            }
        }.bind(this), false);

        request.open('POST', targetUrl);
        const token = getAccessToken();
        request.setRequestHeader('Authorization', `${token}`);
        request.send(data);
    }, [selectedFile]);
    
    const [previewHTML, setPreviewHTML] = useState(undefined);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(undefined);
    
    return (
        <>
            <Modal isOpen={previewHTML!==undefined} toggle={()=>{setPreviewHTML(undefined)}} size={'xl'}>
                <ModalHeader>Previzualizare</ModalHeader>
                <ModalBody>
                    <div 
                        style={{border: '1px solid #2f3542', display: 'block', margin: 'auto'}}
                        dangerouslySetInnerHTML={{__html: previewHTML}}
                    ></div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="no-outline" onClick={()=>{setPreviewHTML(undefined)}}>Inchide</Button>
                </ModalFooter>
            </Modal>

            <DeleteConfirmationDialog
                isOpen={showDeleteConfirmation}
                toggle={()=>{setShowDeleteConfirmation(!showDeleteConfirmation)}}
                onConfirm={()=>{
                    post('CIMConfigurator/CustomDocumentsConfigurator/DeleteObject', {
                        'documentId': formId,
                    }, (response) => {
                        if(response === false) {alert("Eroare la stergere"); return;}
                        goBack(true);
                    });
                }}
            />
            
            <LayoutCard className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}>
                <div className={'row'}>
                    <div className={'col-md-1'}>
                        <span
                            style={{cursor: 'pointer'}}
                            className={"redirectNavigationLink"} 
                            onClick={()=>{goBack();}}
                        >Inapoi</span>
                    </div>
                    <div className={'col-md-5'}>
                        <h5 style={{textAlign: 'center'}}>{formName}</h5>
                    </div>
                    <div className={'col-md-6'}>
                        <div className={'row'}>
                            <div className={'col-md-6'}></div>
                            <div className={'col-md-2'}>
                                <RoundButton
                                    className={"custom-btn-primary"}
                                    text={<MdPreview/>}
                                    onClickHandler={()=>{
                                        get_ajax(`CIMConfigurator/CustomDocumentsConfigurator/Preview?documentId=${formId}`, (response) => {
                                            setPreviewHTML(response);
                                        })
                                    }}
                                />
                            </div>
                            <div className={'col-md-2'}>
                                <RoundButton
                                    className={"custom-btn-primary"}
                                    text={<MdSave/>}
                                    disabled={enableSave === false}
                                    onClickHandler={()=>{
                                        post('CIMConfigurator/CustomDocumentsConfigurator/UpdateObjectMetadata', {
                                            'documentId': formId,
                                            'metadata': documentType === 0 ? documentMetadata : JSON.stringify(documentMetadata)
                                        }, (response) => {
                                            if(response === false) {alert("Eroare la salvare"); return;}
                                            setEnableSave(false);
                                        })
                                    }}
                                />
                            </div>
                            <div className={'col-md-2'}>
                                <RoundButton
                                    className={"btn-danger"}
                                    text={<MdDeleteOutline/>}
                                    onClickHandler={()=>{ setShowDeleteConfirmation(true); }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutCard>
            {isLoading && (<LoadingCircleComponent/>)}
            {!isLoading && (
                <>
                    <LayoutCard className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}>
                        <div className={'row'}>
                            <div className={'col-md-6'}>
                                <h6><b>Antet</b></h6>
                                {isAntetLoading && (<LoadingCircleComponent/>)}
                                {!isAntetLoading && (
                                    <>
                                        <img src={antetImage} style={{width: '100%'}}/>
                                    </>
                                )}
                            </div>
                            <div className={'col-md-6'}>
                                <div className={'row'}>
                                    <div className={'col-md-8'}></div>
                                    <div className={'col-md-4'}>
                                        <RoundButton
                                            className={"custom-btn-primary"}
                                            text={"Incarca"}
                                            onClickHandler={()=>{
                                                $("#autoInputObj_ARCH_F_UPL").click();
                                            }}
                                        />
                                        {antetImage !== '' && (
                                            <>
                                            <br/>
                                                <RoundButton
                                                    className={"btn-danger"}
                                                    text={"Sterge"}
                                                    onClickHandler={()=>{
                                                        post('CIMConfigurator/CustomDocumentsConfigurator/DeleteAntet', {
                                                            'documentId': formId,
                                                        }, (response) => {
                                                            if(response === false) {alert("Eroare la stergere"); return;}
                                                            setIsAntetLoading(true);
                                                            setAntetImage('');
                                                            setTimeout(()=>{
                                                                loadAntet();
                                                            }, 1000);
                                                        })
                                                    }}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <input id="autoInputObj_ARCH_F_UPL" accept="image/png, image/gif, image/jpeg" hidden type="file" style={{ display: 'none', position: 'fixed', top: '-5000em' }} onChange={inputFileChanged} multiple={false} />
                    </LayoutCard>
                    {documentType === 0 && (
                        <LayoutCard className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}>
                            <DocumentEditor
                                documentJSON={documentMetadata}
                                updateDocumentJSON={(json) => {
                                    setDocumentMetadata(json);
                                    setEnableSave(true);
                                }}
                                modelFields={fields}
                            />
                        </LayoutCard>  
                    )}
                    {documentType === 1 && (
                        <LayoutCard className={"layout_card layout_card_25bottom layout_card_padded shadow-sm"}>
                            {documentMetadata.map((action, i) => (
                                <div className="card" key={i} style={{marginBottom: '25px'}}>
                                    <div className="card-header">
                                        <div className={'row'}>
                                            <div className={'col-md-6'}>
                                                <h6><b>{(i+1)}. Subdocument</b></h6>
                                            </div>
                                            <div className={'col-md-6'}>
                                                <div className={'row'}>
                                                    <div className={'col-md-10'}></div>
                                                    <div className={'col-md-2'}>
                                                        <RoundButton
                                                            className={"btn-danger"}
                                                            text={<MdDeleteOutline/>}
                                                            onClickHandler={()=>{
                                                                const _actions = [...documentMetadata];
                                                                _actions.splice(i, 1);
                                                                setEnableSave(true);
                                                                setDocumentMetadata(_actions);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className={'col-md-6'}>
                                                <ActionModelInstanceSelector
                                                    modelType={modelType}
                                                    modelInstanceSelector={action.modelInstanceSelector}
                                                    updateModelInstanceSelector={(key, value)=>{
                                                        const _actions = [...documentMetadata];
                                                        _actions[i]['modelInstanceSelector'][key] = value;
                                                        setEnableSave(true);
                                                        setDocumentMetadata(_actions);
                                                    }}
                                                />
                                                <ActionGenerateDocumentConfigurator
                                                    modelType={action.modelInstanceSelector['referenceType'] === 1 ?
                                                        action.modelInstanceSelector['externalObject'].substring(1) : modelType}
                                                    actionPayload={action.actionPayload}
                                                    updateActionPayload={(key, value) => {
                                                        const _actions = [...documentMetadata];
                                                        _actions[i]['actionPayload'][key] = value;
                                                        setEnableSave(true);
                                                        setDocumentMetadata(_actions);
                                                    }}
                                                />
                                            </div>
                                            <div className={'col-md-6'}>
                                                <ActionExecutionRulesConfigurator
                                                    modelType={modelType}
                                                    instanceRules={action.executionRules}
                                                    updateInstanceRules={(value)=>{
                                                        const _actions = [...documentMetadata];
                                                        _actions[i]['executionRules'] = value;
                                                        setEnableSave(true);
                                                        setDocumentMetadata(_actions);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {documentMetadata.length === 0 && (
                                <p>Nu exista nicio actiune. Adaugati una mai jos</p>
                            )}
                            <div className="card-footer text-muted" style={{
                                marginLeft: '-25px', marginRight: '-25px', marginBottom: '-25px', marginTop: '25px',
                                paddingTop: '25px', paddingBottom: '25px'
                            }}>
                                <div className={'row'}>
                                    <div className={'col-md-4'}>
                                        <RoundButton
                                            className={"custom-btn-primary"}
                                            onClickHandler={()=>{
                                                const _metadataActions = [...documentMetadata];
                                                _metadataActions.push({
                                                    modelInstanceSelector: {},
                                                    actionPayload: {}
                                                });
                                                setEnableSave(true);
                                                setDocumentMetadata(_metadataActions);
                                            }}
                                            text={(
                                                <>
                                                    <IoAddCircle/>
                                                    <span style={{marginLeft: '5px'}}>Element nou</span>
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </LayoutCard>  
                    )}
                </>  
            )}
        </>
    )
}