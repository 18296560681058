import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import { SearchBox } from 'shared/dist/components/generic-building-blocks/SearchBox';
import { get_ajax, post } from 'shared/dist/utils/DataProvider';
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import { Link, Redirect } from 'react-router-dom';
import { RoundButton, RoundRedirectButton } from 'shared/dist/components/generic-building-blocks/RoundButton';
import { FormGroup } from 'shared/dist/components/generic-building-blocks/FormGroup';
import { CustomResourceModal, UpdateResultSuccessDialog } from 'shared/dist/components/generic-building-blocks/UserDialogs';
import $ from 'jquery';

export const Clients = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [clients, setClients] = useState([]);

    useEffect(()=>{
        AOS.init();  
    }, []);

    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(12);

    useEffect(()=>{
        setPageSize(12);
        setSearchBoxValue('');
        loadData('', currentPage);
    }, [currentPage]);

    const loadData = (searchBy, page) => {
        get_ajax("GlobalAdmin/Clients/GetClients?searchBy=" + searchBy + "&page=" + page, (response) =>{
            setClients(response);
            setIsLoading(false);
        });
    }   

    return(
        <>
            <br/>
            <div className='row'>
                <div className='col-md-8'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Clienti</h4>                                              
                        <br/>
                        <SearchBox 
                            value={searchBoxValue} 
                            placeholder="Cauta" 
                            onChange={(e) =>{ loadData(e.target.value); setSearchBoxValue(e.target.value); }}
                        />
                    </LayoutCard>
                    {isLoading && (
                        <>
                            <br/>
                            <LoadingCircleComponent/>
                        </>
                    )}
                    {!isLoading && (
                        <>
                            {clients.length == 0 && (
                                <><br/><p style={{textAlign: 'center'}}>Nu exista cereri de examen medical.</p></>
                            )}   
                            {clients.length != 0 && (
                                <div className="row">
                                    {clients.map((e, i) => 
                                        <div className="col-md-4" key={i}>
                                            <Link to={`/global_admin/clients/details/${e.id}`}>                                            
                                                <LayoutCard                                        
                                                    className="layout_card1" key={i} 
                                                    onClick={()=>{ }}
                                                >
                                                    <div style={{textAlign: 'center'}}>                                                                                                            
                                                        <h6>{`${e.clientName}`}</h6>
                                                        <label>
                                                            {e.host}
                                                        </label>
                                                        <br/>                                          
                                                    </div>
                                                </LayoutCard>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            )}                                                                                            
                            <ul className="pagination">
                                <li className="page-item">
                                    <button 
                                        disabled={currentPage <= 0} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage-1)}}
                                        style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                    >
                                        Inapoi
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        className="page-link btn no-outline"
                                        style={{cursor: "auto", borderRadius: 0}}
                                    >
                                        {currentPage+1}
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        disabled={clients.length < pageSize} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage+1)}}
                                        style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                    >
                                        Inainte
                                    </button>
                                </li>
                            </ul>
                        </>
                    )}
                </div>
                <div className='col-md-4'>
                    <RoundRedirectButton
                        className="custom-btn-primary"
                        text="Adauga client"
                        redirectUrl={'/global_admin/clients/new'}
                    />
                    <hr/>
                    <RoundButton
                        className={"custom-btn-primary"}
                        text={"Rebuild translations (fake)"}
                        onClickHandler={()=>{
                            post("GlobalAdmin/Clients/RebuildTranslationsData", {}, (e)=>{
                                console.log(e);
                                alert(e);
                            })
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export const ClientCreate = () => {

    const [newClientName, setNewClientName] = useState('');
    const [newClientHostname, setNewClientHostname] = useState('');

    const [newAccountPassword, setNewAccountPassword] = useState('');

    const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState(false);
    const [redirectToResource, setRedirectToResource] = useState('');

    return (
        <>
            <br/>
            <div className='row'>
                <div className='col-md-8'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Adauga client nou</h4>                                              
                        <br/>
                        <FormGroup
                            label={'Nume client'}     
                            value={newClientName}
                            onChange={(e) => {setNewClientName(e.target.value)}}
                        />
                        <FormGroup
                            label={'Subdomeniu'}
                            value={newClientHostname}                       
                            onChange={(e) => {setNewClientHostname(e.target.value)}}
                        />
                        <hr/>
                        <FormGroup
                            type='password'
                            label={'Parola cont client'}
                            value={newAccountPassword}                       
                            onChange={(e) => {setNewAccountPassword(e.target.value)}}
                        />
                        <br/>
                        <div className='row'> 
                            <div className='col-md-4'>
                                <RoundButton
                                    className="custom-btn-primary"
                                    text="Adauga client"
                                    onClickHandler={()=>{
                                        post('GlobalAdmin/Clients/CreateNewClient', {
                                            'name': newClientName,
                                            'hostname': newClientHostname
                                        }, (client_id) => {
                                            $.ajax({
                                                type: "GET",
                                                url: 'get_identity_authority',  
                                                headers: 'Access-Control-Allow-Headers: *',
                                                async: true,
                                                success: function (redirectUrl) {
                                                    redirectUrl = redirectUrl + "/AccountManager/CreateAdminUser";
                                                    post(redirectUrl, {                                                        
                                                        'password': newAccountPassword, 
                                                        'clientId': client_id,                                                         
                                                    }, (response)=>{
                                                        if(response == "error"){
                                                            alert("Contul de administrator nu a putut fi adaugat"); return;
                                                        }      
                                                        setDisplayConfirmationDialog(true);
                                                    })
                                                }
                                            });
                                        });
                                    }}
                                />  
                            </div>
                        </div>                        
                    </LayoutCard>
                </div>
            </div>

            <UpdateResultSuccessDialog
                isOpen={displayConfirmationDialog}
                toggle={()=>{setDisplayConfirmationDialog(false)}}
                onConfirm={()=>{setRedirectToResource('/global_admin/clients')}}
            />
            {redirectToResource != '' && (
                <Redirect to={redirectToResource}/>
            )}
        </>
    );
}

export const ClientDetails = (props) => {
    const [clientLink, setClientLink] = useState(undefined);
    useEffect(()=>{
        if (props.match.params.client_id != undefined) {
            setClientLink(props.match.params.client_id);
        }
    }, [props.match.params.client_id]);  
    
    const [clientName, setClientName] = useState(undefined);
    const [clientThemeName, setClientThemeName] = useState(undefined);

    useEffect(()=>{
        if(clientLink === undefined) return;
        load_data();
    }, [clientLink]);
    const load_data = () => {
        get_ajax("GlobalAdmin/Clients/GetClientDetails?clientId=" + clientLink, (response) => {
            setClientName(response.clientName);
            const jsonClientConfig = JSON.parse(response.jsonClientConfig);
            setClientThemeName(jsonClientConfig.theme_name);
        });
    }

    const [selectedThemeId, setSelectedThemeId] = useState(undefined);
    const [themes, setThemes] = useState([]);
    useEffect(()=>{
        get_ajax('GlobalAdmin/Themes/GetThemes', (response) => {
            setThemes(response);
        });
    }, []);

    const [isSelectThemeDialogOpen, setIsSelectDialogOpen] = useState(false);
    if(clientName === undefined) return (<LoadingCircleComponent/>);
    return (
        <>
            <br/>
            <div className='row'>
                <div className='col-md-8'> 
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>{clientName}</h4>                                                                      
                    </LayoutCard>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div className='row'>
                            <div className='col-md-8'>
                                {clientThemeName === undefined && (<p>Nu ati selectat nicio tema.</p>)}
                                {clientThemeName !== undefined && (<p>Tema client: <b>{clientThemeName}</b></p>)}
                            </div>
                            <div className='col-md-4'>
                                <RoundButton
                                    className={'btn-secondary'}
                                    text={'Alege tema'}
                                    onClickHandler={() => { setIsSelectDialogOpen(true); }}
                                />
                            </div>
                        </div>  
                    </LayoutCard>
                </div>
            </div>

            <CustomResourceModal
                size={'lg'}
                isOpen={isSelectThemeDialogOpen}
                toggle={() => setIsSelectDialogOpen(false)}
                title={"Alege tema"}
            >
                <div className='row'>
                    {themes.map((theme, i) => 
                        <div className='col-md-3' key={i}>
                            <RoundButton
                                text={theme.themeName}
                                className={selectedThemeId === theme.id ? "custom-btn-primary" : "btn-secondary"}
                                onClickHandler={()=>{
                                    setSelectedThemeId(theme.id);
                                }}
                            />
                        </div>
                    )}
                </div>
                <hr/>
                <RoundButton
                    className="custom-btn-primary"
                    text={'Salveaza'}
                    onClickHandler={() => {
                        post('GlobalAdmin/Clients/SetClientTheme',
                        {
                            'clientId': clientLink,
                            'themeId': selectedThemeId,
                        }, (response) => {
                            if(response === false)
                                alert("Eroare la salvare.");
                            load_data();
                            setIsSelectDialogOpen(false);
                        });
                    }}
                />
            </CustomResourceModal>
        </>
    );
}