import React from 'react';
import {FaDatabase, FaWpforms} from "react-icons/fa";
import {FiUsers} from 'react-icons/fi';
import {IoDocumentTextOutline} from "react-icons/io5";
import {AiOutlineInteraction} from 'react-icons/ai';
import {VscSymbolMisc} from "react-icons/vsc";
import  {MdWeb} from 'react-icons/md';
import {configurableFieldType} from 'shared/dist/constants/constants';

export const sideOptionsList = [
    {name: "Modele", icon: <FaDatabase/>, key: "models"},
    {name: "Forme", icon: <FaWpforms/>, key: "forms"},
    // {name: "Pagini", icon: <MdWeb/>, key: "pages"},
    {name: "Documente", icon: <IoDocumentTextOutline/>, key: "documents"},
    {name: "Actiuni", icon: <AiOutlineInteraction/>, key: "actions"},
    {name: "Roluri CIM", icon: <FiUsers/>, key: "roles"},
    // {name: "Altele", icon: <VscSymbolMisc/>},
];

export const skipDefaultNames = [
    'Angajat',
    'CIM',
    // 'Decizie',
    // 'ActAditional',
    // 'Notificare',
    // 'Demisie',
    // 'Spor',
    // 'Indemnizatie'
];
export const configurableModels = {
    employee: 0,
    cim: 1,
    decizie: 2,
    actAditional: 3,
    notificare: 4,
    demisie: 5,
    spor: 6,
    indemnizatie: 7
}

export const configurableModelsList = [
    {
        name: 'Angajat',
        description: 'Configureaza informatiile asociate unui angajat din compania dvs.',
        type: configurableModels.employee
    },
    {
        name: 'Contract de munca',
        description: 'Configureaza informatiile asociate unui contract individual de munca.',
        type: configurableModels.cim
    },
    // {
    //     name: 'Decizie',
    //     description: 'Configureaza informatiile asociate unei decizii pentru un CIM.',
    //     type: configurableModels.decizie
    // },
    // {
    //     name: 'Act aditional',
    //     description: 'Configureaza informatiile asociate unui act aditional pentru un CIM.',
    //     type: configurableModels.actAditional
    // },
    // {
    //     name: 'Notificare',
    //     description: 'Configureaza informatiile asociate unei notificari.',
    //     type: configurableModels.notificare
    // },
    // {
    //     name: 'Demisie',
    //     description: 'Configureaza informatiile asociate unei demisii.',
    //     type: configurableModels.demisie
    // },
    // {
    //     name: 'Spor',
    //     description: 'Configureaza informatiile asociate unui spor.',
    //     type: configurableModels.spor
    // },
    // {
    //     name: 'Indemnizatie',
    //     description: 'Configureaza informatiile asociate unei indemnizatii.',
    //     type: configurableModels.indemnizatie
    // }
]

export const ruleBlockTypes = {
    ruleType: 0,
    condition : 1,
    setValue: 2
}
export const ruleTypes = {
    undefined: -1,
    value: 0,
    visibility: 1,
    listOptions: 2
}
export const defaultRuleDetails = [
    {
        type: ruleBlockTypes.ruleType,
        value: ruleTypes.undefined
    },
    {
        type: ruleBlockTypes.condition,
        value: undefined
    }
    // , {
    //     type: ruleBlockTypes.setValue,
    //     value: undefined
    // }
];

export const actionTypes = {
    updateObject: 0,
    generateDocument: 1,
    editForm: 2
};