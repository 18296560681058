import React, { useEffect, useState } from 'react';
import {get_ajax, post} from 'shared/dist/utils/DataProvider';
import {SearchBox} from 'shared/dist/components/generic-building-blocks/SearchBox';
import {RoundButton, RoundRedirectButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {LayoutCard} from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {Link, Redirect} from 'react-router-dom';
import {FormGroup} from 'shared/dist/components/generic-building-blocks/FormGroup';
import {DeleteConfirmationDialog, UpdateResultSuccessDialog, CreateResourceDialog, EditResourceDialog} from 'shared/dist/components/generic-building-blocks/UserDialogs';

import AOS from 'aos';
import "aos/dist/aos.css";

function isEmptyOrSpaces(str){
    return str === null || str.match(/^ *$/) !== null;
}

export const Angajatori = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [angajatori, setAngajatori] = useState([]);

    useEffect(()=>{  
        AOS.init();        
        get_ajax("CompanyConfig/Angajatori/GetAngajatori", (response) =>{
            setAngajatori(response);
            setIsLoading(false);
        });
    }, []);

    return(
        <>
            <h4>Angajatori</h4>
            <br/>
            <div className="form-group row">
                <div className="col-md-6">
                    <SearchBox placeholder="Cauta"/>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3">
                    <RoundRedirectButton 
                        className="custom-btn-primary"
                        text="Angajator nou"
                        redirectUrl="/angajatori/new"
                    />
                </div>
            </div>
            <br/>
            {isLoading && (
                <>
                    <br/>
                    <LoadingCircleComponent/>
                </>
            )}
            {!isLoading && (
                <>
                <div className="row">
                    {angajatori.map((e, i) =>
                        <div className="col-md-4" key={i}>
                            <Link to = {"/angajatori/details/" + e.id}>
                                <div
                                    data-aos="flip-up"
                                    data-aos-delay={parseInt((i / 3)) % 2 == 0 ? (i % 3) * 50 : (2-(i % 3)) * 50}
                                >
                                    <LayoutCard className="layout_card1">                                    
                                        <h5>{e.nume}</h5>
                                        <h6>{e.reprezentantLegal}</h6>
                                        <p style={{marginBottom: '0'}}>
                                            {e.adresa}
                                            <br/>
                                            {e.telefon}
                                        </p>                                                                         
                                    </LayoutCard>
                                </div>
                            </Link>
                        </div>
                    )}                
                </div>
                </>
            )}
            {!isLoading && angajatori.length == 0 && (
                <><br/><p style={{textAlign: 'center'}}>Nu exista angajatori.</p></>
            )}
        </>
    );
}

export const AngajatoriNew = () =>{
    const [nume, setNume] = useState('');
    const [adresa, setAdresa] = useState('');
    const [telefon, setTelefon] = useState('');
    const [fax, setFax] = useState('');
    const [reprezentantLegal, setReprezentantLegal] = useState('');
    const [caen, setCaen] = useState('');    
    
    const [numeInvalidMsg, setNumeInvalidMsg] = useState('');
    const [adresaInvalidMsg, setAdresaInvalidMsg] = useState('');
    const [telefonInvalidMsg, setTelefonInvalidMsg] = useState('');
    const [reprezentantLegalInvalidMsg, setReprezentantLegalInvalidMsg] = useState('');
    const [caenInvalidMsg, setCaenInvalidMsg] = useState('');

    const [redirectToResource, setRedirectToResource] = useState('');    

    const addNewAngajator = () =>{
        let isOkay = true;
        if(isEmptyOrSpaces(nume)){
            isOkay = false;
            setNumeInvalidMsg("Numele este obligatoriu");
        }
        if(isEmptyOrSpaces(adresa)){
            isOkay = false;
            setAdresaInvalidMsg("Adresa este obligatorie");
        }
        if(isEmptyOrSpaces(telefon)){
            isOkay = false;
            setTelefonInvalidMsg("Telefonul este obligatoriu");
        }
        if(isEmptyOrSpaces(reprezentantLegal)){
            isOkay = false;
            setReprezentantLegalInvalidMsg("Reprezentantul legal este obligatoriu");
        }
        if(isEmptyOrSpaces(caen)){
            isOkay = false;
            setCaenInvalidMsg("Codul CAEN este obligatoriu");
        }
        if(!isOkay)return;
        post('CompanyConfig/Angajatori/CreateAngajator', {
            "nume": nume, 
            "adresa": adresa, 
            "telefon": telefon, 
            "fax": fax,
            "reprezentant_legal": reprezentantLegal, 
            "caen":caen
        }, (response)=>{
            if(response == "invalid_request")
            {
                alert("Eroare la adaugare");
                return;
            }
            setRedirectToResource("/angajatori/details/" + response);
        });
    }

    return(
        <>
            {redirectToResource != '' && (
                <Redirect to={redirectToResource}/>
            )}
            <h4><Link className="redirectNavigationLink" to='/angajatori'>Angajatori</Link> &gt; Angajator nou</h4>
            <br/>
            <h6>Date identificare</h6>
            <div className="row">
                <div className="col-md-6">
                    <FormGroup 
                        label={'Nume companie'}
                        value={nume}      
                        onChange={(e) =>{ setNume(e.target.value); }}      
                        use_validation={numeInvalidMsg != ''}            
                        is_valid = {numeInvalidMsg == ''}
                        invalid_feedback = {numeInvalidMsg}
                    />
                    <FormGroup 
                        label={'Adresa'}
                        value={adresa}      
                        onChange={(e) =>{ setAdresa(e.target.value); }}          
                        use_validation={adresaInvalidMsg != ''}            
                        is_valid = {adresaInvalidMsg == ''}
                        invalid_feedback = {adresaInvalidMsg}        
                    />
                    <FormGroup 
                        label={'Telefon'}
                        value={telefon}      
                        onChange={(e) =>{ setTelefon(e.target.value); }}     
                        use_validation={telefonInvalidMsg != ''}            
                        is_valid = {telefonInvalidMsg == ''}
                        invalid_feedback = {telefonInvalidMsg}             
                    />
                    <FormGroup 
                        label={'Fax'}
                        value={fax}
                        onChange={(e) =>{ setFax(e.target.value); }}                             
                    />
                    <FormGroup 
                        label={'Reprezentant legal'}
                        value={reprezentantLegal}      
                        onChange={(e) =>{ setReprezentantLegal(e.target.value); }}           
                        use_validation={reprezentantLegalInvalidMsg != ''}            
                        is_valid = {reprezentantLegalInvalidMsg == ''}
                        invalid_feedback = {reprezentantLegalInvalidMsg}       
                    />
                    <FormGroup 
                        label={'Cod CAEN'}
                        value={caen}      
                        onChange={(e) =>{ setCaen(e.target.value); }}          
                        use_validation={caenInvalidMsg != ''}            
                        is_valid = {caenInvalidMsg == ''}
                        invalid_feedback = {caenInvalidMsg}        
                    />
                    <br/>
                    <div className="row">
                    <div className="col-md-3">
                            <RoundButton
                                className="custom-btn-primary"
                                text="Adauga"
                                onClickHandler={addNewAngajator}
                            />
                        </div>
                        <div className="col-md-9"></div>                        
                    </div>
                </div>
            </div>
        </>
    );
}

export const AngajatoriDetails = (props) =>{
    const [isLoading, setIsLoading] = useState(true);        

    const [nume, setNume] = useState('');
    const [adresa, setAdresa] = useState('');
    const [telefon, setTelefon] = useState('');
    const [fax, setFax] = useState('');
    const [reprezentantLegal, setReprezentantLegal] = useState('');
    const [caen, setCaen] = useState('');    
    
    const [numeInvalidMsg, setNumeInvalidMsg] = useState('');
    const [adresaInvalidMsg, setAdresaInvalidMsg] = useState('');
    const [telefonInvalidMsg, setTelefonInvalidMsg] = useState('');
    const [reprezentantLegalInvalidMsg, setReprezentantLegalInvalidMsg] = useState('');
    const [caenInvalidMsg, setCaenInvalidMsg] = useState('');

    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [redirectToResource, setRedirectToResource] = useState('');
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

    useEffect(()=>{
        if (props.match.params.angajator_id != undefined) {
            load_resources();
        }
    }, [props.match.params.angajator_id]);    

    const load_resources = () =>{        
        get_ajax("CompanyConfig/Angajatori/GetAngajator?id=" + props.match.params.angajator_id, 
            (response) =>{                
                setNume(response.nume);
                setAdresa(response.adresa);
                setTelefon(response.telefon);
                setFax(response.fax);
                setReprezentantLegal(response.reprezentantLegal);
                setCaen(response.codCAEN);

                setIsLoading(false);                
            }
        );
    }    

    const saveEdits = () =>{
        let isOkay = true;
        if(isEmptyOrSpaces(nume)){
            isOkay = false;
            setNumeInvalidMsg("Numele este obligatoriu");
        }
        if(isEmptyOrSpaces(adresa)){
            isOkay = false;
            setAdresaInvalidMsg("Adresa este obligatorie");
        }
        if(isEmptyOrSpaces(telefon)){
            isOkay = false;
            setTelefonInvalidMsg("Telefonul este obligatoriu");
        }
        if(isEmptyOrSpaces(reprezentantLegal)){
            isOkay = false;
            setReprezentantLegalInvalidMsg("Reprezentantul legal este obligatoriu");
        }
        if(isEmptyOrSpaces(caen)){
            isOkay = false;
            setCaenInvalidMsg("Codul CAEN este obligatoriu");
        }
        if(!isOkay)return;
        post('CompanyConfig/Angajatori/UpdateAngajator', {
            "id": props.match.params.angajator_id,
            "nume": nume, 
            "adresa": adresa, 
            "telefon": telefon, 
            "fax": fax,
            "reprezentant_legal": reprezentantLegal, 
            "caen":caen
        }, (response)=>{
            if(response == "invalid_request")
            {
                alert("Eroare la adaugare");
                return;
            }
            setIsConfirmationDialogOpen(true);            
        });
    }

    return (
        <>
            <h4><Link className="redirectNavigationLink" to='/angajatori'>Angajator</Link> &gt; {nume}</h4>
            <br/>
            {isLoading && (
                <>                    
                    <LoadingCircleComponent/>
                </>
            )}
            {!isLoading && (
                <>                
                    <div className="row">
                        <div className="col-md-8">
                            <h6>Date identificare</h6>
                            <FormGroup 
                                label={'Nume companie'}
                                value={nume}      
                                onChange={(e) =>{ setNume(e.target.value); setIsSaveEnabled(true); }}      
                                use_validation={numeInvalidMsg != ''}            
                                is_valid = {numeInvalidMsg == ''}
                                invalid_feedback = {numeInvalidMsg}
                            />
                            <FormGroup 
                                label={'Adresa'}
                                value={adresa}      
                                onChange={(e) =>{ setAdresa(e.target.value); setIsSaveEnabled(true); }}          
                                use_validation={adresaInvalidMsg != ''}            
                                is_valid = {adresaInvalidMsg == ''}
                                invalid_feedback = {adresaInvalidMsg}        
                            />
                            <FormGroup 
                                label={'Telefon'}
                                value={telefon}      
                                onChange={(e) =>{ setTelefon(e.target.value); setIsSaveEnabled(true); }}     
                                use_validation={telefonInvalidMsg != ''}            
                                is_valid = {telefonInvalidMsg == ''}
                                invalid_feedback = {telefonInvalidMsg}             
                            />
                            <FormGroup 
                                label={'Fax'}
                                value={fax}      
                                onChange={(e) =>{ setFax(e.target.value); setIsSaveEnabled(true); }}     
                            />
                            <FormGroup 
                                label={'Reprezentant legal'}
                                value={reprezentantLegal}      
                                onChange={(e) =>{ setReprezentantLegal(e.target.value); setIsSaveEnabled(true); }}           
                                use_validation={reprezentantLegalInvalidMsg != ''}            
                                is_valid = {reprezentantLegalInvalidMsg == ''}
                                invalid_feedback = {reprezentantLegalInvalidMsg}       
                            />
                            <FormGroup 
                                label={'Cod CAEN si domeniu de activitate'}
                                value={caen}      
                                onChange={(e) =>{ setCaen(e.target.value); setIsSaveEnabled(true); }}          
                                use_validation={caenInvalidMsg != ''}            
                                is_valid = {caenInvalidMsg == ''}
                                invalid_feedback = {caenInvalidMsg}        
                            />
                            <br/>
                            <div className="row">
                            <div className="col-md-3">
                                    <RoundButton
                                        disabled={!isSaveEnabled}
                                        className={isSaveEnabled ? "custom-btn-primary" : "custom-btn-primary disabled"}
                                        text="Salveaza"
                                        onClickHandler={saveEdits}
                                    />
                                </div>
                                <div className="col-md-6"></div>                        
                                <div className="col-md-3">
                                    <RoundButton                                        
                                        className={"btn-danger"}
                                        text="Sterge"
                                        onClickHandler={()=>{setIsDeleteDialogOpen(true);}}
                                    />
                                </div>
                            </div>
                        </div>                        
                    </div>
                </>
            )}
            <>                
                <DeleteConfirmationDialog
                    isOpen={isDeleteDialogOpen}
                    toggle={()=>{setIsDeleteDialogOpen(!isDeleteDialogOpen)}}
                    onConfirm={()=>{
                        post('CompanyConfig/Angajatori/RemoveAngajator', {
                            "id": props.match.params.angajator_id
                        }, (response)=>{
                            if(response == false)
                            {
                                alert("Eroare la adaugare");
                                return;
                            }
                            setRedirectToResource("/angajatori");
                        });
                    }}
                />
                <UpdateResultSuccessDialog
                    isOpen={isConfirmationDialogOpen}
                    toggle={()=>{setIsConfirmationDialogOpen(!isConfirmationDialogOpen)}}
                    onConfirm={load_resources}
                />
                {redirectToResource != '' && (
                    <Redirect to={redirectToResource}/>
                )}
            </>
        </>
    );
}