import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import { SearchBox } from 'shared/dist/components/generic-building-blocks/SearchBox';
import { get_ajax, post } from 'shared/dist/utils/DataProvider';
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import { Link, Redirect } from 'react-router-dom';
import { RoundButton, RoundRedirectButton } from 'shared/dist/components/generic-building-blocks/RoundButton';
import { FormGroup } from 'shared/dist/components/generic-building-blocks/FormGroup';
import { CustomResourceModal, UpdateResultSuccessDialog } from 'shared/dist/components/generic-building-blocks/UserDialogs';
import $ from 'jquery';
import {MdOutlineRemoveCircle} from 'react-icons/md';

function getBase64(file, valueSetter) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        valueSetter(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
}

export const Doctors = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [clients, setClients] = useState([]);

    useEffect(()=>{
        AOS.init();  
    }, []);

    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(12);

    useEffect(()=>{
        setPageSize(12);
        setSearchBoxValue('');
        loadData('', currentPage);
    }, [currentPage]);

    const loadData = (searchBy, page) => {
        get_ajax("GlobalAdmin/Doctors/GetClients?searchBy=" + searchBy + "&page=" + page, (response) =>{
            setClients(response);
            setIsLoading(false);
        });
    }  

    return(
        <>
            <br/>
            <div className='row'>
                <div className='col-md-8'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Doctori</h4>                                              
                        <br/>
                        <SearchBox 
                            value={searchBoxValue} 
                            placeholder="Cauta" 
                            onChange={(e) =>{ loadData(e.target.value); setSearchBoxValue(e.target.value); }}
                        />
                    </LayoutCard>
                    {isLoading && (
                        <>
                            <br/>
                            <LoadingCircleComponent/>
                        </>
                    )}
                    {!isLoading && (
                        <>
                            {clients.length == 0 && (
                                <><br/><p style={{textAlign: 'center'}}>Nu exista cereri de examen medical.</p></>
                            )}   
                            {clients.length != 0 && (
                                <div className="row">
                                    {clients.map((e, i) => 
                                        <div className="col-md-4" key={i}>
                                            <Link to={`/global_admin/doctors/details/${e.id}`}>                                            
                                                <LayoutCard                                        
                                                    className="layout_card1" key={i} 
                                                    onClick={()=>{ }}
                                                >
                                                    <div style={{textAlign: 'center'}}>                                                                                                            
                                                        <h6>{`${e.userName}`}</h6>
                                                        <label>
                                                            {`${e.nume} ${e.prenume}`}
                                                        </label><br/>
                                                        <label>{e.medicClinit}</label>
                                                        <br/>                                          
                                                    </div>
                                                </LayoutCard>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            )}                                                                                            
                            <ul className="pagination">
                                <li className="page-item">
                                    <button 
                                        disabled={currentPage <= 0} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage-1)}}
                                        style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                    >
                                        Inapoi
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        className="page-link btn no-outline"
                                        style={{cursor: "auto", borderRadius: 0}}
                                    >
                                        {currentPage+1}
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        disabled={clients.length < pageSize} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage+1)}}
                                        style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                    >
                                        Inainte
                                    </button>
                                </li>
                            </ul>
                        </>
                    )}
                </div>
                <div className='col-md-4'>
                    <RoundRedirectButton
                        className="custom-btn-primary"
                        text="Adauga doctor"
                        redirectUrl={'/global_admin/doctors/new'}
                    />
                </div>
            </div>
        </>
    );
}

export const DoctorCreate = () => {
    const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState(false);
    const [redirectToResource, setRedirectToResource] = useState('');

    const [nume, setNume] = useState('');
    const [prenume, setPrenume] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [username, setUsername] = useState('');

    const [numeClinica, setNumeClinica] = useState('');
    const [adresaClinica, setAdresaClinica] = useState('');
    const [telefonClinica, setTelefonClinica] = useState('');

    const [userPassword, setUserPassword] = useState('');

    return (
        <>
            <br/>
            <div className='row'>
                <div className='col-md-8'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Adauga client nou</h4>                                              
                        <br/>
                        <FormGroup
                            label={'Nume'}     
                            value={nume}
                            onChange={(e) => {setNume(e.target.value)}}
                        />
                        <FormGroup
                            label={'Prenume'}
                            value={prenume}                       
                            onChange={(e) => {setPrenume(e.target.value)}}
                        />
                        <FormGroup
                            label={'Nume utilizator'}
                            value={username}                       
                            onChange={(e) => {setUsername(e.target.value)}}
                        />
                        <FormGroup
                            label={'Adresa email'}
                            value={emailAddress}                       
                            onChange={(e) => {setEmailAddress(e.target.value)}}
                        />
                        <hr/>
                        <FormGroup
                            label={'Nume clinica'}
                            value={numeClinica}                       
                            onChange={(e) => {setNumeClinica(e.target.value)}}
                        />
                        <FormGroup
                            label={'Adresa'}
                            value={adresaClinica}                       
                            onChange={(e) => {setAdresaClinica(e.target.value)}}
                        />
                        <FormGroup
                            label={'Telefon'}
                            value={telefonClinica}                       
                            onChange={(e) => {setTelefonClinica(e.target.value)}}
                        />
                        <hr/>
                        <FormGroup
                            type='password'
                            label={'Parola cont client'}
                            value={userPassword}                       
                            onChange={(e) => {setUserPassword(e.target.value)}}
                        />
                        <br/>
                        <div className='row'> 
                            <div className='col-md-4'>
                                <RoundButton
                                    className="custom-btn-primary"
                                    text="Adauga client"
                                    onClickHandler={()=>{
                                        $.ajax({
                                            type: "GET",
                                            url: 'get_identity_authority',  
                                            headers: 'Access-Control-Allow-Headers: *',
                                            async: true,
                                            success: function (redirectUrl) {
                                                redirectUrl = redirectUrl + "/AccountManager/CreateDoctorUser";
                                                post(redirectUrl, {                                                        
                                                    'nume': nume, 
                                                    'prenume': prenume, 
                                                    'username': username, 
                                                    'email': emailAddress,  
                                                    'password': userPassword
                                                }, (response)=>{
                                                    if(response == "error"){
                                                        alert("Contul de doctor nu a putut fi adaugat"); return;
                                                    }      
                                                    post('GlobalAdmin/doctors/CreateNewDoctor', {
                                                        'numeUnitateMedicala': numeClinica,
                                                        'adresaUnitateMedicala': adresaClinica,
                                                        'telefonUnitateMedicala': telefonClinica,
                                                        'userId': response
                                                    }, (result) => {
                                                        if(result == false) {alert("Eroare la adaugarea unitatii medicale"); return; }
                                                        setDisplayConfirmationDialog(true);
                                                    });
                                                })
                                            }
                                        });
                                    }}
                                />  
                            </div>
                        </div>                        
                    </LayoutCard>
                </div>
            </div>

            <UpdateResultSuccessDialog
                isOpen={displayConfirmationDialog}
                toggle={()=>{setDisplayConfirmationDialog(false)}}
                onConfirm={()=>{setRedirectToResource('/global_admin/doctors')}}
            />
            {redirectToResource != '' && (
                <Redirect to={redirectToResource}/>
            )}
        </>
    );
}

export const DoctorDetails = (props) => {
    const [isLoading,setIsLoading] = useState(true); 
    const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState(false);

    useEffect(()=>{
        if (props.match.params.client_id != undefined) {
            load_resources();
            load_client_links();
            load_theme_data();
        }
    }, [props.match.params.client_id]);  

    const [nume, setNume] = useState('');
    const [prenume, setPrenume] = useState('');

    const [numeUnitateMedicala, setNumeUnitateMedicala] = useState('');
    const [adresaUnitateMedicala, setAdresaUnitateMedicala] = useState('');
    const [telefonUnitateMedicala, setTelefonUnitateMedicala] = useState('');

    const [base64Sign, setBase64Sign] = useState(undefined);
    const [signatureUploadModalVisible, setSignatureUploadModalVisible] = useState(false);    
    const singatureFileInputChanged = (e) => {
        if(e.target.files[0] !== undefined)
        getBase64(e.target.files[0], setBase64Sign);
        else
            setBase64Sign(undefined);
    }

    const load_resources = () => {
        get_ajax("GlobalAdmin/Doctors/GetDoctorDetails?doctorId="+props.match.params.client_id, (response) =>{            
            setNume(response.nume);
            setPrenume(response.prenume);
            response.unitateMedicala = JSON.parse(response.unitateMedicala);
            setNumeUnitateMedicala(response.unitateMedicala.NumeUnitateMedicala);
            setAdresaUnitateMedicala(response.unitateMedicala.AdresaUnitateMedicala);
            setTelefonUnitateMedicala(response.unitateMedicala.TelefonUnitateMedicala);
            setBase64Sign(response.signature);
            setIsLoading(false);
        });
    }
    
    const [addDoctorClientDialogOpen, setAddDoctorClientDialogOpen] = useState(false);
    const [doctorClientLinks, setDoctorClientLinks] = useState([]);
    const load_client_links = () => {
        get_ajax("GlobalAdmin/Doctors/GetDoctorClients?doctorId="+props.match.params.client_id, (response) =>{            
            setDoctorClientLinks(response);
        });
    }

    const [selectedThemeId, setSelectedThemeId] = useState(undefined);
    const [themes, setThemes] = useState([]);
    useEffect(()=>{
        get_ajax('GlobalAdmin/Themes/GetThemes', (response) => {
            setThemes(response);
        });
    }, []); 
    const [isSelectThemeDialogOpen, setIsSelectDialogOpen] = useState(false);
    const [clientThemeName, setClientThemeName] = useState(undefined);
    const load_theme_data = () => {
        get_ajax("GlobalAdmin/Doctors/GetDoctorTheme?doctorId="+props.match.params.client_id, (response) =>{            
            const jsonClientConfig = JSON.parse(response);
            setClientThemeName(jsonClientConfig.theme_name);
        });
    }
    return (
        <>
            <br/>
            <div className='row'>
                <div className='col-md-8'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>Detalii doctor SSM</h4>        
                        <br/>
                        {isLoading && <LoadingCircleComponent/>}
                        {!isLoading && (<>
                            <FormGroup
                                value={nume}
                                label={'Nume'}
                                onChange={(e) => setNume(e.target.value)}
                            />
                            <FormGroup
                                value={prenume}
                                label={'Prenume'}
                                onChange={(e) => setPrenume(e.target.value)}
                            />
                            <hr/>
                            <FormGroup
                                value={numeUnitateMedicala}
                                label={'Nume unitate medicala'}
                                onChange={(e) => setNumeUnitateMedicala(e.target.value)}
                            />
                            <FormGroup
                                value={adresaUnitateMedicala}
                                label={'Adresa unitate medicala'}
                                onChange={(e) => setAdresaUnitateMedicala(e.target.value)}
                            />
                            <FormGroup
                                value={telefonUnitateMedicala}
                                label={'Telefon unitate medicala'}
                                onChange={(e) => setTelefonUnitateMedicala(e.target.value)}
                            />            
                            <hr/>
                            <label>Semnatura:</label>
                            <div className='form-group'>
                                {(base64Sign === 'none' || base64Sign === undefined) && (
                                    <>
                                        <p style={{textAlign: 'center'}}>Nu exista semnatura atasata.</p>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                <RoundButton
                                                    className={"custom-btn-primary"}
                                                    text={'Adauga semnatura'}
                                                    onClickHandler={()=>setSignatureUploadModalVisible(true)}
                                                />
                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </>
                                )}
                                {base64Sign !== 'none' && base64Sign !== undefined && (
                                    <>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <img src={base64Sign} style={{width: '100%', border: '1px solid #2c3e50'}}/>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <RoundButton
                                                    className={"custom-btn-primary"}
                                                    text={'Modifica'}
                                                    onClickHandler={()=>setSignatureUploadModalVisible(true)}
                                                />
                                            </div>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                <RoundButton
                                                    className={"btn-danger"}
                                                    text={'Sterge'}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <hr/>
                            <div className='row'>
                                <div className='col-md-4'> 
                                    <RoundButton
                                        className="custom-btn-primary"
                                        text="Salveaza modificari"
                                        onClickHandler={()=>{
                                            post('GlobalAdmin/doctors/UpdateDoctor', {
                                                'userId': props.match.params.client_id,
                                                'nume': nume,
                                                'prenume': prenume,
                                                'numeUnitateMedicala': numeUnitateMedicala,
                                                'adresaUnitateMedicala': adresaUnitateMedicala,
                                                'telefonUnitateMedicala': telefonUnitateMedicala,
                                                'signatureBase64': base64Sign
                                            }, (result) => {
                                                if(result == false) {alert("Eroare la salvarea modificarilor"); return; }
                                                setDisplayConfirmationDialog(true);
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </>)}
                    </LayoutCard>
                </div>
                <div className='col-md-4'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div className='row'>
                            <div className='col-md-7'>
                                <h4>Clienti asociati</h4>
                            </div>
                            <div className='col-md-5'>
                                <RoundButton
                                    className="custom-btn-primary"
                                    text="Adauga client"
                                    onClickHandler={()=>{setAddDoctorClientDialogOpen(true);}}
                                />
                            </div>
                        </div>
                        <br/>   
                        <ul className="list-group">
                            {doctorClientLinks.map((l, i) => 
                                <li className="list-group-item" key={i}>
                                    <div>
                                        <b>{l.numeAngajator}</b><br/>  
                                        {l.numeSubunitate}
                                    </div>
                                    <MdOutlineRemoveCircle className='float-right' onClick={()=>{
                                        post('GlobalAdmin/Doctors/RemoveDoctorClientLink', {
                                            'doctorId': props.match.params.client_id,
                                            'angajatorId': l.idAngajator,
                                            'subunitateId': l.idSubunitate
                                        }, (response)=>{
                                            if(response == false) {alert("Eroare la stergere"); return;}
                                            load_client_links();
                                        })
                                    }}/>
                                </li>
                            )}  
                        </ul>  
                        {doctorClientLinks.length == 0 && (
                            <p style={{textAlign: 'center', marginBottom: '0'}}>Nu exista clienti asociati</p>
                        )} 
                    </LayoutCard>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div className='row'>
                            <div className='col-md-8'>
                                {clientThemeName === undefined && (<p>Nu ati selectat nicio tema.</p>)}
                                {clientThemeName !== undefined && (<p>Tema client: <b>{clientThemeName}</b></p>)}
                            </div>
                            <div className='col-md-4'>
                                <RoundButton
                                    className={'btn-secondary'}
                                    text={'Alege tema'}
                                    onClickHandler={() => { setIsSelectDialogOpen(true); }}
                                />
                            </div>
                        </div>  
                    </LayoutCard>
                </div>
            </div>
            <CustomResourceModal
                size={'md'}
                isOpen={signatureUploadModalVisible}
                toggle={() => setSignatureUploadModalVisible(false)}
                title={"Incarca semnatura"}
            >
                <form>
                    <div className="form-group">
                        <label>Ataseaza semnatura</label>
                        <input type="file" className="form-control-file" accept="image/*" onChange={singatureFileInputChanged}/>
                    </div>
                </form>
                <br/>
                <img src={base64Sign} style={{width: '100%', border: '1px solid #2c3e50'}}/>
            </CustomResourceModal>
            <UpdateResultSuccessDialog
                isOpen={displayConfirmationDialog}
                toggle={()=>{setDisplayConfirmationDialog(false)}}
                onConfirm={()=>{load_resources(); load_client_links();}}
            />
            {props.match.params.client_id !== undefined && (
                <AddClientToDoctorModal
                    doctorId={props.match.params.client_id}
                    isOpen={addDoctorClientDialogOpen}
                    onClose={(e) => {setAddDoctorClientDialogOpen(false)}}
                    reloadHandler={()=>{load_client_links();}}
                />
            )}
            <CustomResourceModal
                size={'lg'}
                isOpen={isSelectThemeDialogOpen}
                toggle={() => setIsSelectDialogOpen(false)}
                title={"Alege tema"}
            >
                <div className='row'>
                    {themes.map((theme, i) => 
                        <div className='col-md-3' key={i}>
                            <RoundButton
                                text={theme.themeName}
                                className={selectedThemeId === theme.id ? "custom-btn-primary" : "btn-secondary"}
                                onClickHandler={()=>{
                                    setSelectedThemeId(theme.id);
                                }}
                            />
                        </div>
                    )}
                </div>
                <hr/>
                <RoundButton
                    className="custom-btn-primary"
                    text={'Salveaza'}
                    onClickHandler={() => {
                        post('GlobalAdmin/Doctors/SetClientTheme',
                        {
                            'clientId': props.match.params.client_id,
                            'themeId': selectedThemeId,
                        }, (response) => {
                            if(response === false)
                                alert("Eroare la salvare.");
                            load_theme_data();
                            setIsSelectDialogOpen(false);
                        });
                    }}
                />
            </CustomResourceModal>
        </>
    );
}

const AddClientToDoctorModal = ({doctorId, isOpen, onClose, reloadHandler}) => {
    const [angajatori, setAngajatori] = useState([]);
    const [angajatorId, setAngajatorId] = useState(-1);
    const [subunitati, setSubunitati] = useState([]);
    const [subunitateId, setSubunitateId] = useState(-1);
    useEffect(()=>{
        get_ajax("GlobalAdmin/Doctors/GetAngajatori", (response) =>{            
            setAngajatori(response);
        });
    }, []);

    useEffect(() => {
        if(angajatorId == -1) return;
        get_ajax("GlobalAdmin/Doctors/GetSubunitati?angajatorId="+angajatorId, (response) =>{            
            setSubunitati(response);
        });
    }, [angajatorId]);

    return (
        <CustomResourceModal
            size={'md'}
            isOpen={isOpen}
            toggle={onClose}
            title={"Asociaza client"}
        >
            <div className='form-group'>
                <label>Angajator</label>
                <select 
                    className='form-control no-outline'
                    value={angajatorId}
                    onChange={(e) => setAngajatorId(e.target.value)}
                >
                    <option value={-1}>Alege</option>
                    {angajatori.map((a, i) => 
                        <option key={i} value={a.id}>{a.nume}</option>
                    )}
                </select>
            </div>
            <div className='form-group'>
                <label>Subunitate</label>
                <select
                    className='form-control no-outline'
                    value={subunitateId}
                    onChange={(e) => setSubunitateId(e.target.value)}
                >
                    <option value={-1}>Alege</option>
                    {subunitati.map((a, i) => 
                        <option key={i} value={a.id}>{a.nume}</option>
                    )}
                </select>
            </div>
            <div className='row'>
                <div className='col-md-4'>
                    <RoundButton
                        className="custom-btn-primary"
                        text="Adauga client"
                        onClickHandler={()=>{
                            if(angajatorId == -1 || subunitateId == -1) {return;}
                            post('GlobalAdmin/Doctors/AddDoctorClientLink', {
                                'doctorId': doctorId,
                                'angajatorId': angajatorId,
                                'subunitateId': subunitateId
                            }, (response)=>{
                                if(response == false) {alert("Eroare la adaugare"); return;}
                                reloadHandler();
                                onClose();
                            })
                        }}
                    />
                </div>
            </div>
        </CustomResourceModal>
    );
}