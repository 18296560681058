import React, { useEffect, useState } from 'react';
import { RoundButton } from 'shared/dist/components/generic-building-blocks/RoundButton';
import { get_ajax, post } from 'shared/dist/utils/DataProvider';

export const APIManager = () => {
    useEffect(()=>{
        loadTokens();
    }, []);
    const [tokens, setTokens] = useState([]);

    const loadTokens = () => {
        get_ajax('CompanyConfig/APIManager/GetAPITokens', (response) => {
            setTokens(response);
        });
    };

    return(
        <>
            <div className='row'>
                <div className='col-md-8'></div>
                <div className='col-md-4'>
                    <RoundButton 
                        className="custom-btn-primary"
                        text="Adauga API Token nou"
                        onClickHandler={()=>{
                            post('CompanyConfig/APIManager/CreateToken', {}, (response) => {loadTokens();});
                        }}
                    />
                </div>
            </div>
            <br/>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Created At</th>
                        <th scope="col">Token</th>
                    </tr>
                </thead>
                <tbody>
                    {tokens.map((token, index) => 
                        <tr key={index}>
                            <th scope="row">{index+1}</th>
                            <td>{token.createdAt}</td>
                            <td>{token.token}</td>
                        </tr>
                    )}                
                </tbody>
            </table>
        </>
    );
}