import React, { useEffect, useState } from 'react';
import {get_ajax, post} from 'shared/dist/utils/DataProvider';
import {SearchBox} from 'shared/dist/components/generic-building-blocks/SearchBox';
import {RoundButton, RoundRedirectButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import {LayoutCard} from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import $ from 'jquery';
import {FaUser}from 'react-icons/fa';
import {Link} from 'react-router-dom';
import AOS from 'aos';
import "aos/dist/aos.css";
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import { getAccessToken } from 'shared/dist/components/api-authorization/AuthService';
import { FormGroup, FormGroupArea } from 'shared/dist/components/generic-building-blocks/FormGroup';
import {MdOutlineRemoveCircle} from 'react-icons/md';
import {Redirect} from 'react-router-dom';
import {DeleteConfirmationDialog, UpdateResultSuccessDialog, CreateResourceDialog, EditResourceDialog, CustomResourceModal} from 'shared/dist/components/generic-building-blocks/UserDialogs';

function getBase64(file, valueSetter) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        valueSetter(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
 }

export const Conturi = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [conturi, setConturi] = useState([]);

    const [statistics, setStatistics] = useState(undefined);

    const loadStatistics = () =>{
        get_ajax("CompanyConfig/Conturi/GetConturiStatistica", (response) => {
            setStatistics(response);
        });
    }

    useEffect(()=>{  
        AOS.init();                
        loadStatistics();
        setPageSize(12);
    }, []);

    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(12);

    const loadData = (searchBy, page) =>{
        get_ajax("CompanyConfig/Conturi/GetConturi?searchBy=" + searchBy + "&page=" + page, (response) =>{     
            setConturi(response);
            setIsLoading(false);
        });
    }

    useEffect(()=>{
        setSearchBoxValue('');
        loadData('', currentPage);
    }, [currentPage]);

    const [uploadRevisalDbModalVisible, setUploadRevisalDbModalVisible] = useState(false);
    const [employeeBatchDataUpdateModalVisible, setEmployeeBatchDataUpdateModalVisible] = useState(false);
    const [uploadFiseAptitudiniModalVisible, setUploadFiseAptitudiniModalVisible] = useState(false);

    return(
        <>
            <h4>Conturi</h4>
            <br/>
            <div className="form-group row">
                <div className="col-md-6">
                    <SearchBox 
                        placeholder="Cauta"
                        value={searchBoxValue} 
                        onChange={(e) =>{ loadData(e.target.value); setSearchBoxValue(e.target.value); }}
                    />
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3">
                    <RoundRedirectButton 
                        className="custom-btn-primary"
                        text="Cont nou"
                        redirectUrl="/conturi/new"
                    />
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-md-8">
                    {isLoading && (
                        <>
                            <br/>
                            <LoadingCircleComponent/>
                        </>
                    )}
                    {!isLoading && (
                        <>
                            <div className="row">
                                {conturi.map((e, i) => 
                                    <div className="col-md-4" key={i}>
                                        <Link to = {e.emailConfirmed ? "/conturi/details/" + e.id : "/conturi"}>
                                            <LayoutCard                                        
                                                className={e.emailConfirmed ? "layout_card1" : "layout_card1 disabled_grayscale"}
                                                key={i}                                                 
                                            >
                                                <div style={{textAlign: 'center'}}>                                                    
                                                    <FaUser size={56} className="avatar_container"/>
                                                    <h6>{`${e.nume} ${e.prenume}`}</h6>
                                                    <label>
                                                        <b>{e.userName}</b>                                                                                                                
                                                    </label>
                                                    <br/>
                                                    <label>
                                                        {e.email}
                                                    </label>
                                                    <br/>                                          
                                                </div>
                                            </LayoutCard>
                                        </Link>
                                    </div>
                                )}
                            </div>
                            <ul className="pagination">
                                <li className="page-item">
                                    <button 
                                        disabled={currentPage <= 0} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage-1)}}
                                        style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                    >
                                        Inapoi
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        className="page-link btn no-outline"
                                        style={{cursor: "auto", borderRadius: 0}}
                                    >
                                        {currentPage+1}
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button 
                                        disabled={conturi.length < pageSize} 
                                        className="page-link btn no-outline" 
                                        onClick={()=>{setCurrentPage(currentPage+1)}}
                                        style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                    >
                                        Inainte
                                    </button>
                                </li>
                            </ul>
                        </>
                    )}
                </div>
                <div className="col-md-4">
                    <h5>Statistica conturi</h5>
                    <br/>
                    {statistics !== undefined && (
                        <div className="row">
                            <div className="col-md-6">
                                <div
                                    data-aos="flip-up"
                                >
                                    <LayoutCard
                                        className="layout_card1 noselect"
                                        onClick={()=>{}}
                                    >                            
                                        <label>Total</label>
                                        <br/>
                                        <h4 style={{marginBottom: '0', display: 'inline'}}>
                                            {statistics.totalAccounts}
                                        </h4>                                               
                                    </LayoutCard>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    data-aos="flip-up"
                                >
                                    <LayoutCard
                                        className="layout_card1 noselect"
                                        onClick={()=>{}}
                                    >                            
                                        <label>Angajati fara cont</label>
                                        <br/>
                                        <h4 style={{marginBottom: '0', display: 'inline'}}>
                                            {statistics.employeesWithoutAccounts}
                                        </h4>                                               
                                    </LayoutCard>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    data-aos="flip-up"
                                >
                                    <LayoutCard
                                        className="layout_card1 noselect"
                                        onClick={()=>{}}
                                    >                            
                                        <label>Conturi active</label>
                                        <br/>
                                        <h4 style={{marginBottom: '0', display: 'inline'}}>
                                            {statistics.activeAccounts}
                                        </h4>                                               
                                    </LayoutCard>
                                </div>
                            </div>
                        </div>
                    )}
                    <h5>Comenzi</h5>
                    <br/>
                    <div className="row">
                        <div className="col-md-6">
                            <div
                                data-aos="flip-up"
                            >
                                <RoundButton 
                                    className="custom-btn-primary"
                                    text="Importa angajati din REVISAL"
                                    onClickHandler={()=>{
                                        setUploadRevisalDbModalVisible(true);
                                        setTimeout(() => {
                                            const stats_cpy = statistics;
                                            stats_cpy.employeesWithoutAccounts = "...";
                                            setStatistics(stats_cpy);
                                            loadStatistics();
                                        }, 1000*10);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div
                                data-aos="flip-up"
                            >
                                <RoundButton 
                                    className="custom-btn-primary"
                                    text="Actualizare automata"
                                    onClickHandler={()=>{
                                        setEmployeeBatchDataUpdateModalVisible(true);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className='row'>
                        <div className='col-md-6'>
                            <RoundButton
                                className="custom-btn-primary"
                                text="Importa fisa aptitudini"
                                onClickHandler={()=>{
                                    setUploadFiseAptitudiniModalVisible(true);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <UploadRevisalDbModal
                isOpen={uploadRevisalDbModalVisible}
                toggle={()=>{setUploadRevisalDbModalVisible(false);}}
            />
            <UpdateBatchEmployeeDataModal
                isOpen={employeeBatchDataUpdateModalVisible}
                toggle={()=>{setEmployeeBatchDataUpdateModalVisible(false);}}
            />
            <UpdateBatchFisaAptitudiniModal
                isOpen={uploadFiseAptitudiniModalVisible}
                toggle={()=>{setUploadFiseAptitudiniModalVisible(false);}}
            />
        </>
    );
}

const UploadRevisalDbModal = ({ isOpen, toggle }) => {
    const [uploadFileProgress, setUploadFileProgress] = useState(0);
    const [uploadFileProgressTotal, setUploadFileProgressTotal] = useState(0);
    let perc = (uploadFileProgress * 100) / uploadFileProgressTotal;

    const [angajatoriOptions, setAngajatoriOptions] = useState([]);
    const [selectedAngajator, setSelectedAngajator] = useState(undefined);
    useEffect(()=>{
        get_ajax("CompanyConfig/Angajatori/GetAngajatori", (response) =>{
            setAngajatoriOptions(response);
            if(response && response.length !== 0)
                setSelectedAngajator(response[0].id);
        });
    }, []);

    const [btnText, setBtnText] = useState('Alege fisier');

    const [selectedFile, setSelectedFile] = useState(null);
    const inputFileChanged = (e) => {
        setSelectedFile(e.target.files[0]);
        setBtnText(e.target.files[0].name);        
    }

    const uploadFiles = async () => {        
        var data = new FormData();
        data.encoding = "multipart/form-data";
        data.append('files', selectedFile);

        var request = new XMLHttpRequest();

        request.upload.addEventListener('progress', function (e) {
            setUploadFileProgress(e.loaded);
            setUploadFileProgressTotal(e.total);
            if (e.total === e.loaded) {
                setUploadFileProgress(0);
                setUploadFileProgressTotal(0);
                setBtnText('Alege fisier');
                toggle();                
            }
        }.bind(this), false);

        request.open('POST', 'CompanyConfig/Conturi/UploadFile?angajatorId='+selectedAngajator);
        const token = getAccessToken();
        request.setRequestHeader('Authorization', token);
        request.send(data);
    }

    const [customImportRule, setCustomImportRule] = useState('{}');
    useEffect(() => {
        if(selectedAngajator === undefined) return;
        get_ajax("CompanyConfig/Conturi/GetRevisalImportRule?angajatorId="+selectedAngajator, (response) =>{
            setCustomImportRule(response);
        });
    }, [selectedAngajator]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader>Incarcare fisier</ModalHeader>
            <ModalBody>
                <div className='form-group'>
                    <label>Alegeti angajator:</label>
                    <select 
                        className='form-control no-outline'
                        value={selectedAngajator}
                        onChange={(e) => {setSelectedAngajator(e.target.value)}}
                    >
                        {angajatoriOptions.map((ang, i) => 
                            <option value={ang.id} key={i}>{ang.nume}</option>
                        )}
                    </select>
                </div>
                <FormGroupArea
                    label={'Regula import personalizat'}
                    value={customImportRule}
                    rows={10}
                    onChange={(e) => {
                        const new_rule = e.target.value;
                        setCustomImportRule(new_rule);
                        post('CompanyConfig/Conturi/UpdateRevisalImportRule', 
                        {
                            "angajatorId": selectedAngajator,
                            "newRule": new_rule
                        }, (response)=>{
                            if(response === false)
                                alert("Eroare la modificare"); 
                        });
                    }}
                />
                <button
                    onClick={() => {
                        $('#autoInputObj_ARCH_F_UPL').trigger('click');
                    }}
                    className="btn btn-primary no-outline btn-block"
                >
                    {btnText}                    
                </button>
                <small>Atasati fisierul .db exportat din Revisal. Vor fi importati doar angajatii cu contract de munca activ in acest moment.</small>                
                {uploadFileProgressTotal != 0 && (
                    <div style={{ margin: 'auto', width: "calc(100% - 20px)" }}>
                        <br />
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" aria-valuenow={perc}
                                aria-valuemin="0" aria-valuemax="100" style={{ width: perc + "%" }}>
                                {perc + "%"}
                            </div>
                        </div>
                    </div>
                )}
                <input id="autoInputObj_ARCH_F_UPL" hidden type="file" style={{ display: 'none', position: 'fixed', top: '-5000em' }} onChange={inputFileChanged} multiple={false} />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" className="no-outline" onClick={toggle}>Anuleaza</Button>
                <Button color="primary" className="no-outline" onClick={() => {
                    uploadFiles();
                    setUploadFileProgress(0);
                    setUploadFileProgressTotal(0);                    
                }}>Adauga</Button>
            </ModalFooter>
        </Modal>
    );
}

const UpdateBatchEmployeeDataModal = ({isOpen, toggle}) => {
    const [textDataValue, setTextDataValue] = useState('');
    return (
        <CustomResourceModal
            size={'lg'}
            isOpen={isOpen}
            toggle={toggle}
            title={"Actualizare automata"}
        >
            <FormGroupArea
                value={textDataValue}
                label={"Tabel CSV"}
                onChange={(e) => {setTextDataValue(e.target.value)}}
                rows={10}
            />
            <RoundButton
                    className="custom-btn-primary"
                    text={'Actualizeaza'}
                    onClickHandler={() => {
                        post('CompanyConfig/Conturi/BatchUpdateEmployeeData',
                        {
                            'csv_file': textDataValue
                        }, (response) => {
                            if(response === false)
                            {alert("Eroare la import."); return;}
                            alert("Actualizat cu succes!");
                            toggle();
                        });
                    }}
                />
        </CustomResourceModal>
    )
}

const UpdateBatchFisaAptitudiniModal = ({isOpen, toggle}) => {
    const [textDataValue, setTextDataValue] = useState('');
    return (
        <CustomResourceModal
            size={'lg'}
            isOpen={isOpen}
            toggle={toggle}
            title={"Import fisa aptitudini"}
        >
            <FormGroupArea
                value={textDataValue}
                label={"Tabel CSV"}
                onChange={(e) => {setTextDataValue(e.target.value)}}
                rows={10}
            />
            <RoundButton
                    className="custom-btn-primary"
                    text={'Actualizeaza'}
                    onClickHandler={() => {
                        post('CompanyConfig/Conturi/BatchUpdateImportFiseAptitudini',
                        {
                            'csv_file': textDataValue
                        }, (response) => {
                            if(response === false)
                            {alert("Eroare la import."); return;}
                            alert("Actualizat cu succes!");
                            toggle();
                        });
                    }}
                />
        </CustomResourceModal>
    )
}

export const ConturiDetails = (props) => {
    const [isLoading, setIsLoading] = useState(true);     
    const [resources, setResources] = useState({nume: ''});

    useEffect(()=>{
        if (props.match.params.cont_id != undefined) {
            load_resources();
        }
    }, [props.match.params.cont_id]);  

    const load_resources = () =>{
        get_ajax("CompanyConfig/Conturi/GetContDetails?id=" + props.match.params.cont_id, (response) => {
            setResources(response);
            setCustomPermissions(response.customPermissionsJSON);
            setIsLoading(false);
        });
    }

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [redirectToResource, setRedirectToResource] = useState('');
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

    const [addNewRoleModalOpen, setAddNewRoleModalOpen] = useState(false);
    const [newRoleModalSelectedValue, setNewRoleModalSelectedValue] = useState('');

    const [availableRoles, setAvailableRoles] = useState([]);
    useEffect(()=>{
        get_ajax("CompanyConfig/Conturi/GetDefinedRoles", (response) => {
            setAvailableRoles(response);
            setNewRoleModalSelectedValue(response[0].id);
        });
    }, []);
    useEffect(()=>{
        if(addNewRoleModalOpen){ setCustomPermissions("{}"); }
    }, [addNewRoleModalOpen])

    const [customPermissions, setCustomPermissions] = useState({});

    const [configEditDialogSelectedId, setConfigEditDialogSelectedId] = useState(undefined);
    useEffect(()=>{
        if(configEditDialogSelectedId === undefined) return;
        setCustomPermissions(resources.roles[configEditDialogSelectedId].config);
    }, [configEditDialogSelectedId]);

    const [signatureUploadModalVisible, setSignatureUploadModalVisible] = useState(false);
    const updateSingatureBase64 = (value) => {
        const _resources = JSON.parse(JSON.stringify(resources));
        _resources.signature = value;
        setResources(_resources);
    }
    const singatureFileInputChanged = (e) => {
        if(e.target.files[0] !== undefined)
        getBase64(e.target.files[0], updateSingatureBase64);
        else
            updateSingatureBase64(undefined);
    }

    return(
        <>
            <h4><Link className="redirectNavigationLink" to='/conturi'>Conturi</Link> &gt; {resources.userName}</h4>
            <br/>
            {isLoading && (
                <>                    
                    <LoadingCircleComponent/>
                </>
            )}
            {!isLoading && (
                <>
                    <div className="row">
                        <div className="col-md-8">
                            <h6>Date cont</h6>
                            <FormGroup
                                label={'Nume utilizator'}
                                value={resources.userName}
                                disabled={true}
                            />
                            <FormGroup
                                label={'Nume'}
                                value={resources.nume}
                                disabled={true}
                            />
                            <FormGroup
                                label={'Prenume'}
                                value={resources.prenume}
                                disabled={true}
                            />
                            <FormGroup
                                label={'Adresa email'}
                                value={resources.email}
                                disabled={true}
                            />                            
                            
                            <label>Semnatura:</label>
                            <div className='form-group'>
                                {(resources.signature === 'none' || resources.signature === undefined) && (
                                    <>
                                        <p style={{textAlign: 'center'}}>Nu exista semnatura atasata.</p>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                <RoundButton
                                                    className={"custom-btn-primary"}
                                                    text={'Adauga semnatura'}
                                                    onClickHandler={()=>setSignatureUploadModalVisible(true)}
                                                />
                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </>
                                )}
                                {resources.signature !== 'none' && resources.signature !== undefined && (
                                    <>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <img src={resources.signature} style={{width: '100%', border: '1px solid #2c3e50'}}/>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <RoundButton
                                                    className={"custom-btn-primary"}
                                                    text={'Modifica'}
                                                    onClickHandler={()=>setSignatureUploadModalVisible(true)}
                                                />
                                            </div>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                <RoundButton
                                                    className={"btn-danger"}
                                                    text={'Sterge'}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <hr/>

                            <br/>
                            <br/>
                            <div className="row">
                                <div className="col-md-3">
                                    <RoundButton                                        
                                        className={"custom-btn-primary"}
                                        text="Salveaza"
                                        onClickHandler={()=>{
                                            post('CompanyConfig/Conturi/UpdateContDetails', {
                                                'id': props.match.params.cont_id,
                                                'newRolesJSON': JSON.stringify(resources.roles),
                                                'signatureBase64': resources.signature
                                            }, () => {
                                                setIsConfirmationDialogOpen(true);
                                            });                                          
                                        }}
                                    />
                                </div>
                                <div className="col-md-6"></div>                        
                                <div className="col-md-3">
                                    <RoundButton                                        
                                        className={"btn-danger"}
                                        text="Dezactiveaza cont"
                                        onClickHandler={()=>{setIsDeleteDialogOpen(true)}}
                                    />
                                </div>
                            </div>                          
                        </div>
                        <div className="col-md-4">
                            <label>Roluri utilizator:</label>                            
                            <ul className="list-group">
                                {resources.roles.map((role, i) => 
                                    <li key={i} className="list-group-item" onClick={()=>{
                                        setConfigEditDialogSelectedId(i);
                                    }}>
                                        {role.name}
                                        <MdOutlineRemoveCircle 
                                            className="float-right" 
                                            size={18}
                                            onClick={(e)=> {
                                                const _resources = JSON.parse(JSON.stringify(resources));
                                                _resources.roles.splice(i, 1);
                                                setResources(_resources);
                                                e.preventDefault();
                                            }}
                                        />
                                    </li>
                                )}
                            </ul>  
                            <br/>
                            <RoundButton                                        
                                className={"custom-btn-primary"}
                                text="Adauga rol"
                                onClickHandler={()=>{setAddNewRoleModalOpen(true)}}
                            />
                        </div>
                    </div>
                </>
            )}

            <>        
                <CustomResourceModal
                    size={'md'}
                    isOpen={signatureUploadModalVisible}
                    toggle={() => setSignatureUploadModalVisible(false)}
                    title={"Incarca semnatura"}
                >
                    <form>
                        <div className="form-group">
                            <label>Ataseaza semnatura</label>
                            <input type="file" className="form-control-file" accept="image/*" onChange={singatureFileInputChanged}/>
                        </div>
                    </form>
                    <br/>
                    <img src={resources.signature} style={{width: '100%', border: '1px solid #2c3e50'}}/>
                </CustomResourceModal>


                <DeleteConfirmationDialog
                    label={"Confirma dezactivarea"}
                    isOpen={isDeleteDialogOpen}
                    toggle={()=>{setIsDeleteDialogOpen(!isDeleteDialogOpen)}}
                    onConfirm={()=>{
                        post('CompanyConfig/conturi/RemoveCont', {
                            "id": props.match.params.cont_id
                        }, (response)=>{
                            if(response == false)
                            {
                                alert("Eroare la stergere");
                                return;
                            }
                            setRedirectToResource("/conturi");
                        });
                    }}
                />
                <UpdateResultSuccessDialog
                    isOpen={isConfirmationDialogOpen}
                    toggle={()=>{setIsConfirmationDialogOpen(!isConfirmationDialogOpen)}}
                    onConfirm={load_resources}
                />
                {redirectToResource != '' && (
                    <Redirect to={redirectToResource}/>
                )}

                <Modal isOpen={addNewRoleModalOpen} toggle={()=>{setAddNewRoleModalOpen(false)}}>
                    <ModalHeader>Alege rol</ModalHeader>
                    <ModalBody>
                        <label>Rol</label>
                        <select 
                            className="form-control no-outline" 
                            value={newRoleModalSelectedValue}
                            onChange={(e) => {setNewRoleModalSelectedValue(e.target.value)}}
                        >
                            {availableRoles.map((role, i) =>
                                <option value={role.id} key={i}>{role.name}</option>
                            )}
                        </select>
                        <hr/>
                        <FormGroupArea
                            label={"Custom permissions config"}
                            rows={10}
                            value={customPermissions}
                            onChange={(e) => setCustomPermissions(e.target.value)}
                        />
                        <small>Nu recomandam modificarea acestor setari. Contactati suportul.</small>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" className="no-outline" onClick={()=>{setAddNewRoleModalOpen(false)}}>Anuleaza</Button>
                        <Button color="primary" className="no-outline" onClick={() => {
                            const _resources = JSON.parse(JSON.stringify(resources));                                                        
                            const obj = availableRoles.filter(function(a){return a.id == newRoleModalSelectedValue})[0];
                            obj['config'] = customPermissions;
                            _resources.roles.push(obj);
                            setResources(_resources);
                            setAddNewRoleModalOpen(false);
                        }}>Adauga</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={configEditDialogSelectedId !== undefined} toggle={()=>{setConfigEditDialogSelectedId(undefined)}}>
                    {resources !== undefined && configEditDialogSelectedId !== undefined && (
                        <>
                        <   ModalHeader>{resources.roles[configEditDialogSelectedId].name}</ModalHeader>
                            <ModalBody>
                                <FormGroupArea
                                    label={"Custom permissions config"}
                                    rows={10}
                                    value={customPermissions}
                                    onChange={(e) => setCustomPermissions(e.target.value)}
                                />
                                <small>Nu recomandam modificarea acestor setari. Contactati suportul.</small>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" className="no-outline" onClick={()=>{setConfigEditDialogSelectedId(undefined)}}>Anuleaza</Button>
                                <Button color="primary" className="no-outline" onClick={() => {
                                    const _resources = JSON.parse(JSON.stringify(resources));                                                          
                                    _resources.roles[configEditDialogSelectedId]['config'] = customPermissions;
                                    setResources(_resources);
                                    setConfigEditDialogSelectedId(undefined);
                                }}>Salveaza</Button>
                            </ModalFooter>
                        </>
                    )}
                </Modal>
            </>
            <br/>
        </>
    )
}

export const ConturiNew = () => {
    const [nume, setNume] = useState('');
    const [prenume, setPrenume] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [roles, setRoles] = useState([]);
    
    const [redirectToResource, setRedirectToResource] = useState('');    

    const [addNewRoleModalOpen, setAddNewRoleModalOpen] = useState(false);
    const [newRoleModalSelectedValue, setNewRoleModalSelectedValue] = useState('');

    const [availableRoles, setAvailableRoles] = useState([]);
    useEffect(()=>{
        get_ajax("CompanyConfig/Conturi/GetDefinedRoles", (response) => {
            setAvailableRoles(response);
            setNewRoleModalSelectedValue(response[0].id);
        });
    }, []);

    return(
        <>
            <h4><Link className="redirectNavigationLink" to='/conturi'>Conturi</Link> &gt; Cont nou</h4>
            <br/>
            <div className="row">
                <div className="col-md-8">
                    <h6>Date cont</h6>
                    <FormGroup
                        label={'Nume utilizator'}
                        value={userName}
                        onChange={(e) =>{setUserName(e.target.value)}}                        
                    />
                    <FormGroup
                        label={'Nume'}
                        value={nume}
                        onChange={(e) =>{setNume(e.target.value)}}                        
                    />
                    <FormGroup
                        label={'Prenume'}
                        value={prenume}
                        onChange={(e) =>{setPrenume(e.target.value)}}                        
                    />
                    <FormGroup
                        label={'Adresa email'}
                        value={email}
                        onChange={(e) =>{setEmail(e.target.value)}}
                    />                           
                    <FormGroup
                        label={'Parola'}
                        value={password}
                        onChange={(e) =>{setPassword(e.target.value)}}
                        type={"password"}
                    />      
                    <FormGroup
                        label={'Confirma parola'}
                        value={passwordConfirmation}
                        onChange={(e) =>{setPasswordConfirmation(e.target.value)}}
                        type={"password"}
                    />                                                            
                    <br/>
                    <br/>
                    <div className="row">
                        <div className="col-md-3">
                            <RoundButton                                        
                                className={"custom-btn-primary"}
                                text="Salveaza"
                                onClickHandler={()=>{
                                    if(passwordConfirmation !== password)
                                    { alert("Parola si Confirmarea parolei nu coincid."); return; }
                                    if(document.domain.includes("aquannect"))
                                        document.domain = "aquannect.com";
                                    else if(document.domain.includes("localhost"))
                                        document.domain = "localhost";
                                    $.ajax({
                                        type: "GET",
                                        url: 'get_identity_authority',  
                                        headers: 'Access-Control-Allow-Headers: *',
                                        async: true,
                                        success: function (redirectUrl) {
                                            redirectUrl = redirectUrl + "/AccountManager/CreateUser";
                                            post(redirectUrl, {
                                                'username': userName, 
                                                'email': email, 
                                                'password': password, 
                                                'nume': nume, 
                                                'prenume': prenume
                                            }, (response)=>{
                                                if(response == "error"){
                                                    alert("Utilizatorul nu a putut fi adaugat."); return;
                                                }
                                                post('CompanyConfig/Conturi/UpdateContDetails', {
                                                    'id': response,
                                                    'newRolesJSON': JSON.stringify(roles)
                                                }, () => {
                                                    setRedirectToResource('/conturi');
                                                }); 
                                            })
                                        }
                                    });
                                }}
                            />
                        </div>
                    </div>                          
                </div>
                <div className="col-md-4">
                    <label>Roluri utilizator:</label>                            
                    <ul className="list-group">
                        {roles.map((role, i) => 
                            <li key={i} className="list-group-item">
                                {role.name}
                                <MdOutlineRemoveCircle 
                                    className="float-right" 
                                    size={18}
                                    onClick={()=>{
                                        const _roles = [...roles];
                                        _roles.splice(i, 1);
                                        setRoles(_roles);
                                    }}
                                />
                            </li>
                        )}
                    </ul>  
                    <br/>
                    <RoundButton                                        
                        className={"custom-btn-primary"}
                        text="Adauga rol"
                        onClickHandler={()=>{setAddNewRoleModalOpen(true)}}
                    />
                </div>
                <br/>
                <br/>
            </div>
            <>                                
                {redirectToResource != '' && (
                    <Redirect to={redirectToResource}/>
                )}

                <Modal isOpen={addNewRoleModalOpen} toggle={()=>{setAddNewRoleModalOpen(false)}}>
                    <ModalHeader>Alege rol</ModalHeader>
                    <ModalBody>
                        <label>Rol</label>
                        <select 
                            className="form-control no-outline" 
                            value={newRoleModalSelectedValue}
                            onChange={(e) => {setNewRoleModalSelectedValue(e.target.value)}}
                        >
                            {availableRoles.map((role, i) =>
                                <option value={role.id} key={i}>{role.name}</option>
                            )}
                        </select>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" className="no-outline" onClick={()=>{setAddNewRoleModalOpen(false)}}>Anuleaza</Button>
                        <Button color="primary" className="no-outline" onClick={() => {
                            const _roles = [...roles];
                            _roles.push(
                                availableRoles.filter(function(a){return a.id == newRoleModalSelectedValue})[0]
                            );
                            setRoles(_roles)
                            setAddNewRoleModalOpen(false);
                        }}>Adauga</Button>
                    </ModalFooter>
                </Modal>
            </>
        </>
    )
}