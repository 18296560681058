import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Home } from './pages/Home';

import './App.scss';
import { getAccessToken, GetRoles } from 'shared/dist/components/api-authorization/AuthService';
import {ClientPersonalizedLayout} from 'shared/dist/components/ClientPersonalizedLayout';
import {PaddingBox} from 'shared/dist/components/generic-building-blocks/PaddingBox';
import {BsFillDiagram2Fill, BsBuilding} from "react-icons/bs";
import {FaFileContract} from "react-icons/fa";
import { Angajatori, AngajatoriNew, AngajatoriDetails } from './pages/Angajatori';
import { CompanyStructure } from './pages/CompanyStructure';
import { Subunitati, SubunitatiDetails, SubunitatiNew } from './pages/Subunitati';
import {HiUserGroup} from 'react-icons/hi';
import { ConturiDetails, Conturi, ConturiNew } from './pages/Conturi';
import { CenterScreenContainer } from 'shared/dist/components/generic-building-blocks/CenterScreenContainer';
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {MdOutlineMedicalServices, MdOutlineDesignServices} from 'react-icons/md';
import { ClientCreate, ClientDetails, Clients } from './pages/global_admin/Clients';
import { DoctorCreate, DoctorDetails, Doctors } from './pages/global_admin/Doctors';
import { ThemeDetails, ThemesPage } from './pages/global_admin/Themes';
import {AiOutlineApi} from 'react-icons/ai';
import { APIManager } from './pages/APIManager';
import {CimConfigDashboard} from './pages/cim_configurator/CimConfigDashboard';
import {CimConfigModels} from "./pages/cim_configurator/CimModelsBuilder";
import {CimConfigForms} from "./pages/cim_configurator/CimFormsBuilder";
import {CimConfigRoles} from "./pages/cim_configurator/CimRolesBuilder";
import {CimConfigActions} from "./pages/cim_configurator/CimActionsBuilder";
import {CimConfigDocuments} from "./pages/cim_configurator/CimDocumentsBuilder";

export default class App extends Component {
    static displayName = App.name;    

    constructor(props)
    {
        super(props);        

        getAccessToken();
        let allowed_roles = [];
        GetRoles((roles)=> {                    
          allowed_roles = roles.filter((e)=>{
            return (
              e.name == "GlobalAdmin" || e.name == "Administrator"
            );
          });          
          //TODO Aici in loc sa pui direct allowed_roles[0] ar trebui sa te uiti putin si la un localstorage sa vezi ultimul role ales de user          
          this.setState({allowed_roles: allowed_roles, current_role: allowed_roles[0]});
        });  
        this.state = { allowed_roles : allowed_roles, current_role: allowed_roles[0] };              
    }

    //GlobalAdmin

    returnAllowedRoutes = () => {
      const current_role = this.state.current_role.name;       
      window.localStorage.setItem("__current_role__", current_role);
      if(current_role == 'GlobalAdmin'){
        return([
          {
            "route": "/global_admin/clients",
            "name": "Clienti",
            "icon": <BsBuilding size={28}/>
          },
          {
            "route": "/global_admin/doctors",
            "name": "Clienti",
            "icon": <MdOutlineMedicalServices size={28}/>
          },    
          {
            "route": "/global_admin/themes",
            "name": "Clienti",
            "icon": <MdOutlineDesignServices size={28}/>
          },          
        ])
      }
      else{
        return([
          {
            "route": "/company_structure",
            "name": "Structura",
            "icon": <BsFillDiagram2Fill size={28}/>
          },
          {
            "route": "/angajatori",
            "name": "Angajatori",
            "icon": <FaFileContract size={28}/>
          },
          {
            "route": "/subunitati",
            "name": "Subunitati",
            "icon": <BsBuilding size={28}/>
          },
          {
            "route": "/conturi",
            "name": "Conturi",
            "icon": <HiUserGroup size={28}/>
          },
          {
            "route": "/api_manager",
            "name": "API",
            "icon": <AiOutlineApi size={28}/>
          },
          {
            "route": "/cim",
            "name": "Modul CIM",
            "icon": <FaFileContract size={28}/>
          }
        ])
      }
    }

    render() {   
        if(this.state.current_role === undefined) {
          return(
            <CenterScreenContainer>
              <LoadingCircleComponent/>
            </CenterScreenContainer>
          );
        }     
        return (
            <ClientPersonalizedLayout              
              app={'config'} 
              routes={this.returnAllowedRoutes()}              
            >
              <PaddingBox
                top='0'
                right='0'
                bottom='0'
                left='15px'
              >
                <Route exact path='/' component={Home} />                              

                <Route exact path='/company_structure' component={CompanyStructure} />

                <Route exact path='/angajatori' component={Angajatori} />
                <Route exact path='/angajatori/new' component={AngajatoriNew} />
                <Route exact path='/angajatori/details/:angajator_id' component={AngajatoriDetails} />                                

                <Route exact path='/subunitati' component={Subunitati} />
                <Route exact path='/subunitati/new' component={SubunitatiNew} />
                <Route exact path='/subunitati/details/:subunitate_id' component={SubunitatiDetails} />                                

                <Route exact path='/conturi' component={Conturi}/>
                <Route exact path='/conturi/new' component={ConturiNew}/>
                <Route exact path='/conturi/details/:cont_id' component={ConturiDetails} /> 

                <Route exact path='/api_manager' component={APIManager}/>

                <Route exact path='/cim' component={CimConfigModels}/>
                <Route exact path='/cim/models' component={CimConfigModels}/>
                <Route exact path='/cim/models/:model_id' component={CimConfigModels}/>
                <Route exact path="/cim/forms" component={CimConfigForms}/>
                <Route exact path='/cim/documents' component={CimConfigDocuments}/>
                <Route exact path='/cim/actions' component={CimConfigActions}/>
                <Route exact path='/cim/roles' component={CimConfigRoles}/>

                <Route exact path='/global_admin/clients' component={Clients} />
                <Route exact path='/global_admin/clients/details/:client_id' component={ClientDetails} />
                <Route exact path='/global_admin/clients/new' component={ClientCreate} />

                <Route exact path='/global_admin/doctors' component={Doctors} />
                <Route exact path='/global_admin/doctors/details/:client_id' component={DoctorDetails} />
                <Route exact path='/global_admin/doctors/new' component={DoctorCreate} />

                <Route exact path='/global_admin/themes' component={ThemesPage} />
                <Route exact path='/global_admin/themes/details/:theme_id' component={ThemeDetails} />

              </PaddingBox>              
            </ClientPersonalizedLayout>
        );
  }
}