import React, {useEffect, useState} from "react";
import {LayoutCard} from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {sideOptionsList} from "./constants";
import {EditableDataModelsConfigurator} from "./CimModelsBuilder";
import {EditableFormConfigurator} from "./CimFormsBuilder";
import {EditableCimRolesConfigurator} from "./CimRolesBuilder";
import {EditableDocumentsConfigurator} from "./CimDocumentsBuilder";
import {EditableActionsConfigurator} from "./CimActionsBuilder";
import {Redirect} from 'react-router-dom';

export const CimConfigDashboard = (props) => {
    const [redirectToKey, setRedirectToKey] = useState(undefined);
    
    return (
        <>
            <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                <h4>Configurare Modul Administrare CIM</h4>
            </LayoutCard>
            <div className={'row'}>
                <div className={'col-md-2'}>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <ul className="list-group list-group-flush">
                            {sideOptionsList.map((option, i) =>
                                <li 
                                    key={i} 
                                    style={{cursor: 'pointer'}}
                                    className={option.key === props.selectedKey ? "list-group-item list-group-item-active noselect" : "list-group-item noselect"}
                                    onClick={()=>{
                                        setRedirectToKey(option.key);
                                    }}
                                >
                                    <span style={{marginRight: '10px'}}>{option.icon}</span>
                                    {option.name}
                                </li>
                            )}
                        </ul>
                    </LayoutCard>
                </div>
                <div className={'col-md-10'}>
                    {props.children}
                    {/*{selectedSideOption === 0 && (*/}
                    {/*    <EditableDataModelsConfigurator/>*/}
                    {/*)}*/}
                    {/*{selectedSideOption === 1 && (*/}
                    {/*    <EditableFormConfigurator/>*/}
                    {/*)}*/}
                    {/*{selectedSideOption == 2 && (*/}
                    {/*    <h4>Pages configurator</h4>*/}
                    {/*)}*/}
                    {/*{selectedSideOption === 3 && (*/}
                    {/*    <EditableDocumentsConfigurator/>*/}
                    {/*)}*/}
                    {/*{selectedSideOption === 4 && (*/}
                    {/*    <EditableActionsConfigurator/>*/}
                    {/*)}*/}
                    {/*{selectedSideOption === 5 && (*/}
                    {/*    <EditableCimRolesConfigurator/>*/}
                    {/*)}*/}
                </div>
            </div>
            {(redirectToKey !== undefined && redirectToKey !== '') && (
                <Redirect to={`/cim/${redirectToKey}`}/>
            )}
        </>
    );
}